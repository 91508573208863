import React from 'react';
import { Dialog } from '@mui/material';

import { AUTOMATION_TESTING_IDS, INTEGRATION_TESTING_IDS } from '../../../constants';

interface IProps {
    isOpen: boolean;
    handleCloseModal: () => void;
    children: React.ReactNode;
    labelledBy?: string;
    describedBy?: string;
}

// TODO Change handleClose to onClose
const Modal: React.FC<IProps> = ({
    isOpen,
    handleCloseModal,
    labelledBy = 'sign in dialog',
    describedBy = 'need to authorized',
    children,
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            id={AUTOMATION_TESTING_IDS.modalContent}
            data-testid={INTEGRATION_TESTING_IDS.dialogModal}
        >
            {children}
        </Dialog>
    );
};

export default Modal;
