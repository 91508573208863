import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../ui-components/Box/Box';
import { ToolTipBoxStyled } from './ToolTipBoxStyled.styled';

const x = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top',
};

const ToolTipBox = ({
    align,
    placement,
    offset,
    arrow,
    borderColor,
    maxWidth,
    show,
    showOnHover,
    content,
    children,
    wrapperRight,
    arrowPosition,
    arrowOffset,
    arrowDirection,
}) => {
    const elementPosition = {
        [x[placement]]: '100%',
        [`m${x[placement].charAt(0)}`]: `${5 + offset}px`,
    };

    const boxAlign = placement === 'top' || placement === 'bottom' ? 'x' : 'y';

    return (
        <Box
            position="relative"
            component={ToolTipBoxStyled}
            align={`${boxAlign}-${align}`}
            wrapperRight={wrapperRight}
            show={show}
            showOnHover={showOnHover}
            bordercolor={borderColor}
            arrowPosition={arrowPosition}
            arrowOffset={arrowOffset}
            arrowDirection={arrowDirection}
        >
            {show && (
                <Box
                    {...elementPosition}
                    position="absolute"
                    maxWidth={maxWidth}
                    zIndex={99}
                    className="WP-ToolTipBox-Body"
                >
                    {arrow && (
                        <Box
                            position="absolute"
                            width={1}
                            height={1}
                            zIndex={-1}
                            className="WP-ToolTipBox-arrow"
                        />
                    )}
                    <Box className="WP-ToolTipBox-Content">{content}</Box>
                </Box>
            )}

            <Box>{children}</Box>
        </Box>
    );
};

ToolTipBox.defaultProps = {
    portal: false,
    align: 'center',
    placement: 'bottom',
    show: false,
    showOnHover: false,
    arrow: true,
    maxWidth: 250,
    offset: 0,
    borderColor:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    arrowDirection: 'top',
    arrowOffset: '-6px',
};

ToolTipBox.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    show: PropTypes.bool,
    showOnHover: PropTypes.bool,
    arrow: PropTypes.bool,
    offset: PropTypes.number,
    borderColor: PropTypes.string,
    maxWidth: PropTypes.number,
    arrowMarginLeft: PropTypes.string,
    wrapperRight: PropTypes.string,
    content: PropTypes.node,
    children: PropTypes.node,
    arrowOffset: PropTypes.string,
    arrowDirection: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    arrowTopOffset: PropTypes.string,
    arrowPosition: PropTypes.string,
};

export default ToolTipBox;
