import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import { ErrorBoundary } from './ErrorBoundary';
import { Store } from './store/configureStore';

datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,

    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',

    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,

    env: process.env.REACT_APP_ENVIRONMENT,

    version: process.env.REACT_APP_DATADOG_RELEASE_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    // <React.StrictMode>
    <ErrorBoundary>
        <Provider store={Store()}>
            <App />
        </Provider>
    </ErrorBoundary>,
    // </React.StrictMode>,
);
