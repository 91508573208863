/* eslint-disable no-undef */
import { useState } from 'react';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect.hook';

export const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateSize = () => {
        setWindowWidth(window.innerWidth);
    };

    useIsomorphicLayoutEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return windowWidth;
};
