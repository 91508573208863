import {
    type IBucket,
    type IBundle,
    type ILocalizedItem,
    type IOffer,
    type IStoreGroup,
    type TContentItem,
    type TContentItemData,
} from 'src/@types/store';
import {
    applyWebGiftFixedPosition,
    groupStoreItemsByType,
    sortStoreItemsBySortOrder,
    validateWebGifts,
} from 'src/helpers/dataManipulations';

import {
    type IClaimWebGiftResponse,
    type ILocalizedItemsResponse,
    type IMissionTranslationsResponse,
    type TJsonKey,
    type TOfferBannerQuantity,
    type TOfferSettingsAsync,
    type TOperation,
    type TSetOfferPreviewModal,
} from '../../@types';
import { fetch } from '../../services';
import { globalOp } from '../global';
import { initialOfferState } from './initialState';
import { offerSlice } from './offerSlice';

const getOfferSettingsAsync = () => {
    return fetch<TOfferSettingsAsync>({
        endPoint: '/xsolla/settings',
        privateRequest: true,
    });
};

const getXsollaAccessTokenForOrderAsync = (
    bundleId: number,
    redirectButtonCaption: string | undefined,
    quantity: number,
) => {
    let body: any = { bundleId, redirectButtonCaption };
    if (quantity > 1) {
        body.quantity = quantity;
    }
    return fetch<{ token: string }>({
        endPoint: '/xsolla/init-purchase',
        privateRequest: true,
        method: 'POST',
        body,
    });
};

const getLocalizedItems: TOperation<string, TJsonKey<ILocalizedItem>> = (countryCode) => {
    const { setLocalizedItems } = offerSlice.actions;

    return async (dispatch) => {
        const { data } = await fetch<ILocalizedItemsResponse>({
            url: `https://store.xsolla.com/api/v2/project/${process.env.REACT_APP_XSOLLA_PROJECT_ID}/items/virtual_items`,
            endPoint: '',
            privateRequest: true,
            body: {
                country: countryCode,
                limit: 999,
            },
        });

        const localizedItems = data.items.reduce((acc, cur) => {
            const {
                sku,
                price: { amount: price, currency },
            } = cur;

            acc[sku] = {
                price,
                currency,
            };

            return acc;
        }, {});

        dispatch(setLocalizedItems(localizedItems));

        return localizedItems;
    };
};

const getOfferItems: TOperation = () => {
    const {
        setOfferItems,
        setOfferBanner,
        setStoreGroupsItems,
        setLoading,
        setLastTimeOffersFetched,
    } = offerSlice.actions;

    const handleStoreGroups = (data: IOffer, dispatch) => {
        const { store_groups } = data;
        const { featuredGroup, webGiftGroup } = initialOfferState;

        const setStoreGroups = (groups: IStoreGroup[]) => {
            dispatch(setStoreGroupsItems(groups));
        };

        if (store_groups?.length) {
            sortStoreItemsBySortOrder(store_groups);
            setStoreGroups(applyWebGiftFixedPosition(store_groups, webGiftGroup));
        } else {
            setStoreGroups([featuredGroup, webGiftGroup]);
        }
    };

    const handleBundles = (data: IOffer, dispatch) => {
        sortStoreItemsBySortOrder(data.bundles);
        groupStoreItemsByType(data.bundles);

        const bannerItem = data.bundles[0] ?? null;
        dispatch(setOfferBanner(bannerItem));
        dispatch(setOfferItems(data.bundles));
    };

    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            let { data } = await fetch<IOffer>({
                endPoint: '/offers',
                privateRequest: true,
            });

            if (data && data.bundles.length) {
                handleBundles(data, dispatch);
                handleStoreGroups(data, dispatch);
            }
            dispatch(setLastTimeOffersFetched(new Date().getTime()));
        } catch (err) {
            // todo Add error handler
        } finally {
            dispatch(setLoading(false));
        }
    };
};

const getSecondaryOfferItems: TOperation = () => {
    const { setSecondaryOfferItems } = offerSlice.actions;

    return async (dispatch) => {
        try {
            let { data } = await fetch<IBundle[]>({
                endPoint: '/offers/resources-drp',
                privateRequest: true,
            });

            if (data && data.length) {
                const secondaryOffers = {};

                sortStoreItemsBySortOrder(data);
                groupStoreItemsByType(data, 'up_to');

                data.forEach((item) => {
                    secondaryOffers[item.bundle_id] = item;
                });

                dispatch(setSecondaryOfferItems(secondaryOffers));
            }
        } catch (err) {
            // todo Add error handler
        }
    };
};

const getMissionTranslations = (items, bundleId) => {
    const { setContentItemsData, setContentItemLoading } = offerSlice.actions;

    const filteredMissionItems = items.filter((item) => item.type === 'mission');
    const missionItemsIds = filteredMissionItems.map((item) => item.id);

    if (!missionItemsIds.length) {
        return async () => undefined;
    }

    return async (dispatch) => {
        dispatch(setContentItemLoading(bundleId));

        try {
            const { data } = await fetch<IMissionTranslationsResponse>({
                endPoint: '/translations/mission',
                privateRequest: true,
                method: 'POST',
                body: {
                    entity: missionItemsIds,
                },
            });

            if (data?.translations?.entity) {
                const itemData: TJsonKey<{ name: string; desc: string }> = {};

                filteredMissionItems.forEach((item) => {
                    itemData[item.id] = {
                        ...item,
                        rarity: 1,
                    };
                });

                data.translations.entity.forEach((item) => {
                    if (item.key === 'title') {
                        // @ts-ignore
                        itemData[item.id].name = item.text;
                    }

                    if (item.key === 'narrative') {
                        // @ts-ignore
                        itemData[item.id].desc = item.text;
                    }
                });

                dispatch(setContentItemsData({ bundleId, itemData }));
                dispatch(setContentItemLoading(bundleId));
            }
        } catch (err) {
            // todo Add error handler
        }
    };
};

const setPaymentStatus: TOperation<'done' | null> = (status) => {
    const { setPaymentStatus } = offerSlice.actions;
    return (dispatch) => {
        dispatch(setPaymentStatus(status));
    };
};

const setOfferBannerQuantity: TOperation<TOfferBannerQuantity> = ({ quantity, bundleId = 0 }) => {
    const { setOfferBannerQuantity, clearOfferBannerQuantity } = offerSlice.actions;
    return (dispatch) => {
        quantity === -1
            ? dispatch(clearOfferBannerQuantity())
            : dispatch(setOfferBannerQuantity({ quantity, bundleId }));
    };
};

const setBoughtBundleId: TOperation<number> = (bundleId) => {
    const { setBoughtBundleId } = offerSlice.actions;
    return (dispatch) => {
        dispatch(setBoughtBundleId(bundleId));
    };
};

const getOfferContentTranslations = (ids, types, bundleId) => {
    const { setContentItemData, setContentItemLoading } = offerSlice.actions;

    return async (dispatch) => {
        dispatch(setContentItemLoading(bundleId));

        try {
            const { data } = await fetch<TContentItemData[]>({
                endPoint: `/translations/content?ids=${ids.join()}&types=${types.join()}`,
                privateRequest: true,
            });
            const itemData = {};
            data.forEach((item) => (itemData[item.id] = item));
            dispatch(setContentItemData({ bundleId, itemData }));
        } catch (err) {
            // todo Add error handler
        } finally {
            dispatch(setContentItemLoading(bundleId));
        }
    };
};

const setContentItemLoading: TOperation<number> = (bundleId) => {
    const { setContentItemLoading } = offerSlice.actions;
    return (dispatch) => {
        dispatch(setContentItemLoading(bundleId));
    };
};

const clearOfferItems: TOperation = () => (dispatch) => {
    const { clearOfferItems } = offerSlice.actions;
    dispatch(clearOfferItems());
};

const getStaticOffers = () => {
    return fetch({
        endPoint: '/static-offers',
    });
};

const setProcessingBundle: TOperation<IBundle | null> = (processingOffer) => {
    const { setProcessingBundle } = offerSlice.actions;
    return (dispatch) => {
        dispatch(setProcessingBundle(processingOffer));
    };
};

const setOfferPurchasing: TOperation<boolean> = (isOfferPurchasing) => {
    const { setOfferPurchasing } = offerSlice.actions;
    return (dispatch) => {
        dispatch(setOfferPurchasing(isOfferPurchasing));
    };
};

const getWebGifts: TOperation = () => {
    const { setWebGifts, setClaimableWebGiftsCount } = offerSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<IBundle[]>({
                endPoint: '/offers/gifts',
                privateRequest: true,
            });

            if (data && data.length) {
                const [filteredBundles, claimableWebGifts] = validateWebGifts(data);

                dispatch(setWebGifts(filteredBundles));
                dispatch(setClaimableWebGiftsCount(claimableWebGifts.length));
            }
        } catch (e) {
            // todo Add error handler
        }
    };
};

const getWebGiftsWithoutCache: TOperation = () => {
    const { setWebGifts, setClaimableWebGiftsCount } = offerSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<IBundle[]>({
                endPoint: '/offers/gifts/update-cache',
                privateRequest: true,
            });

            if (data && data.length) {
                const [filteredOffers, claimableWebGifts] = validateWebGifts(data);

                dispatch(setWebGifts(filteredOffers));
                dispatch(setOfferPurchasing(false));
                dispatch(setProcessingBundle(null));
                dispatch(setClaimableWebGiftsCount(claimableWebGifts.length));
            }
        } catch (e) {
            // todo Add error handler
        }
    };
};

const claimWebGift = (bundleId, quantity = 1) => {
    const { setClaimableItemsModalPreview } = offerSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<IClaimWebGiftResponse>({
                endPoint: '/offers/gifts/claim',
                privateRequest: true,
                method: 'POST',
                body: { bundleId },
            });

            const claimedItems: TContentItem[] = [];

            data.orderBundles?.forEach((bundle) => {
                bundle.content?.forEach((item) => {
                    item.contents?.forEach((contentItem) => {
                        claimedItems.push(contentItem);
                    });
                });
            });

            dispatch(
                setClaimableItemsModalPreview({
                    items: claimedItems,
                    bundleId,
                    quantity,
                }),
            );
        } catch (e) {
            requestAnimationFrame(() => {
                //TODO remove console and try to make more appropriate
                // handling for popup blocker error message and the other ones

                // eslint-disable-next-line no-console
                console.log(e);
                dispatch(setProcessingBundle(null));
                dispatch(setOfferPurchasing(false));
                dispatch(
                    globalOp.handleOpenSnackbar({
                        open: true,
                        isTimeOutDisabled: !e?.response,
                        variant: 'error',
                        message:
                            e?.response?.status === 404
                                ? 'Bundle not available.'
                                : 'Oops! There was an error',
                        messageTranslationKey:
                            e?.response?.status === 404
                                ? 'common_snackbar_gift_not_available_text'
                                : 'common_snackbar_oops_error_text',
                    }),
                );
            });
            return e;
        } finally {
            dispatch(getWebGiftsWithoutCache());
        }
    };
};

const dropClaimableItemsModalPreview: TOperation = () => {
    const { setClaimableItemsModalPreview } = offerSlice.actions;

    return (dispatch) =>
        dispatch(
            setClaimableItemsModalPreview({
                items: [],
                bundleId: 0,
                quantity: 1,
            }),
        );
};

const setOfferPreviewModal: TOperation<TSetOfferPreviewModal> = ({
    isModalOpen = false,
    bundle = null,
}) => {
    const { setOfferPreviewModal } = offerSlice.actions;
    return (dispatch) => dispatch(setOfferPreviewModal({ isModalOpen, bundle }));
};

const togglePaymentStatusModal = ({ type = 'success', isModalOpen = false }) => {
    const { setPaymentStatusModal } = offerSlice.actions;
    return (dispatch) => dispatch(setPaymentStatusModal({ type, isModalOpen }));
};

const setSelectedStoreGroup = (group: IStoreGroup | null) => {
    const { setSelectedStoreGroup } = offerSlice.actions;

    return (dispatch) => {
        dispatch(setSelectedStoreGroup(group));
    };
};

const setSelectedBucket = (bucket: null | IBucket) => {
    const { setSelectedBucket } = offerSlice.actions;
    return (dispatch) => dispatch(setSelectedBucket(bucket));
};

export const offerOp = {
    getOfferItems,
    getSecondaryOfferItems,
    getOfferSettingsAsync,
    getLocalizedItems,
    getXsollaAccessTokenForOrderAsync,
    setPaymentStatus,
    getStaticOffers,
    getOfferContentTranslations,
    setContentItemLoading,
    clearOfferItems,
    setProcessingBundle,
    setOfferPurchasing,
    getWebGifts,
    getWebGiftsWithoutCache,
    claimWebGift,
    setOfferBannerQuantity,
    setBoughtBundleId,
    dropClaimableItemsModalPreview,
    getMissionTranslations,
    setOfferPreviewModal,
    togglePaymentStatusModal,
    setSelectedStoreGroup,
    setSelectedBucket,
};
