import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type Theme, useMediaQuery } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { type TErrorModal } from 'src/@types/store';
import { scopelyId } from 'src/configs/web-sdk.config';
import { redirectToScopelyAuth } from 'src/helpers/utils';

import { INTEGRATION_TESTING_IDS } from '../../../../constants';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { translationsSel } from '../../../../store/translation';
import TextSuspend from '../../TextSuspend/TextSuspend';
import { Box } from '../../ui-components/Box/Box';
import { Button } from '../../ui-components/Button/Button';
import Modal from '../Modal';
import ModalBody from '../ModalBody/ModalBody';

interface IProps {
    modalData: TErrorModal | null;
    onCloseModal: () => void;
    preview?: boolean;
}

interface IChildProps {
    modalData: TErrorModal | null;
    onCloseModal: () => void;
    onAccountLinkingGuide?: () => void;
    preview?: boolean;
}

const ErrorModalBody: React.FC<IChildProps> = ({
    modalData,
    onCloseModal,
    onAccountLinkingGuide,
    preview,
}) => {
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const t = useTranslation();

    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);

    const retryToSignIn = () => {
        redirectToScopelyAuth();
    };

    const renderButton = () => {
        if (!modalData || !modalData.type) {
            return null;
        }

        if (modalData.type === 'unauthorized-user-not-found') {
            return (
                <Box display="flex" minWidth={220}>
                    <Button
                        className="flex-1"
                        size="large"
                        color="secondary"
                        onClick={onAccountLinkingGuide}
                        testId={INTEGRATION_TESTING_IDS.errorModalAccountGuideButton}
                    >
                        <TextSuspend loading={translationsLoading} whiteVariant>
                            {t('common_error_modal_button_text', 'Account Linking Guide')}
                        </TextSuspend>
                    </Button>
                </Box>
            );
        }

        if (modalData.type === 'unauthorized-user-is-archived') {
            return (
                <Box display="flex" minWidth={220}>
                    <Button
                        className="flex-1"
                        size="large"
                        color="secondary"
                        onClick={onCloseModal}
                        testId={INTEGRATION_TESTING_IDS.errorModalOKButton}
                    >
                        <TextSuspend loading={translationsLoading} whiteVariant>
                            {t('common_confirm_text', 'ok')}
                        </TextSuspend>
                    </Button>
                </Box>
            );
        }

        if (modalData.type === 'game-in-maintenance') {
            return (
                <Box display="flex" minWidth={220}>
                    <Button
                        className="flex-1"
                        color="secondary"
                        onClick={retryToSignIn}
                        testId={INTEGRATION_TESTING_IDS.errorModalRetryButton}
                    >
                        <Box minWidth="162px">
                            <TextSuspend loading={translationsLoading} whiteVariant>
                                {t('error_modal_maintenance_button_text', 'Retry')}
                            </TextSuspend>
                        </Box>
                    </Button>
                </Box>
            );
        }
    };

    return (
        <ModalBody
            isAuthError
            hasCloseIcon={!preview}
            onClose={onCloseModal}
            image={
                <Box display="flex" justifyContent="center" alignItems="center" mt={[0, 10]}>
                    <Box
                        display="flex"
                        position="relative"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress
                            size={xsDown ? 120 : 150}
                            className="WP-Modal-loading"
                            variant="determinate"
                            value={100}
                            thickness={1}
                        />
                        <Box
                            width={[100, 127]}
                            height={[100, 127]}
                            position="absolute"
                            className={modalData?.className}
                        />
                    </Box>
                </Box>
            }
            title={modalData?.title && t(modalData.titleTranslationKey, modalData.title)}
            text={
                modalData?.description &&
                t(modalData.descriptionTranslationKey, modalData.description)
            }
            button={renderButton()}
        />
    );
};

const ErrorModal: React.FC<IProps> = ({ modalData, onCloseModal, preview }) => {
    const errorType = modalData?.title?.trim().replace(' ', '_').toLowerCase();

    const handleOpenAccountLinkingGuide = () => {
        sendTitanEventForLinkingGuide();

        window.open(
            'https://startrekfleetcommand.scopely.com/2021/08/10/introducing-scopelyid/',
            '_blank',
            'noopener, noreferrer',
        );
    };

    const sendTitanEventForLinkingGuide = () => {
        titanAnalyticsEvents.generateButtonEvent({
            interaction_object: 'account_linking_guide_button',
            screen_category: 'authorization',
            screen_id: 'account_linking_guide',
        });
    };

    const handleCloseModal = () => {
        if (modalData?.type === 'game-in-maintenance') {
            scopelyId.logout();
        }
        onCloseModal();
    };

    useEffect(() => {
        if (modalData?.open) {
            titanAnalyticsEvents.generateModalEvent({
                screen_id: `auth_failed`,
                screen_id_helper: `type_${errorType}`,
                screen_category: 'authorization_failed',
                interaction_object: 'authorization_failed_modal',
            });
        }
    }, [modalData]);

    if (preview) {
        return (
            <ErrorModalBody
                modalData={modalData}
                onCloseModal={onCloseModal}
                preview={preview}
                onAccountLinkingGuide={handleOpenAccountLinkingGuide}
            />
        );
    }

    return (
        <Modal
            labelledBy="sign in failed"
            describedBy={errorType}
            isOpen={Boolean(modalData?.open)}
            handleCloseModal={handleCloseModal}
        >
            <ErrorModalBody
                modalData={modalData}
                onCloseModal={handleCloseModal}
                preview={preview}
                onAccountLinkingGuide={handleOpenAccountLinkingGuide}
            />
        </Modal>
    );
};

export default ErrorModal;
