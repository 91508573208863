import { type IEventsState } from 'src/@types/store';

// TODO create global interface
type State = {
    events: IEventsState;
};

const eventsRewardClaimedModal = (state: State) => state.events.modals.rewardClaimed;
const eventsSelector = (state: State) => state.events.events;
const eventsBundlesSelector = (state: State) => state.events.bundles;
const eventsLoadingSelector = (state: State) => state.events.eventsLoading;
const eventsTranslationsSelector = (state: State) => state.events.eventsTranslations;
const eventsTranslationsLoadingSelector = (state: State) => state.events.translationsLoading;
const eventsContentTranslationsSelector = (state: State) => state.events.rewardContent.itemData;
const eventsContentTranslationsLoadingSelector = (state: State) =>
    state.events.rewardContent.loading;
const eventsSidebarTimeStampSelector = (state: State) => state.events.eventsSidebarTimeStamp;
const isNewEventsShownSelector = (state: State) => state.events.isNewEventsShown;

export const eventsSel = {
    eventsRewardClaimedModal,
    eventsSelector,
    eventsBundlesSelector,
    eventsLoadingSelector,
    eventsTranslationsSelector,
    eventsTranslationsLoadingSelector,
    eventsContentTranslationsSelector,
    eventsContentTranslationsLoadingSelector,
    eventsSidebarTimeStampSelector,
    isNewEventsShownSelector,
};
