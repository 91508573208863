import React from 'react';

export interface ITestingProperties {
    id?: string;
    name?: string;
}

function withTestingProperties<T>(WC: React.ComponentType<T>) {
    const MyComponent: React.FC<T & ITestingProperties> = ({ id, name, ...props }) => {
        const badgeTextProperties: ITestingProperties = {};

        if (id) {
            badgeTextProperties.id = id;
        }

        if (name) {
            badgeTextProperties.name = name;
        }

        return <WC testingProperties={badgeTextProperties} {...(props as T)} />;
    };

    return MyComponent;
}

export default withTestingProperties;
