import { type IGlobalState } from '../../@types/store';
import { theme } from '../../theme/theme';
import { isAuthenticated } from './operations';

export const initialGlobalState: IGlobalState = {
    theme: theme('dark'),
    snackbar: {
        open: false,
        variant: 'info',
    },
    modal: {
        open: false,
        variant: '',
    },
    errorModal: {
        open: false,
        title: '',
        description: '',
        type: 'game-in-maintenance',
        descriptionTranslationKey: '',
        titleTranslationKey: '',
    },
    authenticated: (isAuthenticated && isAuthenticated()) || false,
    currentUserLoading: false,
    currentUser: null,
    isAuthenticatedModal: {
        open: false,
        type: 'storeAuth',
    },
    playOnPCDialogData: {
        titleText: '',
        bodyText: '',
        confirmText: 'Yes',
        declineText: 'No',
        acceptAction: () => ({}),
        declineAction: () => ({}),
        open: false,
    },
    killSwitch: null,
    banner: null,
};
