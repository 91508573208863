import styled from 'styled-components';

export const PlayerProfileInfoStyled = styled.div`
    ${({ theme }) => theme.breakpoints.down('xs')} {
        position: unset;
    }

    .info-text {
        line-height: 13px;
    }

    .profile-info__dropdown-button {
        color: ${({ theme }) => theme.palette.grey.light[800]};
    }

    .WP-PlayerProfileInfo-dropDown-arrow {
        transition: transform 350ms ease-out;

        ${({ open }) => !open} {
            transform: rotate(180deg);
        }
    }

    .profile-info__link {
        text-decoration: none;
        padding-right: 12px;
    }

    .menu-wrapper {
        width: 100%;
        top: 65px;
        left: 0;
        max-width: 290px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            top: 70px;
            padding: 0 20px;
            max-width: 100%;
        }
    }

    .bottom:after {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            left: auto;
            right: 56px;
        }
    }

    .WP-PlayerProfileInfo-web-coin-box {
        min-width: 168px;

        img {
            width: 14px;
        }
    }

    .WP-PlayerProfileInfo-web-profile-info {
        min-width: 184px;
    }

    .offer-purchasing {
        pointer-events: none;
    }

    .WP-PlayerProfileInfo-tooltip-divider {
        background-color: ${({ theme }) => theme.palette.white.transparent10};
    }
`;
