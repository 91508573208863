import { combineReducers } from '@reduxjs/toolkit';
import { type IReducers } from 'src/@types/store/reducers';

import eventsSlice from './events';
import globalSlice from './global';
import newsSlice from './news';
import notificationsSlice from './notifications';
import offerSlice from './offer';
import { redeemSlice } from './redeem/redeemSlice';
import { translationsSlice } from './translation/translationSlice';

export const reducers = () => {
    const mergedReducers: IReducers = {
        global: globalSlice.reducer,
        news: newsSlice.reducer,
        offer: offerSlice.reducer,
        translations: translationsSlice.reducer,
        notifications: notificationsSlice.reducer,
        events: eventsSlice.reducer,
        redeem: redeemSlice.reducer,
    };

    return combineReducers({
        ...mergedReducers,
    });
};
