import React from 'react';
import { Box as MUIBox } from '@mui/material';

import { BoxStyled } from './Box.styled';

interface BoxProps {
    bgcolor?: string;
    bgimage?: string;
    bordercolor?: string;
    children?: React.ReactNode;
    [key: string]: any;
}

export const Box: React.FC<BoxProps> = (props) => {
    return (
        <MUIBox
            component={BoxStyled}
            bgcolor={props.bgcolor}
            bgimage={props.bgimage}
            bordercolor={props.bordercolor}
            {...props}
        >
            {props.children}
        </MUIBox>
    );
};
