import { hexToRgba } from 'src/helpers/styleHelpers';
import styled from 'styled-components';

import AccountSuspended from '../../../../assets/images/AccountSuspended.png';
import ArchivedAccount from '../../../../assets/images/ArchivedAccount.png';
import ModalBg from '../../../../assets/images/AuthorizeModalBg.png';
import Maintenance from '../../../../assets/images/Maintenance.png';
import ServerMaintenance from '../../../../assets/images/Maintenance-avatar.png';
import NotConnectedAccount from '../../../../assets/images/NotConnectedAccount.png';
import PaymentSuccess from '../../../../assets/images/PaymentSuccess.png';

export const ModalBodyStyled = styled.div`
    overflow: auto;
    scrollbar-width: none;

    ${({ theme: { helpers } }) =>
        helpers.toggleScrollBar({
            enable: false,
        })};

    .WP-Modal-background {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 85%;
        background-image: ${({
            theme: {
                palette: { mode },
            },
        }) =>
            mode === 'dark'
                ? `linear-gradient(180deg, #292B2E 0%, rgba(41, 43, 46, 0) 100%), url(${ModalBg})`
                : `linear-gradient(180deg, #f8f9fc 0%, rgba(255, 255, 255, 0) 100%), url(${ModalBg})`};
        background-size: cover;
        background-position: center left;
        background-repeat: no-repeat;
        opacity: ${({ theme }) => (theme.palette.mode === 'dark' ? 1 : 0.5)};
    }
    .WP-Modal-loading {
        border: 1px solid transparent;
        border-radius: 50%;
        ${({
            theme: {
                palette,
                spacing,
                palette: { mode },
            },
        }) => ({
            padding: `${spacing(3)}px`,
            borderColor:
                mode === 'dark' ? hexToRgba(palette.common.white, 0.05) : palette.common.white,
        })};
        circle {
            stroke: url(#linearColors);
            border: 1px solid rgba(255, 255, 255, 0.05);
            stroke-linecap: round;
        }
    }
    .WP-Modal-Payment-Success {
        background-image: url(${PaymentSuccess});
        border: 1px solid transparent;
    }
    .WP-Modal-Not-Connected-Account {
        background-image: url(${NotConnectedAccount});
        background-size: 40%;
        border: 1px solid transparent;
    }
    .WP-Modal-Account-Suspended {
        background-image: url(${AccountSuspended});
        background-size: 60%;
        border: 1px solid transparent;
    }
    .WP-Modal-Account-Archived {
        background-image: url(${ArchivedAccount});
        background-size: 60%;
        border: 1px solid transparent;
    }
    .WP-Modal-Game-Unavailable {
        background-image: url(${Maintenance});
        background-size: 60%;
        border: 1px solid transparent;
    }
    .WP-Modal-Server-in-Maintenance {
        background-image: url(${ServerMaintenance});
        background-size: 40%;
        border: 1px solid transparent;
    }
    .WP-Modal-Payment-Success,
    .WP-Modal-Not-Connected-Account,
    .WP-Modal-Server-in-Maintenance,
    .WP-Modal-Account-Suspended,
    .WP-Modal-Account-Archived,
    .WP-Modal-Game-Unavailable {
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            backgroundColor:
                mode === 'dark' ? hexToRgba(palette.common.white, 0.08) : palette.common.white,
            borderColor:
                mode === 'dark' ? hexToRgba(palette.common.white, 0.05) : palette.common.white,
        })};
    }
    .WP-Modal-Reward-Claimed-Success {
        h2 {
            font-size: ${({ theme }) => theme.typography.size.p28.fontSize};
            line-height: ${({ theme }) => theme.typography.size.p28.lineHeight};
        }
        .WP-Modal-text {
            margin-top: 20px;
            max-width: 340px;
        }
    }
    .large {
        padding: 13px 20px;
    }
    .news-title {
        /* white-space: nowrap; */
        /* text-overflow: ellipsis; */
        /* overflow: hidden; */
        /* line-clamp: 2; */
        span {
            color: ${({ theme }) => theme.components.inGamesNewsCard.titleBlueC};
        }
        span:nth-child(2) {
            display: inline-block;
            margin-right: -1px;
        }
        &.important {
            span {
                color: ${({ theme }) => theme.components.inGamesNewsCard.titleYellowC};
            }
        }
    }
    .WP-BorderedContent-body {
        /* width: 100px; */
    }
    .WP-InGameNewsDetailsModal-content {
        overflow-y: auto;
        scrollbar-width: thin;
        .WP-InGameNewsDEtailsModal-Content-img {
            object-fit: cover;
        }
        pre {
            ${({
                theme: {
                    palette,
                    typography,
                    palette: { mode },
                },
            }) => ({
                color: mode === 'dark' ? palette.grey.light[100] : palette.lightMode.grey[700],
                fontFamily: typography.font.univiaPro,
                fontSize: typography.size.p16.fontSize,
                lineHeight: typography.size.p16.lineHeight,
            })};
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        a {
            text-decoration: none;
        }

        ${({
            theme: {
                helpers,
                palette: { mode },
            },
        }) =>
            helpers.toggleScrollBar({
                isDark: mode === 'dark',
                enable: true,
            })};
    }
    .WP-OfferDetailsModal {
        &-itemCount {
            word-break: break-word;
        }
        &-confirmButton {
            width: 100%;
            height: 56px;
            max-width: 220px;
        }
        &-content-body {
            &:after {
                content: '';
                width: 100%;
                height: 95px;
                position: absolute;
                left: 0;
                bottom: 0;
                background: ${({
                    theme: {
                        palette: { mode },
                    },
                }) =>
                    mode === 'dark'
                        ? 'linear-gradient(180deg, rgba(41, 43, 46, 0) 0%, #292b2e 100%)'
                        : 'linear-gradient(180deg, rgba(238, 239, 241, 0) 0%, #EEEFF1 100%)'};
                z-index: 10;
                pointer-events: none;
            }
        }
    }
    .WP-OfferDetailsModalItem-title {
        text-transform: capitalize;
    }
    /* TODO move styled to BottomGradient component */
    .WP-MonthlyRewardModal {
        &-content-body {
            &:after {
                content: '';
                width: 70px;
                height: 100%;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 10;
                pointer-events: none;
                ${({
                    theme: {
                        breakpoints,
                        palette: { mode },
                    },
                }) => ({
                    background:
                        mode === 'dark'
                            ? 'linear-gradient(270deg, #292B2E 0%, rgba(41, 43, 46, 0) 100%)'
                            : 'linear-gradient(270deg, #F8F9FC 0%, rgba(248, 249, 252, 0) 100%)',
                    [breakpoints.up('md')]: {
                        width: '100%',
                        height: '95px',
                        background:
                            mode === 'dark'
                                ? 'linear-gradient(180deg, rgba(41, 43, 46, 0) 0%, #292b2e 100%)'
                                : 'linear-gradient(180deg, rgba(248, 249, 252, 0) 0%, #F8F9FC 100%)',
                    },
                })};
            }
        }
        &-content-body-grid {
            flex-wrap: nowrap;
            overflow-x: scroll;
            ${({ theme }) => theme.breakpoints.up('md')} {
                flex-wrap: wrap;
            }
        }
    }
`;
