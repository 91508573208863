/* eslint-disable no-undef */
import { type TURLSearchParams } from 'src/@types';
import { LANGUAGE_STORAGE_KEY, LANGUAGES_KEYS } from 'src/constants/project.constants';

export const getPageLoadDuration = () => {
    const entriesValues = performance.getEntriesByType('navigation')[0];

    if (!entriesValues) {
        return 0;
    }
    return Math.floor(entriesValues.duration / 1000);
};

export const getPageHeight = () => {
    const body = document.body,
        html = document.documentElement;

    return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
    );
};

export const getSearchParams = (search: TURLSearchParams): URLSearchParams => {
    return new URLSearchParams(search);
};

export const getUserPreferredLanguage = () => {
    const getLanguageKey = (language: string): string => {
        return language.split('-')[0]!;
    };

    const isUserDefaultLanguageSupported = (defaultLanguageKey: string) => {
        return LANGUAGES_KEYS.find((lang) => defaultLanguageKey === lang);
    };

    return navigator.language && isUserDefaultLanguageSupported(getLanguageKey(navigator.language));
};

export const getUserSelectedLanguage = () => {
    if (!document.cookie) {
        return 'en';
    }

    // TODO throws an error for local storage service implementations
    // react eject could help
    const storedLanguageKey = localStorage.getItem(LANGUAGE_STORAGE_KEY);

    if (storedLanguageKey) {
        return JSON.parse(storedLanguageKey);
    }

    const userPreferredLanguage = getUserPreferredLanguage();

    return userPreferredLanguage || 'en';
};

export const updateHistoryWithoutReRender = (url = '') => {
    return window.history.replaceState({ ...window.history.state, as: url, url: url }, '', url);
};

export const getElementTopPositionFromPageTop = (elementRect: HTMLElement | null) => {
    if (!elementRect) {
        return;
    }
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = elementRect.getBoundingClientRect();

    return elemRect.top - bodyRect.top;
};
