import { hexToRgba } from 'src/helpers/styleHelpers';
import styled from 'styled-components';

export const LabelStyled = styled.span<{ hasAction: boolean; hasFullWidth: boolean }>`
    transition: ${({ theme }) => theme.helpers.transitions('background-color')};
    display: ${({ hasFullWidth }) => (hasFullWidth ? 'block' : 'inline-block')};

    &.medium {
        padding: 8px 16px;
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p14.lineHeight};
    }

    &.small {
        padding: 6px 12px;
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p12.lineHeight};
    }

    &.contained {
        color: ${({ theme }) => theme.palette.common.white};
        border: 1px solid transparent;

        &-green {
            background-color: ${({ theme }) => theme.palette.green[600]};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.green[500] : theme.palette.green[600]};
            }
        }

        &-blue {
            background-color: ${({ theme }) => theme.palette.blue[600]};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.blue[500] : theme.palette.blue[600]};
            }
        }

        &-red {
            background-color: ${({ theme }) => theme.palette.red[600]};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.red[500] : theme.palette.red[600]};
            }
        }

        &-yellow {
            background-color: ${({ theme }) => theme.palette.yellow[800]};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.yellow[700] : theme.palette.yellow[800]};
            }
        }

        &-grey-1 {
            color: ${({ theme }) => theme.palette.grey.light[700]};
            background-color: ${({ theme }) => theme.palette.grey.dark[600]};
            border-color: ${({ theme }) => theme.palette.white.transparent10};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.dark[500] : theme.palette.grey.dark[600]};
            }
        }

        &-grey-2 {
            color: ${({ theme }) => theme.palette.grey.light[700]};
            background-color: ${({ theme }) => theme.palette.grey.dark[500]};
            border-color: ${({ theme }) => theme.palette.white.transparent10};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.dark[400] : theme.palette.grey.dark[500]};
            }
        }

        &-grey-3 {
            color: ${({ theme }) => theme.palette.grey.light[600]};
            background-color: ${({ theme }) => theme.palette.grey.dark[400]};
            border-color: ${({ theme }) => theme.palette.white.transparent10};

            &:hover {
                background-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.dark[300] : theme.palette.grey.dark[400]};
            }
        }
    }

    &.outlined {
        border: 1px solid;

        &-green {
            color: ${({ theme }) => theme.palette.green[600]};
            border-color: ${({ theme }) => theme.palette.green[600]};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.green[400] : theme.palette.green[600]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.green[400] : theme.palette.green[600]};
            }
        }

        &-blue {
            color: ${({ theme }) => theme.palette.blue[400]};
            border-color: ${({ theme }) => theme.palette.blue[400]};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.blue[200] : theme.palette.blue[400]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.blue[200] : theme.palette.blue[400]};
            }
        }

        &-red {
            color: ${({ theme }) => theme.palette.red[400]};
            border-color: ${({ theme }) => theme.palette.red[400]};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.red[300] : theme.palette.red[400]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.red[300] : theme.palette.red[400]};
            }
        }

        &-yellow {
            color: ${({ theme }) => theme.palette.yellow[700]};
            border-color: ${({ theme }) => theme.palette.yellow[700]};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.yellow[500] : theme.palette.yellow[700]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.yellow[500] : theme.palette.yellow[700]};
            }
        }

        &-grey-1 {
            color: ${({ theme }) => theme.palette.grey.light[700]};
            border-color: ${({ theme }) => hexToRgba(theme.palette.common.white, 0.4)};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.light[600] : theme.palette.grey.light[700]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction
                        ? hexToRgba(theme.palette.common.white, 0.5)
                        : hexToRgba(theme.palette.common.white, 0.4)};
            }
        }

        &-grey-2 {
            color: ${({ theme }) => theme.palette.grey.light[700]};
            border-color: ${({ theme }) => theme.palette.white.transparent20};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.light[600] : theme.palette.grey.light[700]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction
                        ? theme.palette.white.transparent30
                        : theme.palette.white.transparent20};
            }
        }

        &-grey-3 {
            color: ${({ theme }) => theme.palette.grey.light[800]};
            border-color: ${({ theme }) => theme.palette.white.transparent10};

            &:hover {
                color: ${({ theme, hasAction }) =>
                    hasAction ? theme.palette.grey.light[700] : theme.palette.grey.light[800]};
                border-color: ${({ theme, hasAction }) =>
                    hasAction
                        ? theme.palette.white.transparent20
                        : theme.palette.white.transparent10};
            }
        }
    }
`;
