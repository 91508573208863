import de_android_black from '../assets/images/downloadGame/android/de_android_black.svg';
import de_android_white from '../assets/images/downloadGame/android/de_android_white.svg';
import en_android_black from '../assets/images/downloadGame/android/en_android_black.svg';
import en_android_white from '../assets/images/downloadGame/android/en_android_white.svg';
import es_android_black from '../assets/images/downloadGame/android/es_android_black.svg';
import es_android_white from '../assets/images/downloadGame/android/es_android_white.svg';
import fr_android_black from '../assets/images/downloadGame/android/fr_android_black.svg';
import fr_android_white from '../assets/images/downloadGame/android/fr_android_white.svg';
import it_android_black from '../assets/images/downloadGame/android/it_android_black.svg';
import it_android_white from '../assets/images/downloadGame/android/it_android_white.svg';
import ja_android_black from '../assets/images/downloadGame/android/ja_android_black.svg';
import ja_android_white from '../assets/images/downloadGame/android/ja_android_white.svg';
import ko_android_black from '../assets/images/downloadGame/android/ko_android_black.svg';
import ko_android_white from '../assets/images/downloadGame/android/ko_android_white.svg';
import pt_android_black from '../assets/images/downloadGame/android/pt_android_black.svg';
import pt_android_white from '../assets/images/downloadGame/android/pt_android_white.svg';
import ru_android_black from '../assets/images/downloadGame/android/ru_android_black.svg';
import ru_android_white from '../assets/images/downloadGame/android/ru_android_white.svg';
import de_iOS_black from '../assets/images/downloadGame/iOS/de_ios_black.svg';
import de_iOS_white from '../assets/images/downloadGame/iOS/de_ios_white.svg';
import en_iOS_black from '../assets/images/downloadGame/iOS/en_ios_black.svg';
import en_iOS_white from '../assets/images/downloadGame/iOS/en_ios_white.svg';
import es_iOS_black from '../assets/images/downloadGame/iOS/es_ios_black.svg';
import es_iOS_white from '../assets/images/downloadGame/iOS/es_ios_white.svg';
import fr_iOS_black from '../assets/images/downloadGame/iOS/fr_ios_black.svg';
import fr_iOS_white from '../assets/images/downloadGame/iOS/fr_ios_white.svg';
import it_iOS_black from '../assets/images/downloadGame/iOS/it_ios_black.svg';
import it_iOS_white from '../assets/images/downloadGame/iOS/it_ios_white.svg';
import ja_iOS_black from '../assets/images/downloadGame/iOS/ja_ios_black.svg';
import ja_iOS_white from '../assets/images/downloadGame/iOS/ja_ios_white.svg';
import ko_iOS_black from '../assets/images/downloadGame/iOS/ko_ios_black.svg';
import ko_iOS_white from '../assets/images/downloadGame/iOS/ko_ios_white.svg';
import pt_iOS_black from '../assets/images/downloadGame/iOS/pt_ios_black.svg';
import pt_iOS_white from '../assets/images/downloadGame/iOS/pt_ios_white.svg';
import ru_iOS_black from '../assets/images/downloadGame/iOS/ru_ios_black.svg';
import ru_iOS_white from '../assets/images/downloadGame/iOS/ru_ios_white.svg';

type TDownloadGameButtons = {
    de_android_black: string;
    de_android_white: string;
    en_android_black: string;
    en_android_white: string;
    es_android_black: string;
    es_android_white: string;
    fr_android_black: string;
    fr_android_white: string;
    it_android_black: string;
    it_android_white: string;
    ja_android_black: string;
    ja_android_white: string;
    ko_android_black: string;
    ko_android_white: string;
    pt_android_black: string;
    pt_android_white: string;
    ru_android_black: string;
    ru_android_white: string;
    de_iOS_black: string;
    de_iOS_white: string;
    en_iOS_black: string;
    en_iOS_white: string;
    es_iOS_black: string;
    es_iOS_white: string;
    fr_iOS_black: string;
    fr_iOS_white: string;
    it_iOS_black: string;
    it_iOS_white: string;
    ja_iOS_black: string;
    ja_iOS_white: string;
    ko_iOS_black: string;
    ko_iOS_white: string;
    pt_iOS_black: string;
    pt_iOS_white: string;
    ru_iOS_black: string;
    ru_iOS_white: string;
};

export const downloadGameButtons: TDownloadGameButtons = {
    de_android_black,
    de_android_white,
    en_android_black,
    en_android_white,
    es_android_black,
    es_android_white,
    fr_android_black,
    fr_android_white,
    it_android_black,
    it_android_white,
    ja_android_black,
    ja_android_white,
    ko_android_black,
    ko_android_white,
    pt_android_black,
    pt_android_white,
    ru_android_black,
    ru_android_white,
    de_iOS_black,
    de_iOS_white,
    en_iOS_black,
    en_iOS_white,
    es_iOS_black,
    es_iOS_white,
    fr_iOS_black,
    fr_iOS_white,
    it_iOS_black,
    it_iOS_white,
    ja_iOS_black,
    ja_iOS_white,
    ko_iOS_black,
    ko_iOS_white,
    pt_iOS_black,
    pt_iOS_white,
    ru_iOS_black,
    ru_iOS_white,
};
