import React from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Box } from '../ui-components/Box/Box';
import { Icon } from '../ui-components/Icon/Icon';
import { OblivionStyled } from './Oblivion.styled';

export const Oblivion = ({
    children,
    onClick,
    className,
    icon,
    imgUrl,
    iconSize,
    isLoading,
    testId,
}) => {
    return (
        <OblivionStyled className={className} onClick={onClick} data-testid={testId}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" mr={1.5}>
                    <CircularProgress
                        size={26}
                        className="loading-icon-rotation"
                        variant="determinate"
                        value={75}
                    />
                </Box>
            ) : icon ? (
                <Box
                    component={Icon}
                    size={iconSize}
                    mr={1}
                    zIndex={1}
                    className={clsx(icon, 'button-icon')}
                />
            ) : (
                <Box
                    component="img"
                    position="relative"
                    mr={2}
                    width={20}
                    height={29}
                    src={imgUrl}
                    alt="mobile-logo"
                    zIndex={1}
                />
            )}
            {children}
        </OblivionStyled>
    );
};

Oblivion.defaultProps = {
    iconSize: 14,
};

Oblivion.propTypes = {
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    children: PropTypes.node,
    onClick: PropTypes.func,
    className: PropTypes.string,
    imgUrl: PropTypes.string,
    isLoading: PropTypes.bool,
    testId: PropTypes.string,
};
