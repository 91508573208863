import { type FC as FunctionalComponent, lazy, type LazyExoticComponent } from 'react';

const Dashboard = lazy(() => import('../views/pages/Dashboard/Dashboard'));
const Store = lazy(() => import('../views/pages/Store/Store'));
const News = lazy(() => import('../views/pages/News/News'));
const NewsDetails = lazy(() => import('../views/pages/NewsDetails/NewsDetails'));
const StyleGuide = lazy(() => import('../views/pages/StyleGuide/StyleGuide'));
const Maintenance = lazy(() => import('../views/pages/Maintenance/Maintenance'));
const RedeemCode = lazy(() => import('../views/pages/RedeemCode/RedeemCode'));
const Events = lazy(() => import('../views/pages/Events/Events'));
const TranslationsHighlight = lazy(
    () => import('../views/pages/TranslationsHighlight/TranslationsHighlight'),
);
const NotFound = lazy(() => import('../views/pages/NotFound/NotFound'));

type TAppRoute = {
    path: string;
    redirect?: string;
    component: LazyExoticComponent<FunctionalComponent<any>>;
    private: boolean;
    testing?: boolean;
    children?: TAppRoute[];
};

export const routes: TAppRoute[] = [
    {
        path: '/',
        component: Dashboard,
        private: false,
        children: [],
    },
    {
        path: '/store',
        component: Store,
        redirect: '/',
        private: true,
        children: [],
    },
    {
        path: '/store/xsolla/:offerBundleId',
        component: Store,
        redirect: '/',
        private: true,
        children: [],
    },
    {
        path: '/store/:offerBundleId',
        component: Store,
        redirect: '/',
        private: true,
        children: [],
    },
    {
        path: '/news',
        component: News,
        private: false,
        children: [],
    },
    {
        path: '/events',
        component: Events,
        private: true,
        children: [],
    },
    {
        path: '/news/:slug',
        component: NewsDetails,
        private: false,
        children: [],
    },
    {
        path: '/style-guide',
        component: StyleGuide,
        private: false,
        children: [],
        testing: true,
    },
    {
        path: '/translation-components-preview',
        component: TranslationsHighlight,
        private: false,
        children: [],
        testing: false,
    },
    {
        path: '/maintenance',
        component: Maintenance,
        private: false,
        children: [],
    },
    {
        path: '/redeem-code',
        component: RedeemCode,
        redirect: '/',
        private: true,
        children: [],
    },
    {
        path: '*',
        component: NotFound,
        private: false,
        children: [],
    },
];
