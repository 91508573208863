import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type TGetConfigResponse } from 'src/@types';

import {
    type ICurrentUser,
    type IGlobalConfig,
    type IKillSwitchConfig,
    type TErrorModal,
    type TPlayOnPCDialogData,
    type TSnackbar,
} from '../../@types/store/global';
import { theme } from '../../theme/theme';
import { initialGlobalState } from './initialState';

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setCurrentUser(state, action: PayloadAction<null | ICurrentUser>) {
            state.currentUser = action.payload;
            state.modal = {
                variant: '',
                open: false,
            };
        },
        setAuthenticated(state, action: PayloadAction<boolean>) {
            state.authenticated = action.payload;
        },
        setSnackbar(state, action: PayloadAction<TSnackbar>) {
            state.snackbar = action.payload;
        },
        setAuthenticatedModal(
            state,
            action: PayloadAction<{
                open: boolean;
                type: 'storeAuth' | 'eventsAuth' | 'redeemAuth';
            }>,
        ) {
            state.isAuthenticatedModal.open = action.payload.open;
            state.isAuthenticatedModal.type = action.payload.type
                ? action.payload.type
                : state.isAuthenticatedModal.type;
        },
        setPlayOnPCDialogData(state, action: PayloadAction<TPlayOnPCDialogData>) {
            state.playOnPCDialogData = action.payload;
        },
        setConfigs(state, action: PayloadAction<TGetConfigResponse>) {
            //todo need to fix data from backend(killSwitch1)
            let killSwitch: null | IKillSwitchConfig = null;
            let banner: null | IGlobalConfig = null;

            action.payload.forEach((item) => {
                if (item.name === 'killSwitch') {
                    killSwitch = item;
                } else if (item.name === 'global') {
                    banner = item;
                }
            });

            state.killSwitch = killSwitch;
            state.banner = banner;
        },
        setErrorModal(state, action: PayloadAction<TErrorModal>) {
            state.errorModal = action.payload;
        },
        setColorMode(state, action: PayloadAction<'dark' | 'light'>) {
            state.theme = theme(action.payload);
            state.theme.palette.mode = action.payload;
        },
    },
});
