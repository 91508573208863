import { type IStoreState } from 'src/@types/store';

const isAuthSelector = (state: IStoreState) => state.global.authenticated;
const currentUserSelector = (state: IStoreState) => state.global.currentUser;
const snackbarSelector = (state: IStoreState) => state.global.snackbar;
const themeSelector = (state: IStoreState) => state.global.theme;
const modalSelector = (state: IStoreState) => state.global.modal;
const isLoadingSelector = (state: IStoreState) => state.global.isLoading;
const playOnPCDialogDataSelector = (state: IStoreState) => state.global.playOnPCDialogData;
const isAuthenticatedModalSelector = (state: IStoreState) => state.global.isAuthenticatedModal;
const killSwitchConfig = (state: IStoreState) => state.global.killSwitch;
const bannerConfig = (state: IStoreState) => state.global.banner;
const errorModalSelector = (state: IStoreState) => state.global.errorModal;
const colorModeSelector = (state: IStoreState) => state.global.theme.palette.mode;

export const globalSel = {
    isAuthSelector,
    currentUserSelector,
    themeSelector,
    snackbarSelector,
    modalSelector,
    isLoadingSelector,
    isAuthenticatedModalSelector,
    playOnPCDialogDataSelector,
    killSwitchConfig,
    bannerConfig, // TODO rename to globalConf
    errorModalSelector,
    colorModeSelector,
};
