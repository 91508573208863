import styled from 'styled-components';

interface StyledProps {
    bgcolor?: string;
    bgimage?: string;
    bordercolor?: string;
    [key: string]: string | undefined;
}

export const BoxStyled = styled.div<StyledProps>`
    background-color: ${({ bgcolor }) => bgcolor};
    ${({ bgimage }) => bgimage && `background-image: url(${bgimage})`};
    border-color: ${({ bordercolor }) => bordercolor} !important;
`;
