type fontProperties = {
    fontSize: string;
    lineHeight: string;
};

export interface MaterialTypography {
    size: {
        h1: fontProperties;
        h2: fontProperties;
        h3: fontProperties;
        h4: fontProperties;
        p54: fontProperties;
        p40: fontProperties;
        p32: fontProperties;
        p28: fontProperties;
        p26: fontProperties;
        p24: fontProperties;
        p20: fontProperties;
        p18: fontProperties;
        p16: fontProperties;
        p14: fontProperties;
        p12: fontProperties;
        p10: fontProperties;
        pre14: fontProperties;
    };
    weight: {
        bold: number;
        semiBold: number;
        medium: number;
        regular: number;
        book: number;
    };
    font: {
        architectsDaughter: string;
        fingerPaint: string;
        inter: string;
        univiaPro: string;
    };
}

export default {
    size: {
        h1: {
            fontSize: 32,
            lineHeight: '44px',
            fontWeight: 400,
        },
        h2: {
            fontSize: 28,
            lineHeight: '38px',
            fontWeight: 400,
        },
        h3: {
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 400,
        },
        h4: {
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 400,
        },
        p54: {
            fontSize: 54,
            lineHeight: '72px',
            fontWeight: 400,
        },
        p40: {
            fontSize: 40,
            lineHeight: '54px',
            fontWeight: 400,
        },
        p32: {
            fontSize: 32,
            lineHeight: '38px',
            fontWeight: 400,
        },
        p26: {
            fontSize: 26,
            lineHeight: '34px',
            fontWeight: 400,
        },
        p24: {
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 400,
        },
        p20: {
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 400,
        },
        p18: {
            fontSize: 18,
            lineHeight: '26px',
            fontWeight: 400,
        },
        p16: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 400,
        },
        p14: {
            fontSize: 14,
            lineHeight: '22px',
            fontWeight: 400,
        },
        p12: {
            fontSize: 12,
            lineHeight: '18px',
            fontWeight: 400,
        },
        p10: {
            fontSize: 10,
            lineHeight: '12px',
            fontWeight: 400,
        },
        pre14: {
            fontSize: 14,
            lineHeight: '22px',
            fontWeight: 400,
        },
    },
    weight: {
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400,
        book: 350,
    },
    font: {
        architectsDaughter: 'Architects Daughter',
        fingerPaint: 'Finger Paint',
        inter: 'Inter',
        univiaPro: 'Univia Pro',
    },
} as const;
