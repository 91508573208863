import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import helpShift from '../configs/helpShift.config';
import { translationsSel } from '../store/translation';

export const useHelpShift = () => {
    const selectedLanguage = useSelector(translationsSel.selectedLanguageSelector);

    useEffect(() => {
        try {
            helpShift(selectedLanguage);
        } catch (e) {
            // console.log(e);
        }
    }, [selectedLanguage]);
};
