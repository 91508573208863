import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { toggleBodyOverflow } from 'src/helpers/styleHelpers';
import { getPageLoadDuration } from 'src/helpers/webEnvHelpers';

import { APP_REDIRECT_URI } from '../../constants';
import { googleAnalyticsEvent } from '../../helpers/GA4Helper';
import { useIsMounted, useLogIn, useLogout } from '../../hooks';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../../services';
import { globalOp, globalSel } from '../../store/global';
import { Backdrop } from '../components/ui-components/Backdrop/Backdrop';
import { Box } from '../components/ui-components/Box/Box';
import Header from './Header/Header';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import Main from './Main/Main';
import CommonModals from './Modals/CommonModals';
import RightSidebar from './RightSidebar/RightSidebar';
import { useAppDispatch } from 'src/store/configureStore';

interface IProps {
    children: React.ReactNode;
}

let previousPath = '';

const Layout: React.FC<IProps> = ({ children }) => {
    const isMounted = useIsMounted();

    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [openLeftSidebar, setOpenLeftSidebar] = useState(false);
    const [openRightSidebar, setOpenRightSidebar] = useState(false);

    const isLoggedIn = useSelector(globalSel.isAuthSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);

    const handleLeftSidebar = () => {
        setOpenLeftSidebar(false);
    };

    const handleRightSidebar = () => {
        setOpenRightSidebar(false);
    };

    useEffect(() => {
        const storeRedirect = process.env.REACT_APP_AUTH_SUCCESS_REDIRECT as string;
        const storeOfferPreviewRedirect = process.env
            .REACT_APP_AUTH_SUCCESS_REDIRECT_TO_OFFER as string;

        if (authenticated) {
            const redirectAfterSignIn = getSessionStorage(storeRedirect);
            const redirectToOfferAfterSignIn = getSessionStorage(storeOfferPreviewRedirect);

            dispatch(globalOp.authMeAsync());

            if (redirectAfterSignIn) {
                navigate(redirectAfterSignIn);
                removeSessionStorage(storeRedirect);
            }

            if (redirectToOfferAfterSignIn) {
                navigate(redirectToOfferAfterSignIn);
                removeSessionStorage(storeOfferPreviewRedirect);
            }

            return;
        }

        if (!authenticated && matchPath({ path: '/store' }, pathname)) {
            setSessionStorage(storeRedirect, pathname);
        }
    }, [authenticated]);

    useEffect(() => {
        toggleBodyOverflow(openLeftSidebar);
    }, [openLeftSidebar]);

    useEffect(() => {
        toggleBodyOverflow(openRightSidebar);
    }, [openRightSidebar]);

    useEffect(() => {
        if (isMounted) {
            dispatch(globalOp.getConfigs());
        }

        if (pathname !== previousPath) {
            googleAnalyticsEvent.pageLoadEvent({
                pagePathName: pathname,
                previousPath,
            });
            previousPath = pathname;
        }

        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [pathname]);

    useEffect(() => {
        const duration = getPageLoadDuration();

        googleAnalyticsEvent.appLoad({ pagePathName: pathname });
        googleAnalyticsEvent.pageLoadEvent({ pagePathName: pathname, previousPath });

        if (duration >= 20) {
            window?.DD_RUM?.addAction(`page-load-${window.location.pathname.replace('/', '')}`, {
                host: APP_REDIRECT_URI,
                path: window.location.pathname.replace('/', ''),
            });
        }
        // eslint-disable-next-line
    }, []);

    useLogIn();
    useLogout();

    return (
        <>
            <Header
                setOpenRightSidebar={setOpenRightSidebar}
                setOpenLeftSidebar={setOpenLeftSidebar}
            />

            <Box display="flex" height={1}>
                <Backdrop open={openLeftSidebar} zIndex={1150} onClick={handleLeftSidebar} />

                <LeftSidebar
                    openLeftSidebar={openLeftSidebar}
                    setOpenLeftSidebar={setOpenLeftSidebar}
                />

                <Main>{children}</Main>

                <Hidden mdUp>
                    <Backdrop open={openRightSidebar} zIndex={1150} onClick={handleRightSidebar} />
                    <RightSidebar
                        isLoggedIn={isLoggedIn}
                        openRightSidebar={openRightSidebar}
                        setOpenRightSidebar={setOpenRightSidebar}
                    />
                </Hidden>

                <CommonModals isLoggedIn={isLoggedIn} />
            </Box>
        </>
    );
};
export default Layout;
