import styled from 'styled-components';

export const LoginWithScopelyStyled = styled.div(({ theme: { palette }, isModalType }) => ({
    '.WP-LoginWithScopely': {
        '&-button': {
            borderColor: palette.white.transparent20,
            backgroundColor: 'transparent',
            color: '#1D33A5',
            padding: isModalType ? '16px 23px' : ' 10px 16px',

            '&:before': {
                borderColor: palette.common.white,
                backgroundColor: palette.common.white,
            },

            '.button-text': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        '&-circularProgress': {
            color: '#1D33A5',
            marginRight: '8px',
        },
    },
}));
