/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getDuration } from 'src/helpers/dateTimeHelpers';

import { useTranslation } from '../../../../../hooks';

const timeKeys = {
    short: {
        day: 'common_offer_remaining_time_day_short_text',
        hour: 'common_offer_remaining_time_hour_short_text',
        minutes: 'common_offer_remaining_time_minute_short_text',
        seconds: 'common_remaining_time_seconds_short_text',
        fallBackDay: 'd',
        fallBackHour: 'h',
        fallBackMinutes: 'm',
        fallBackSeconds: 's',
    },
    long: {
        day: 'common_offer_remaining_time_day_text',
        hour: 'common_offer_remaining_time_hour_text',
        minutes: 'common_offer_remaining_time_minute_text',
        seconds: 'common_offer_remaining_time_seconds_long_text',
        fallBackDay: 'Day',
        fallBackHour: 'Hour',
        fallBackMinutes: 'Minutes',
        fallBackSeconds: 'Seconds',
    },
};

const RemainingTimeLive = ({
    date = '',
    short,
    isFromDateToNow,
    dateType = 'secondsTimeStamp',
    callBackOnEnd = () => {},
}) => {
    const t = useTranslation();
    const timerRef = useRef(0);
    const timerTypeRef = useRef('seconds');
    const [, setSecondsLeft] = useState(getDuration(date).asSeconds());

    let duration;

    switch (dateType) {
        case 'secondsTimeStamp': {
            if (isFromDateToNow) {
                duration = moment.duration(moment().diff(moment(moment.unix(date))));
            } else {
                duration = moment.duration(moment.unix(date).diff(moment()));
            }
            break;
        }

        default: {
            if (isFromDateToNow) {
                duration = moment.duration(moment().diff(moment(moment.parseZone(date))));
            } else {
                duration = moment.duration(moment.parseZone(date).diff(moment()));
            }
        }
    }
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const remainingTime = () => {
        if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
            return '';
        }

        if (days && hours && minutes) {
            return `${days}${
                short
                    ? t(timeKeys.short.day, timeKeys.short.fallBackDay)
                    : ` ${t(timeKeys.long.day, timeKeys.long.fallBackDay)}`
            } ${hours}${
                short
                    ? t(timeKeys.short.hour, timeKeys.short.fallBackHour)
                    : ` ${t(timeKeys.long.hour, timeKeys.long.fallBackHour)} ${t(
                          'store_offer_count_text',
                      )}`
            } ${minutes}${
                short
                    ? t(timeKeys.short.minutes, timeKeys.short.fallBackMinutes)
                    : ` ${t(timeKeys.long.minutes, timeKeys.long.fallBackMinutes)} ${t(
                          'store_offer_count_text',
                      )}`
            }`;
        } else if (days && hours) {
            return `${days}${
                short
                    ? t(timeKeys.short.day, timeKeys.short.fallBackDay)
                    : ` ${t(timeKeys.long.day, timeKeys.long.fallBackDay)}`
            } ${hours}${
                short
                    ? t(timeKeys.short.hour, timeKeys.short.fallBackHour)
                    : ` ${t(timeKeys.long.hour, timeKeys.long.fallBackHour)} ${t(
                          'store_offer_count_text',
                      )}`
            }`;
        } else if (hours && !days) {
            return `${hours}${
                short
                    ? t(timeKeys.short.hour, timeKeys.short.fallBackHour)
                    : ` ${t(timeKeys.long.hour, timeKeys.long.fallBackHour)}`
            } ${minutes}${
                short
                    ? t(timeKeys.short.minutes, timeKeys.short.fallBackMinutes)
                    : ` ${t(timeKeys.long.minutes, timeKeys.long.fallBackMinutes)} ${t(
                          'store_offer_count_text',
                      )}`
            }`;
        } else if (!hours && days) {
            return `${days} ${
                short
                    ? t(timeKeys.short.day, timeKeys.short.fallBackDay)
                    : ` ${t(timeKeys.long.day, timeKeys.long.fallBackDay)} ${t(
                          'store_offer_count_text',
                      )}`
            }`;
        } else if ((minutes && !seconds) || (minutes && seconds)) {
            return `${minutes}${
                short
                    ? t(timeKeys.short.minutes, timeKeys.short.fallBackMinutes)
                    : ` ${t(timeKeys.long.minutes, timeKeys.long.fallBackMinutes)} ${t(
                          'store_offer_count_text',
                      )}`
            } ${seconds}${
                short
                    ? t(timeKeys.short.seconds, timeKeys.short.fallBackSeconds)
                    : ` ${t(timeKeys.long.seconds, timeKeys.long.fallBackSeconds)} ${t(
                          'store_offer_count_text',
                      )}`
            }`;
        }

        return seconds === 0
            ? ''
            : `${seconds}${
                  short
                      ? t(timeKeys.short.seconds, timeKeys.short.fallBackSeconds)
                      : ` ${t(timeKeys.long.seconds, timeKeys.long.fallBackSeconds)} ${t(
                            'store_offer_count_text',
                        )}`
              }`;
    };

    const countDown = () => {
        if (timerTypeRef.current === 'minutes' && getDuration(date).asSeconds() < 3600) {
            clearInterval(timerRef.current);
            timerRef.current = setInterval(countDown, 1000);
        }
        setSecondsLeft((prevValue) => {
            let currentSeconds = prevValue - 1;
            if (currentSeconds < 1) {
                callBackOnEnd();
                clearInterval(timerRef.current);
            }
            return currentSeconds;
        });
    };

    useEffect(() => {
        if (date) {
            if (duration.asSeconds() < 3600) {
                timerRef.current = setInterval(countDown, 1000);
            } else {
                timerRef.current = setInterval(countDown, 60000);
                timerTypeRef.current = 'minutes';
            }

            setSecondsLeft(duration.asSeconds());

            return () => {
                timerTypeRef.current = 'seconds';
                clearInterval(timerRef.current);
            };
        }
        // eslint-disable-next-line
    }, [date]);

    return <>{remainingTime()}</>;
};

RemainingTimeLive.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    short: PropTypes.bool,
    isFromDateToNow: PropTypes.bool,
    dateType: PropTypes.string,
    callBackOnEnd: PropTypes.func,
};

export default RemainingTimeLive;
