import { type INewsState } from './../../@types/store/news';

export const initialNewsState: INewsState = {
    isNewsLoading: true,
    isNewsDetailsLoading: true,
    newsItems: {
        data: [],
        count: 0,
        currentPage: 1,
        limit: 0,
        pages: 0,
    },
    categoryList: [],
    banner: null,
    inGameNews: [],
    newsDetails: null,
};
