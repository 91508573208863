import { type AxiosResponse } from 'axios';
import { type ITranslationsResponse, type TOperation } from 'src/@types';
import { type TLanguageKeys } from 'src/@types/store';
import { getUserSelectedLanguage } from 'src/helpers/webEnvHelpers';

import { TRANSLATIONS_API_KEY, TRANSLATIONS_URI } from '../../constants';
import { fetch } from '../../services';
import { translationsSlice } from './translationSlice';

const setLanguage: TOperation<TLanguageKeys> = (lang = 'en') => {
    const { setLanguage } = translationsSlice.actions;

    return (dispatch) => {
        dispatch(setLanguage(lang));
    };
};

const setTranslationLoading = (page: string, loading: boolean) => {
    const { setLoading } = translationsSlice.actions;

    return (dispatch) => {
        dispatch(
            setLoading({
                page,
                loading,
            }),
        );
    };
};

const setTranslations = (translations: ITranslationsResponse[] | undefined) => {
    const { setTranslationsKeys } = translationsSlice.actions;
    if (!translations) return;
    const converted = {};

    translations.forEach((translation) => {
        const pageColumn = translation.cells?.find((item) => item.columnId === 'pageColumn');

        translation.cells?.forEach((item) => {
            converted[`${pageColumn?.value}_${translation?.id}`] = {
                ...(converted[`${pageColumn?.value}_${translation?.id}`] || {}),
                [item?.columnId]: item?.value,
            };
        });
    });

    return (dispatch) => {
        dispatch(setTranslationsKeys(converted));
    };
};

const getTranslations = (
    pages: string[] = [],
): Promise<AxiosResponse<ITranslationsResponse[]>[]> => {
    return Promise.all(
        pages.map((page) => {
            return fetch<ITranslationsResponse[]>({
                endPoint: '/records',
                body: {
                    columnIds: `pageColumn,en,${getUserSelectedLanguage()}`,
                    query: {
                        pageColumn: {
                            contains: page,
                        },
                    },
                    page: {
                        limit: 1000,
                    },
                },
                url: TRANSLATIONS_URI,
                headers: {
                    Authorization: `ApiKey ${TRANSLATIONS_API_KEY}`,
                },
            });
        }),
    );
};

export const translationsOp = {
    setTranslations,
    getTranslations,
    setTranslationLoading,
    setLanguage,
};
