export const icons = `
    [class^="icon-"], [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-alert:before {
        content: "\\e900";
    }
    .icon-all-news:before {
        content: "\\e901";
    }
    .icon-arrow-left:before {
        content: "\\e902";
    }
    .icon-article-fill:before {
        content: "\\e903";
    }
    .icon-battery-full:before {
        content: "\\e904";
    }
    .icon-broadcast:before {
        content: "\\e905";
    }
    .icon-burger:before {
        content: "\\e906";
    }
    .icon-calendar-check-fill:before {
        content: "\\e907";
    }
    .icon-chat-fill:before {
        content: "\\e908";
    }
    .icon-close:before {
        content: "\\e909";
    }
    .icon-collapse:before {
        content: "\\e90a";
    }
    .icon-crescent:before {
        content: "\\e90b";
    }
    .icon-dashboard-fill:before {
        content: "\\e90c";
    }
    .icon-discord:before {
        content: "\\e90d";
    }
    .icon-error:before {
        content: "\\e90e";
    }
    .icon-expand:before {
        content: "\\e90f";
    }
    .icon-facebook:before {
        content: "\\e910";
    }
    .icon-file-list-fill:before {
        content: "\\e911";
    }
    .icon-folder-upload-fill:before {
        content: "\\e912";
    }
    .icon-gamepad:before {
        content: "\\e913";
    }
    .icon-gamepad-arrows:before {
        content: "\\e914";
    }
    .icon-honour-fill:before {
        content: "\\e915";
    }
    .icon-hourglass:before {
        content: "\\e916";
    }
    .icon-infinite:before {
        content: "\\e917";
    }
    .icon-info:before {
        content: "\\e918";
    }
    .icon-instagram:before {
        content: "\\e919";
    }
    .icon-medal-fill:before {
        content: "\\e91a";
    }
    .icon-money-dollar-circle-fill:before {
        content: "\\e91b";
    }
    .icon-multiphasic:before {
        content: "\\e91c";
    }
    .icon-redeem:before {
        content: "\\e91d";
    }
    .icon-right-answer:before {
        content: "\\e91e";
    }
    .icon-shopping-bag:before {
        content: "\\e91f";
    }
    .icon-shopping-bag-fill:before {
        content: "\\e920";
    }
    .icon-shopping-bag-full:before {
        content: "\\e921";
    }
    .icon-space-ship-fill:before {
        content: "\\e922";
    }
    .icon-success:before {
        content: "\\e923";
    }
    .icon-success-small:before {
        content: "\\e924";
    }
    .icon-sun:before {
        content: "\\e925";
    }
    .icon-tag:before {
        content: "\\e926";
    }
    .icon-time-fill:before {
        content: "\\e927";
    }
    .icon-twitch:before {
        content: "\\e928";
    }
    .icon-twitter:before {
        content: "\\e929";
    }
    .icon-usd:before {
        content: "\\e92a";
    }
    .icon-warning:before {
        content: "\\e92b";
    }
    .icon-web-gift:before {
        content: "\\e92c";
    }
    .icon-wrong-answer:before {
        content: "\\e92d";
    }
    .icon-youtube:before {
        content: "\\e92e";
    }

`;
