/* eslint-disable no-undef */
import {
    type TFormatBundleCurrencyPrice,
    type TFormatBundleItemCount,
} from 'src/@types/helpers/dataManipulations';
import {
    type IBucket,
    type IBundle,
    type IEvent,
    type IInGameNews,
    type IStoreGroup,
} from 'src/@types/store';

import { isTimeStampPassed } from './dateTimeHelpers';

export const thousandsFormatter = (n: string | number, rounded = false) =>
    rounded
        ? Math.round(Number(n)).toLocaleString(navigator.language)
        : Number(n).toLocaleString(navigator.language);

/*
    TODO refactor content item data get method,
    it shouldn't get all items for every bundle
    data shouldn't store for every bundle, create separate property fo all
    content items data, use prefix resource/office and e.t.c for items
    ```NOT USED```
*/
//todo not used
export const setUpMissionTranslations = (bundles: []) => {
    const missionItems: any[] = [];

    bundles.forEach((bundle: { content: { list: [] } }) => {
        bundle.content.list.forEach((contentItem: { type: string }) => {
            if (contentItem.type === 'mission') {
                missionItems.push(contentItem);
            }
        });
    });

    return missionItems;
};

/*
    Sorting is using the following order:
    1. Guaranteed
    2. Chance
    3. ALLIANCE GIFT

    If there is no guarantee, then the item is sorted by the following order:
    1. Chance
    2. ALLIANCE GIFT

    Also for items there are custom sorting for the following types:
    1. Multiphasic credits -> useOfferItemsSortByMultiPhasic
*/
export const groupStoreItemsByType = (data: IBundle[], type = 'default') => {
    if (type === 'up_to') {
        return data.forEach((item) => {
            item.content.list = [];
            item.content.dynamic.forEach((dynamic: [] | {}) => {
                if (Array.isArray(dynamic)) {
                    dynamic.forEach((dynamicContent: {}) => {
                        item.content.list?.push({
                            ...dynamicContent,
                            typeTitle: 'Chance',
                            typeTitleKey: 'store_offer_content_item_type_chance_text',
                        });
                    });
                } else {
                    item.content.list?.push({
                        ...dynamic,
                        typeTitle: 'Chance',
                        typeTitleKey: 'store_offer_content_item_type_chance_text',
                    });
                }
            });
            item.content.gifts.forEach((gift: {}) =>
                item.content.list?.push({
                    ...gift,
                    typeTitle: 'ALLIANCE GIFT',
                    typeTitleKey: 'store_offer_content_item_type_alliance_gift_text',
                }),
            );
            item.content.static.forEach((content: { quantity: number }) =>
                item.content.list?.push({
                    ...content,
                    typeTitle: `Up to x${formatBundleItemCount(content.quantity * 30)}`,
                    typeTitleKey: 'store_offer_content_item_type_up_to_text',
                    typeTitleKeyVariable: formatBundleItemCount(content.quantity * 30),
                    typeTitleColor: 'green',
                }),
            );
        });
    }

    data.forEach((item) => {
        item.content.list = [];
        item.content.static.forEach((content: {}) =>
            item.content.list?.push({
                ...content,
                typeTitle: 'Guaranteed',
                typeTitleKey: 'store_offer_content_item_type_guaranteed_text',
            }),
        );
        item.content.dynamic.forEach((dynamic: [] | {}) => {
            if (Array.isArray(dynamic)) {
                dynamic.forEach((dynamicContent: {}) => {
                    item.content.list?.push({
                        ...dynamicContent,
                        typeTitle: 'Chance',
                        typeTitleKey: 'store_offer_content_item_type_chance_text',
                    });
                });
            } else {
                item.content.list?.push({
                    ...dynamic,
                    typeTitle: 'Chance',
                    typeTitleKey: 'store_offer_content_item_type_chance_text',
                });
            }
        });
        item.content.gifts.forEach((gift: {}) =>
            item.content.list?.push({
                ...gift,
                typeTitle: 'ALLIANCE GIFT',
                typeTitleKey: 'store_offer_content_item_type_alliance_gift_text',
            }),
        );
    });
};

export const groupStoreItemsByBucket = (data: IBundle[]): IBucket[] => {
    const bucketsById = new Map<number, IBucket>();
    data.forEach((item) => {
        const bucketId = item.metadata?.rendered_bucket?.id;
        if (bucketId) {
            const bucket = bucketsById.get(bucketId);
            if (bucket) {
                bucket.bundles?.push(item);
            } else {
                bucketsById.set(bucketId, {
                    ...(item.metadata.rendered_bucket as IBucket),
                    bundles: [item],
                });
            }
        }
    });
    return [...bucketsById.values()];
};

export const validateWebGifts = (data: IBundle[] = []) => {
    let filteredBundles: IBundle[] = [];

    filteredBundles = filterGiftsBySpecialAndDetails(data);
    filteredBundles = filterGiftsByCost(filteredBundles);
    filteredBundles = filterGiftsByBonusLabel(filteredBundles);

    const claimableWebGifts = filterGiftsByValidCount(filteredBundles);

    sortStoreItemsBySortOrder(filteredBundles);
    sortStoreGiftsByCategory(filteredBundles, 'webstore');

    groupStoreItemsByType(filteredBundles);

    return [filteredBundles, claimableWebGifts] as [IBundle[], IBundle[]];
};

export const filterGiftsByBonusLabel = (data: any[] | [{ bonus_label: string }]) => {
    if (data && data.length) {
        return data.filter((item) => !item.bonus_label);
    }
    return data;
};

export const filterGiftsBySpecialAndDetails = (data: IBundle[]) => {
    return data.filter(
        (offer) =>
            !offer.is_special && offer.offer_details && Object.keys(offer.offer_details).length,
    );
};

export const filterGiftsByCost = (data: IBundle[]) => {
    return data.filter((offer) => offer.cost?.[0]?.cost?.[0]?.amount === 0);
};

export const filterGiftsByValidCount = (data: IBundle[]) => {
    return data.filter((item) => {
        // Valid count will be positive number for limited bundles
        // or -1, if the bundle is "unlimited"
        const validCountCase = item.offer_details?.valid_count || 0;

        return validCountCase !== 0;
    });
};

export const sortStoreItemsBySortOrder = (data: IBundle[] | IStoreGroup[] = []) => {
    data.sort((a, b) => {
        if (a.sort_order < b.sort_order) {
            return -1;
        }
        if (a.sort_order > b.sort_order) {
            return 1;
        }

        return 0;
    });
};

/**
 * Modifies an array of store groups by inserting a specific store group at the second position.
 *
 * Since the store group "Web Gifts" is hardcoded and won't come from the backend, it will be forced to be at
 * the 2nd position.
 */
export const applyWebGiftFixedPosition = (data: IStoreGroup[], webGift: IStoreGroup) => {
    const result: IStoreGroup[] =
        data.length > 0 && typeof data[0] !== 'undefined' ? [data[0]] : [];

    // Add webGift at the second position
    result.push(webGift);
    // Append the rest of data if there are more elements beyond the first
    if (data.length > 1) {
        result.push(...data.slice(1));
    }

    return result;
};

export const sortStoreGiftsByCategory = (data: IBundle[] = [], categoryName = 'webstore') => {
    data.sort((a, b) => {
        if (a.category === categoryName) {
            return -1;
        } else if (b.category === categoryName) {
            return 1;
        }

        return 0;
    });
};

export const sortInGameNewsByPriority = (news: IInGameNews[] = []) => {
    news.sort((a, b) => {
        if (a.priority < b.priority) {
            return -1;
        }
        if (a.priority > b.priority) {
            return 1;
        }

        return 0;
    });
};

/**
 * @function formatBundleCurrencyPrice: TFormatBundleCurrencyPrice
 * Format the price numbers based on browser's locale/language.
 * @param {number} price - The number of the price
 * @param {number} quantity - The amount the price is going to be mutiplied by
 * @param {string | number} currency - The price currency brought by the API (USD, EUR, etc)
 * @return {string} Formatted price value
 * @example "99.99" becomes "99,99 EUR" or "99.99 USD"
 */
export const formatBundleCurrencyPrice: TFormatBundleCurrencyPrice = (
    price,
    quantity,
    currency,
) => {
    let roundedPrice = Math.round(price * (quantity ? quantity : 1) * 100) / 100;
    let formattedPrice = new Intl.NumberFormat(navigator.language).format(roundedPrice);

    return `${formattedPrice} ${currency}`;
};

/**
 * @function formatBundleItemCount: TFormatBundleItemCount
 * Format a numbers fraction based on browser's locale/language
 * @param {number} number - The number to be formatted
 * @return {string} Formatted number
 * @example "100.000" in "en-US" becomes 100K
 */
export const formatBundleItemCount: TFormatBundleItemCount = (number = 0) => {
    let formattedItemCount = new Intl.NumberFormat(navigator.language, {
        notation: 'compact',
        minimumSignificantDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);

    return formattedItemCount;
};

//todo not used
export const ordinalSuffixOf = (i: number) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};

export const groupEventsTranslationsById = (data) => {
    if (!Array.isArray(data)) {
        return;
    }
    const result = {};
    data.forEach((item) => {
        if (!result[item?.id]) {
            result[item?.id] = {};
        }
        result[item?.id][item?.key] = item?.text;
    });
    return result;
};

export const getUpcomingRewardPointsAndIndex = (yourCurrentPoints, rewardsList) => {
    let sortedPoints = rewardsList
        .map((reward, index) => {
            return { point: reward.position[0], index };
        })
        .sort((a, b) => a.point - b.point);

    let result = sortedPoints.find((sortedPoint) => sortedPoint.point > yourCurrentPoints);
    return result ? result : sortedPoints[sortedPoints.length - 1];
};

export const arrayElementsNotInTargetArray = (arr, target) => {
    return arr.filter((x) => !target.includes(x));
};

// TODO merge with getUpcomingRewardPointsAndIndex
export const getEventDataStatus = (eventData: IEvent) => {
    const currentPoints = eventData.ranking.score;
    const rewardList = eventData.segments[0]?.rewards || [];
    const isScheduled = !isTimeStampPassed(eventData.schedule.start);
    const isEventOver = isTimeStampPassed(eventData.schedule.display);

    const sortedPoints = rewardList
        .map((reward, index) => {
            return { point: reward.position[0], index };
        })
        .sort((a, b) => a.point - b.point);

    const result = sortedPoints.find((sortedPoint) => sortedPoint.point > currentPoints);
    const currentReward = result
        ? result
        : sortedPoints[sortedPoints.length - 1] || { point: 0, index: 0 };

    return {
        isActive: !isScheduled && !isEventOver,
        isScheduled,
        isEventOver,
        currentReward,
    };
};
