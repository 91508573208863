import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NotFoundCharacter from '../../../assets/images/FacePalm.png';
import { useTranslation } from '../../../hooks';
import { globalSel } from '../../../store/global';
import { translationsSel } from '../../../store/translation/selectors';
import TextSuspend from '../../components/TextSuspend/TextSuspend';
import { Box } from '../../components/ui-components/Box/Box';
import { Button } from '../../components/ui-components/Button/Button';
import { Typography } from '../../components/ui-components/Typography/Typography';
import { NotFoundStyled } from './NotFound.styled';

const NotFoundPage = () => {
    const t = useTranslation();

    const { palette } = useSelector(globalSel.themeSelector);
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);

    const isDark = palette.mode === 'dark';

    useEffect(() => {
        window?.DD_RUM?.addAction('404-visit-count', window?.history?.state?.as);
    }, []);

    return (
        <Box
            component={NotFoundStyled}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            position="relative"
            height={1}
            mx={[-5, -8]}
        >
            <Box className="WP-NotFound-characters-wrapper">
                <Box
                    position="relative"
                    width={[260, 390, 390, 446, 532]}
                    height={[192, 292, 292, 334, 400]}
                    className="WP-NotFound-characters"
                >
                    <Box component="img" src={NotFoundCharacter} alt="sign-in" width={1} />
                </Box>
            </Box>

            <Box mt={[-22.5, -32.5, -32.5, -38.75, -47.5]} ml={[0, 0, 6.25, 10]}>
                <Typography className="WP-NotFound-title" variant="h1">
                    404
                </Typography>
            </Box>

            <Box
                mt={[8, 10]}
                display="flex"
                alignItems="center"
                flexDirection="column"
                position="relative"
                zIndex={10}
                textAlign="center"
            >
                <Box mb={[6, 8, 8]} position="relative" zIndex={9}>
                    <Typography
                        variant={['p24', 'h1']}
                        color={isDark ? palette.grey.light[100] : palette.lightMode.grey[700]}
                    >
                        <TextSuspend loading={translationsLoading}>
                            {t('common_page_not_found_title_text', 'Page Not Found, Captain')}
                        </TextSuspend>
                    </Typography>
                </Box>

                <Link className="text-decoration-none" to="/">
                    <Button size="large" color="primary">
                        <TextSuspend loading={translationsLoading}>
                            {t('common_page_not_found_button_text', 'BACK TO HOME')}
                        </TextSuspend>
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};

export default NotFoundPage;
