import { checkBrowser, getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';
import { createGlobalStyle } from 'styled-components';

import { icons } from './icons/icons';

export const GlobalStyles = createGlobalStyle`
    ${icons}

    html {
        scrollbar-width: thin;
    }

    scrollbar-width: thin;
    overflow-x: hidden;
    margin-bottom: 0px;

    ${({
        theme: {
            helpers,
            palette: { mode },
        },
    }) => helpers.toggleScrollBar({ isDark: mode === 'dark', enable: true })};

    @media (max-width: 1279.95px) {
        :not(.enable-scroll-bar) {
            html {
                scrollbar-width: none;
            }

            &::-webkit-scrollbar {
                display: none;
                width: 0;
            }
        }
    }

    // Disable on Safari, cause bring unexpected behavior on 1280px
    ${() => {
        const isSafari = checkBrowser.isNotiOSSafariBrowser();
        const mobileDevicePlatform = getMobileOperatingSystem();
        const isDesktopSafari = mobileDevicePlatform === 'unknown' && isSafari;

        if (isDesktopSafari) {
            return `
                &::-webkit-scrollbar {
                        display: none;
                        width: 0;
                    }
                `;
        }
    }}

    body {
        ${({
            theme: {
                palette,
                typography,
                palette: { mode },
            },
        }) => ({
            color: palette.grey.light[100],
            fontFamily: `${typography.font.univiaPro}, sans-serif`,
            fontWeight: typography.weight.medium,
            background: mode === 'dark' ? '#17181A' : palette.lightMode.grey[200],

            pre: {
                fontFamily: `${typography.font.univiaPro}, sans-serif`,
            },
        })};

        margin: 0;
        min-height: 100vh;
    }

    h1, h2, h3, h4, h5, h6, p, pre {
        margin: 0;
         ${({ theme: { typography } }) => ({
             fontFamily: `${typography.font.univiaPro}, sans-serif`,
         })};
    }

    * {
        box-sizing: border-box;
    }

    input {
        border: none;

        &:focus-visible {
            outline: none;
        }
    }

    button {
        font-family: inherit;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .text-underline {
        text-decoration: underline;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .text-decoration-none {
        text-decoration: none;
    }

    .white-space-nowrap {
        white-space: nowrap;
    }

    .background-cover {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    @keyframes rotating {
        from{
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
        }
    }

    .loading-icon-rotation {
        animation:rotating 1.5s linear infinite;
    }

    .WP-scrollable-content {
        flex-grow: 1;
        overflow-y: auto;
        min-height: 0;
    }

    .WP-cursor-pointer {
        cursor: pointer;
    }

    .flex-1 {
        flex: 1;
    }

    .d-flex {
        display: flex;

        .button-text {
            display: flex;
        }
    }

    #offer-content-popover {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            width: 80%;
            max-width: 330px;
        }
        .MuiPopover-paper {
            overflow: visible;
            border: 1px solid;
            position: absolute;
            max-width: 258px;
            z-index: 10;

            &:after {
                content: '';
                width: 14px;
                height: 14px;
                position: absolute;
                border-left: 1px solid;
                border-bottom: 1px solid;
                transform: translateY(-50%) rotate(45deg);
                left: -8px;
                z-index: 11;
                top: 50%;
                ${({ theme }) => theme.breakpoints.down('xs')} {
                    transform: rotate(135deg);
                    left: 22px;
                    top: -8px;
                }
            }

            ${({
                theme: {
                    palette,
                    palette: { mode },
                },
            }) => ({
                backgroundColor: mode === 'dark' ? palette.grey.dark[500] : palette.common.white,
                borderColor: mode === 'dark' ? '#5a6066' : palette.common.white,

                '&:after': {
                    backgroundColor:
                        mode === 'dark' ? palette.grey.dark[500] : palette.common.white,
                    borderColor: mode === 'dark' ? '#5a6066' : palette.common.white,
                },
            })};

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 100%;
                max-width: 330px;
                transform: 'translateX(-50%)';
                align-items: flex-start;
            }

            @media (max-width: 429px) {
                left: 0 !important;
            }
        }
        .WP-Offer-Content-Popover {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 14px;
        }

        .WP-Offer-Content-DropRates-popover {
            padding: 16px 14px;

            @media (max-width: 767.95px) {
                left: 16px !important;
            }

            .inner-content {
                ${({
                    theme: {
                        palette,
                        typography,
                        palette: { mode },
                    },
                }) => ({
                    color: mode === 'dark' ? '#B8BECC' : palette.lightMode.grey[700],
                    fontSize: typography.size.p12.fontSize,
                    lineHeight: typography.size.p12.lineHeight,
                })}
            }
        }
    }

    .custom-popper {
        border: 1px solid;
        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            backgroundColor: mode === 'dark' ? palette.grey.dark[500] : palette.common.white,
            borderColor: mode === 'dark' ? '#5a6066' : palette.common.white,

            '&:after': {
                backgroundColor: mode === 'dark' ? palette.grey.dark[500] : palette.common.white,
                borderColor: mode === 'dark' ? '#5a6066' : palette.common.white,
            },
        })};

        &[data-popper-placement^='bottom'] > .arrow {
            top: 0;
            left: 50%;
            margin-top: -8px;

            &:before {
                border-width: 1px 1px 0 0;
                transform: rotate(-45deg);
            }
        }
        &[data-popper-placement^='top'] > .arrow {
            bottom: 0;
            left: 50%;
            margin-bottom: -8px;

            &:before {
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
            }
        }
        &[data-popper-placement^='right'] > .arrow {
            left: 0;
            top: 50%;
            margin-left: -8px;

            &:before {
                border-width: 1px 1px 0 0;
                transform: rotate(-135deg);
            }
        }

        &[data-popper-placement^='left'] > .arrow {
            right: 0;
            top: 50%;
            margin-right: -8px;

            &:before {
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }
        }
    }
    .arrow {
        position: absolute;

        &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border-style: solid;

            ${({
                theme: {
                    palette,
                    palette: { mode },
                },
            }) => ({
                backgroundColor: mode === 'dark' ? palette.grey.dark[500] : palette.common.white,
                borderColor: mode === 'dark' ? '#5a6066' : palette.common.white,
            })};
        }
    }

    .WP-tooltip-description-content {
        line-height: 16px;
        color: ${({ theme }) => (theme.palette.mode === 'dark' ? '#B8BECC' : '#8A8E99')};
        font-size: 12px;
        font-weight: normal;
        margin-top: 12px;
        padding-bottom: 16px;
        .inner-div:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .WP-EventsMilestoneRewardCardContainerTitle-tooltip-text {
        pre {
            white-space: normal;
            color:${({ theme }) =>
                theme.palette.mode === 'dark' ? '#B8BECC' : theme.palette.lightMode.grey[800]};
            font-size: 12px;
            line-height: 16px;
            font-weight: 350;
        }

        p {
            margin-bottom: 16px;
            font-weight: 500;
            color:${({ theme }) =>
                theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.lightMode.grey[800]};
        }

        span {
            color: ${({ theme }) =>
                theme.palette.mode === 'dark' ? '#FCE191' : theme.palette.blue[400]};
        }
    }

    .WP-tooltip-description-content-text {
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        color: ${({ theme }) => (theme.palette.mode === 'dark' ? '#B8BECC' : '#8A8E99')};
    }

    .WP-StoreItem-formatted-text {
        span {
            white-space: nowrap;
        }
    }

    .clearfix {
        padding: 8px 0;
    }

    .text-center {
        text-align: center;
    }

    .pre-text-wrap {
        white-space: pre-wrap;
    }

    .full-width {
        width: 100%;
    }

    .full-wh {
        width: 100%;
        height: 100%;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    #ot-sdk-btn-floating {
        display: none;
    }

    .WP-OfferBanner-ItemsListWrapper {
        padding-left: 28px;
    }
`;
