import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import avatar from '../../../../assets/images/avatar.png';
import { AUTOMATION_TESTING_IDS } from '../../../../constants';
import { NOTIFICATIONS_STATES } from '../../../../constants/notifications.constants';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { globalOp, globalSel } from '../../../../store/global';
import { notificationsSel } from '../../../../store/notifications';
import { translationsSel } from '../../../../store/translation/selectors';
import LoginWithScopely from '../../../components/LoginWithScopely/LoginWithScopely';
import ToggleNotificationsSwitcher from '../../../components/MISwitchContainer/components/ToggleNotificationsSwitcher/ToggleNotificationsSwitcher';
import { Oblivion } from '../../../components/Oblivion/Oblivion';
import TextSuspend from '../../../components/TextSuspend/TextSuspend';
import { Box } from '../../../components/ui-components/Box/Box';
import { Button } from '../../../components/ui-components/Button/Button';
import { Icon } from '../../../components/ui-components/Icon/Icon';
import { Typography } from '../../../components/ui-components/Typography/Typography';
import { UserWrapperStyled } from './UserWrapper.styled';
// TODO change styles (move to <Box> properties)
function UserWrapper({ setOpenRightSidebar, isLoggedIn }) {
    const theme = useSelector(globalSel.themeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);
    const notificationsState = useSelector(notificationsSel.notificationsStateSelector);

    const t = useTranslation();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isDark = theme.palette.mode === 'dark';
    const colors = {
        closeIcon: isDark ? '#686C74' : theme.palette.lightMode.grey[700],
        sidebarTitle: isDark ? theme.palette.grey.light[300] : theme.palette.lightMode.grey[900],
        userName: isDark ? theme.palette.grey.light[300] : theme.palette.lightMode.grey[700],
    };

    const handleLogout = () => {
        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'sign_out',
            screen_category: 'authorization',
            interaction_object: 'sign_out_button',
        });

        dispatch(globalOp.logout());

        if (lgDown) {
            setOpenRightSidebar(false);
        }

        if (pathname === '/store') {
            navigate('/');
        }
    };

    const handleCloseSidebar = () => {
        setOpenRightSidebar(false);
    };

    return (
        <UserWrapperStyled>
            <Box className="heading" display="flex" alignItems="center" py={5} px={4}>
                <Box
                    component={Icon}
                    size={13}
                    className="icon-close cursor-pointer"
                    mr={2}
                    color={colors.closeIcon}
                    onClick={handleCloseSidebar}
                    id={AUTOMATION_TESTING_IDS.rightSideBarCloseButton}
                />

                <Typography variant="p14" color={colors.sidebarTitle}>
                    <span id={AUTOMATION_TESTING_IDS.rightSideBarUserAuthorizationType}>
                        {currentUser
                            ? t('common_header_profile_text', 'Profile')
                            : t('common_header_log_in_text', 'Login')}
                    </span>
                </Typography>
            </Box>

            <Box px={4}>
                {isLoggedIn ? (
                    <>
                        <Box mt={9} mb={8}>
                            <Oblivion imgUrl={avatar}>
                                <Box position="relative" zIndex={1}>
                                    <Typography
                                        variant="p10"
                                        color={colors.userName}
                                        className="info-text"
                                    >
                                        <span id={AUTOMATION_TESTING_IDS.userName}>
                                            {currentUser?.name}
                                        </span>
                                    </Typography>
                                    {/* <Typography className="info-text" variant="p10" color="#E2E6EF">
                                        {currentUser?.level}
                                    </Typography> */}
                                </Box>
                            </Oblivion>
                        </Box>
                        {/* <Box mb={8}>
                            <Oblivion imgUrl={webCoin}>
                                <Box position="relative" zIndex={1}>
                                    <Typography className="info-text" variant="p10" color={theme.palette.grey.light[300]}>
                                        {currentUser?.coinTitle}
                                    </Typography>
                                    <Typography className="info-text" variant="p10" color="#E2E6EF">
                                        {currentUser?.coinCount}
                                    </Typography>
                                </Box>
                            </Oblivion>
                        </Box> */}

                        <Button
                            size="medium"
                            color="primary"
                            onClick={handleLogout}
                            className="log-out-button"
                            id={AUTOMATION_TESTING_IDS.logOutButton}
                        >
                            <TextSuspend loading={translationsLoading}>
                                {t('common_header_log_out_button_text', 'LOG OUT')}
                            </TextSuspend>
                        </Button>
                        {notificationsState !== NOTIFICATIONS_STATES.UNAVAILABLE && (
                            <ToggleNotificationsSwitcher />
                        )}
                    </>
                ) : (
                    <Box mt={5}>
                        <LoginWithScopely />
                    </Box>
                )}
            </Box>
        </UserWrapperStyled>
    );
}

UserWrapper.propTypes = {
    openRightSidebar: PropTypes.bool,
    setOpenRightSidebar: PropTypes.func,
    isLoggedIn: PropTypes.bool,
};

export default UserWrapper;
