import styled from 'styled-components';

export const defaultStyled = styled.h1``;

export const TypographyStyled = styled(defaultStyled)`
    color: ${({ color }) => color};

    ${({ theme }) => {
        return Object.entries(theme.typography.size)
            .map((item) => {
                return `&.${item[0]} {
                  font-size: ${item[1].fontSize};
                  line-height: ${item[1].lineHeight};
            }`;
            })
            .join(';\n');
    }}

    ${({ theme }) => {
        return Object.entries(theme.typography.weight)
            .map((item) => {
                return `&.${item[0]} {
                  font-weight: ${item[1]};
            }`;
            })
            .join(';\n');
    }}

    ${({ theme }) => {
        return Object.entries(theme.typography.font)
            .map((item) => {
                return `&.${item[0]} {
                  font-family: ${item[1]};
            }`;
            })
            .join(';\n');
    }}

    &.uppercase {
        text-transform: uppercase;
    }
`;
