import { type IStoreState } from 'src/@types/store/global';

const isLoadingSelector = (state: IStoreState) => state.offer.isOfferLoading;
const isTransitioningSelector = (state: IStoreState) => state.offer.isStoreGroupsTransitioning;
const isWebGiftsLoadingSelector = (state: IStoreState) => state.offer.isWebGiftsLoading;

const offerItemsSelector = (state: IStoreState) => state.offer.offerItems;
const secondaryOfferItemsSelector = (state: IStoreState) => state.offer.secondaryOfferItems;
const localizedItemsSelector = (state: IStoreState) => state.offer.localizedItems;
const webGiftsSelector = (state: IStoreState) => state.offer.webGifts;

const offerBannerSelector = (state: IStoreState) => state.offer.offerBanner;
const offerBannerQuantitySelector = (state: IStoreState) => state.offer.offerBannerQuantity;

const paymentStatusSelector = (state: IStoreState) => state.offer.paymentStatus;
const offerPurchasingSelector = (state: IStoreState) => state.offer.isOfferPurchasing;
const processingBundleSelector = (state: IStoreState) => state.offer.processingBundle;
const boughtBundleIdSelector = (state: IStoreState) => state.offer.boughtBundleId;

const contentItemDataSelector = (state: IStoreState) => state.offer.contentItemData;
const contentItemLoadingSelector = (state: IStoreState) => state.offer.contentItemLoading;

const claimedItemsModalPreview = (state: IStoreState) => state.offer.webGiftsSection.claimedItems;
const offerPreviewModal = (state: IStoreState) => state.offer.offerSection.modals.offerPreview;
const paymentStatusModal = (state: IStoreState) => state.offer.offerSection.modals.paymentStatus;

const claimableWebGiftsCount = (state: IStoreState) => state.offer.claimableWebGiftsCount;

const isStoreGroupsLoading = (state: IStoreState) => state.offer.isStoreGroupsLoading;

const storeGroupSelector = (state: IStoreState) => state.offer.storeGroups;

const selectedStoreGroupSelector = (state: IStoreState) => state.offer.selectedStoreGroup;

const selectedBucketSelector = (state: IStoreState) => state.offer.selectedBucket;
const lastDataFetchTimestampSelector = (state: IStoreState) => state.offer.lastDataFetchTimestamp;

export const offerSel = {
    isLoadingSelector,
    offerItemsSelector,
    secondaryOfferItemsSelector,
    offerBannerSelector,
    paymentStatusSelector,
    contentItemDataSelector,
    contentItemLoadingSelector,
    offerPurchasingSelector,
    localizedItemsSelector,
    processingBundleSelector,
    webGiftsSelector,
    isWebGiftsLoadingSelector,
    offerBannerQuantitySelector,
    boughtBundleIdSelector,
    claimableWebGiftsCount,
    claimedItemsModalPreview,
    offerPreviewModal,
    paymentStatusModal,
    isStoreGroupsLoading,
    storeGroupSelector,
    selectedStoreGroupSelector,
    selectedBucketSelector,
    isTransitioningSelector,
    lastDataFetchTimestampSelector,
};
