import React from 'react';
import clsx from 'clsx';

import { LabelStyled } from './Label.styled';

type LabelProps = {
    size?: 'medium' | 'small';
    variant?: 'contained' | 'outlined';
    color?: 'green' | 'blue' | 'red' | 'yellow' | 'grey-1' | 'grey-2' | 'grey-3';
    className?: string;
    children: React.ReactNode;
    hasAction?: boolean;
    hasFullWidth?: boolean;
    onClick?: () => void;
};

export const Label: React.FC<LabelProps> = ({
    children,
    variant = 'contained',
    color = 'grey-1',
    size = 'medium',
    hasAction = true,
    className = '',
    hasFullWidth = false,
    onClick = () => {},
}) => {
    return (
        <LabelStyled
            hasAction={hasAction}
            hasFullWidth={hasFullWidth}
            color={color}
            onClick={onClick}
            className={clsx(variant, variant + '-' + color, size, className)}
        >
            {children}
        </LabelStyled>
    );
};
