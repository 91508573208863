import { useEffect } from 'react';

import { attributionHook } from '../configs/web-sdk.config';

export function useAttributionHook() {
    useEffect(() => {
        attributionHook.appOpen();
        // eslint-disable-next-line
    }, []);
}
