import styled from 'styled-components';

interface IStyledProps {
    color: string;
    size: number;
}

export const IconStyled = styled.i<IStyledProps>(({ color, size }) => ({
    color,
    fontSize: size + 'px',

    '&.with-transition': {
        transform: 'rotate(180deg)',
        transition: 'transform 0.2s ease-out',
    },
}));
