import { type IStoreState } from 'src/@types/store';

const translationsSelector = (state: IStoreState) => state.translations.translations;
const isLoadingSelector = (state: IStoreState) => state.translations.loading;
const selectedLanguageSelector = (state: IStoreState) => state.translations.selectedLanguage;

export const translationsSel = {
    isLoadingSelector,
    translationsSelector,
    selectedLanguageSelector,
};
