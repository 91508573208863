import { styled } from '@mui/material/styles';

import Galaxy from '../../../assets/images/Galaxy.png';
import Lights from '../../../assets/images/Lights.png';
import RedBg from '../../../assets/images/RedBackground.png';

export const NotFoundStyled = styled('div')(({
    theme: {
        breakpoints,
        helpers,
        palette: { mode },
    },
}) => {
    const isDark = mode === 'dark';

    return {
        '.WP-NotFound-characters-wrapper': {
            ...(isDark && {
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    height: '100%',
                    backgroundImage: `url(${Galaxy})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    mixBlendMode: 'screen',
                },
            }),
        },

        '.WP-NotFound-characters': {
            '&:before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 1,

                backgroundImage: `url(${RedBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',

                mixBlendMode: 'lighten',

                width: '100%',
                height: '30%',

                [breakpoints.up('sm')]: {
                    height: '25%',
                },
                [breakpoints.up('md')]: {
                    height: '30%',
                    marginLeft: 10,
                },
                [breakpoints.up('lg')]: {
                    marginLeft: 12,
                },
            },
        },

        '.WP-NotFound-title': {
            fontSize: 140,
            lineHeight: 1,
            fontWeight: 'bold',

            position: 'relative',
            display: 'inline-block',

            ...(isDark
                ? helpers.textGradient('180deg, #F2F6F7 0%, #C7CFD0 100%', '#C7CFD0')
                : helpers.textGradient('180deg, #464646 0%, #000 100%', '#000')),

            [breakpoints.up('sm')]: {
                fontSize: 210,
            },
            [breakpoints.up('md')]: {
                fontSize: 220,
            },
            [breakpoints.up('lg')]: {
                fontSize: 250,
            },
            [breakpoints.up('xl')]: {
                fontSize: 300,
            },

            '&:before': {
                width: '100%',
                height: 280,

                position: 'absolute',
                bottom: '-10%',
                left: 15,

                content: '""',
                zIndex: 0,

                backgroundImage: `url(${Lights})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',

                display: 'inline-block',
                mixBlendMode: 'lighten',

                [breakpoints.up('sm')]: {
                    left: '20%',
                    bottom: '25%',
                },
            },
        },
    };
});
