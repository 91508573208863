import React from 'react';
import clsx from 'clsx';

import { Box } from '../Box/Box';
import { ProgressBarStyled } from './ProgressBar.styled';

type ProgressBarProps = {
    variant: string;
    height: number;
    bgColor: string;
    fillerColor: string;
    progress: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
    variant = 'dashed',
    height,
    bgColor,
    fillerColor = '',
    progress = 0,
}) => {
    return (
        <ProgressBarStyled progress={progress} height={height} color={fillerColor}>
            <Box bgcolor={bgColor} borderRadius="10px">
                <Box position="relative" className={clsx('WP-ProgressBar-filler', variant)} />
            </Box>
        </ProgressBarStyled>
    );
};
