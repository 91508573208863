/* eslint-disable no-undef */
window.cookieLess = {};

const setCookieLess = (key: string, val: string) => {
    window.cookieLess[key] = val;
};

const getCookieLess = (key: string) => {
    return window.cookieLess[key];
};

const removeCookieLess = (key: string) => {
    delete window.cookieLess[key];
};

export { setCookieLess, getCookieLess, removeCookieLess };
