export const toggleElement = (show: boolean = false) => ({
    opacity: show ? 1 : 0,
    visibility: show ? 'visible' : 'hidden',
    pointerEvents: show ? 'all' : 'none',
});

export const textGradient = (gradientColors: string, fallbackColor: string = 'white') => ({
    '@supports not (-webkit-touch-callout: none)': {
        background: `linear-gradient(${gradientColors})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },

    '@supports (-webkit-touch-callout: none)': {
        color: fallbackColor,
    },
});

export const textCutX = () => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
});

export const textCutY = (lineCount: number) => ({
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': String(lineCount),
    '-webkit-box-orient': 'vertical',
});
