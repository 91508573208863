import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import * as Constants from '../constants';
import preloadedState from './preloadedState';
import { reducers } from './reducers';

// import { createLogger } from 'redux-logger';

export let store;
// export const getStore = () => store;

export function Store() {
    // if (Constants.IS_DEVELOPMENT) {
    //     const logger = createLogger({
    //         collapsed: true,
    //     });
    //     middleware.push(logger);
    // }

    store = configureStore({
        reducer: reducers(),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        devTools: Constants.IS_DEVELOPMENT,
        preloadedState,
    });

    return store;
}

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
