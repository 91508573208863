import { styled } from '@mui/material/styles';
import { SNACKBAR_DURATION } from 'src/constants';

export const SnackBarStyled = styled('div')(({ theme: { breakpoints } }) => ({
    right: -350,
    opacity: 1,
    animation: `mobile-fade ${SNACKBAR_DURATION}ms linear`,

    [breakpoints.up('sm')]: {
        animation: `fade ${SNACKBAR_DURATION}ms linear`,
    },

    '&.WP-Snackbar-without-timeout': {
        animation: 'mobile-fade-without-animation 1s linear',

        animationFillMode: 'forwards',

        [breakpoints.up('sm')]: {
            animation: 'fade-without-animation 1s linear',
            animationFillMode: 'forwards',
        },
    },

    '@keyframes fade-without-animation': {
        '0%': {
            right: -350,
        },
        '100%': {
            opacity: 1,
            right: 45,
        },
    },

    '@keyframes mobile-fade-without-animation': {
        '0%': {
            right: -350,
        },
        '100%': {
            opacity: 1,
            right: 5,
        },
    },

    '@keyframes fade': {
        '0%, 100%': {
            opacity: 0,
            right: -310,
        },
        '15%, 50%, 85%': {
            opacity: 1,
            right: 45,
        },
    },

    '@keyframes mobile-fade': {
        '0%, 100%': {
            opacity: 0,
            right: -310,
        },
        '15%, 50%, 85%': {
            opacity: 1,
            right: 5,
        },
    },
}));
