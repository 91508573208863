export const SESSION_ID = 'sessionID';
export const LOGIN_PROCESSING_TYPE = 'loginProcessing';
export const ACCESS_TOKEN = 'accessToken';
export const FB_APP_ID = '1540256866215956';

export const API_URL = `${process.env.REACT_APP_API_URL}/api/v2`;

export const CDN_URI = `${process.env.REACT_APP_CDN_URI}`;

export const EVENTS_CDN_URI = `${process.env.REACT_APP_EVENTS_CDN_URI}`;

export const STATIC_CDN_URI = `${process.env.REACT_APP_CDN_STATIC_URI}`;

export const TRANSLATIONS_URI = `${process.env.REACT_APP_TRANSLATIONS_URI}/${process.env.REACT_APP_TRANSLATIONS_VIEW_ID}`;

export const TRANSLATIONS_API_KEY = `${process.env.REACT_APP_TRANSLATIONS_API_KEY}`;
export const SNACKBAR_DURATION = 7000;

export const SCOPELY_LOGIN_ENDPOINT = process.env.REACT_APP_SCOPELY_LOGIN_ENDPOINT;

export const SCOPELY_LOGIN_CLIENT_ID = process.env.REACT_APP_SCOPELY_LOGIN_CLIENT_ID;

export const APP_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

export const API_ENV = process.env.REACT_APP_ENVIRONMENT;

export const TITAN_API_KEY = process.env.REACT_APP_TITAN_API_KEY;

export const TITAN_EVENT_SOURCE = process.env.REACT_APP_TITAN_EVENT_SOURCE;

export const XSOLLA_URL = process.env.REACT_APP_XSOLLA_URL;

export const VAPID_PUB_KEY = process.env.REACT_APP_VAPID_PUB_KEY;

export const REACT_APP_S3 = `${process.env.REACT_APP_S3}/`;

export const REACT_APP_CMS_API_URL = `${process.env.REACT_APP_CMS_API_BASE_URL}/${process.env.REACT_APP_CMS_API_ENVIRONMENT}`;

export const OFFER_ITEMS_TYPES_MAPPING = {
    resource: 'resource/icon_',
    worker: 'worker/icon_',
    component: 'component/icon_',
    blueprint: 'blueprint/icon_',
    officer_shard: 'Character/',
    consumable: 'consumable/icon_',
    forbidden_tech_shard: 'forbiddentech/token_',
    cosmetic: {
        avatar: 'avatars/avatar_texture_',
        frame: 'avatars/frame_texture_',
    },
};

export const ERROR_HANDLER_MODAL = 'errorHandlerModal';
export const USER_LOGGED_IN = 'userLoggedIn';
