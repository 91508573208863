import axios from 'axios';
import { type TFetch, type TFetchConfig, type TPendingRequests } from 'src/@types/services';
import { checkForMaintenance, responseInterceptorOnError } from 'src/helpers/axiosHelpers';
import { getUserSelectedLanguage } from 'src/helpers/webEnvHelpers';

import * as Bridge from '../bridge';
import * as Constants from '../constants';
import { DebounceCallback } from './../helpers/utils';

let _pendingRequests: TPendingRequests = [];

axios.interceptors.response.use((response) => response, responseInterceptorOnError);
axios.interceptors.request.use(
    // @ts-ignore
    (config) => ({
        ...config,
        headers: {
            ...config.headers,
            'Content-Language': getUserSelectedLanguage(),
        },
    }),
    responseInterceptorOnError,
);

const debounce = DebounceCallback();

const fetch: TFetch = ({
    url = Constants.API_URL,
    endPoint = '/',
    body = {},
    method = 'GET',
    headers = {},
    privateRequest = false,
}) => {
    const curToken = axios.CancelToken.source();

    if (endPoint !== '/user/info') {
        _pendingRequests.push(curToken);
    }

    let config: TFetchConfig = {
        // withCredentials: true,
        method,
        url: `${url}${endPoint}`,
        cancelToken: curToken.token,
        data: body,
        headers: {},
    };

    config.headers = {
        ...Constants.DEFAULT_HEADERS,
        ...headers,
    };

    if (privateRequest) {
        const sessionToken = Bridge.getSessionToken();
        config.headers.Authorization = `Bearer ${sessionToken || ''}`;

        if (sessionToken) {
            debounce(checkForMaintenance, 1000);
        }
    }

    if (method === 'GET') {
        config.params = body;
    }

    return axios(config);
};

const abortPendingRequest = () => {
    if (_pendingRequests.length) {
        _pendingRequests.map((req) => req.cancel('HTTP Request aborted'));
        _pendingRequests = [];
        return true;
    }
    return false;
};

export { fetch, abortPendingRequest };
