import styled from 'styled-components';

export const UserWrapperStyled = styled.div`
    .heading {
        border-bottom: 1px solid;
        border-color: ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => (mode === 'dark' ? ` ${palette.white.transparent20}` : palette.grey.light[100])};
    }

    .log-out-button {
        width: 100%;
    }

    .WP-LoginWithScopely-button {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
    }
`;
