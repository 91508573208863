export const AUTOMATION_TESTING_IDS = {
    logInButton: 'log-in-button',
    logOutButton: 'log-out-button',
    userName: 'user-name',
    logoButton: 'logo-button',
    rightSideBarUserAuthorizationType: 'right-sidebar-authorization-type',
    rightSideBarButton: 'right-sidebar-button',
    leftSideBarButton: 'left-sidebar-button',
    rightSideBarCloseButton: 'right-sidebar-close-button',
    leftSideBarCloseButton: 'left-sidebar-close-button',
    navigationItem: 'navigation-item-to-',
    playOnPcButton: 'play-on-pc-button',
    iOSMarketLink: 'ios-market-link',
    androidMarketLink: 'android-market-link',
    termsOfUsePageLink: 'terms-of-use-page-link',
    privacyPolicyPageLink: 'privacy-policy-page-link',
    fbPageLink: 'fb-page-link',
    youtubePageLink: 'youtube-page-link',
    twitterPageLink: 'twitter-page-link',
    instagramPageLink: 'instagram-page-link',
    discordPageLink: 'discord-page-link',
    copyrightTextTitle: 'copyright-title-text',
    copyrightTextText: 'copyright-text-text',
    modalContent: 'modal-content',
    modalCloseButton: 'modal-close-button',
    notificationContent: 'notification-content',
    notificationCloseButton: 'notification-close-icon',
    showMoreNewsButton: 'show-more-news-button',
    specialOfferButton: 'special-offer-button',
    specialOffersCarouselPrevButton: 'special-offers-carousel-prev-button',
    specialOffersCarouselNextButton: 'special-offers-carousel-next-button',
    seeAllNewsLink: 'see-all-news-link',
    seeAllArticlesLink: 'see-all-articles-link',
    blogBannerReadMoreButton: 'blog-banner-read-more-button',
    newsCategoryButton: 'news-category-button',
    offerPriceButton: 'offer-price-button',
    offerClaimButton: 'offer-claim-button',
    offerContentOpenButton: 'offer-content-open-button',
    offerContentCloseButton: 'offer-content-close-button',
    offerDetailsListItemImage: 'offer-details-list-item-image',
    offerBannerLeftCount: 'offer-banner-left-count',
    offerBannerBasket: 'offer-banner-basket',
    offerBannerPurchaseButton: 'offer-banner-purchase-button',
    storeFeaturedTabButton: 'store-featured-tab-button',
    storeWebGiftTabButton: 'store-web-gift-tab-button',
    offerLeftIcon: 'offer-left-icon',
    featureOfferItemDiv: 'feature-offer-item-div',
    webGiftItemDiv: 'web-gift-item-div',
    dropRate: 'drop-rate',
    featureOffersDiv: 'feature-offers-div',
    webGiftsDiv: 'web-gifts-div',
    mainOfferDiv: 'main-offer-div',
    homeLatestNewsDiv: 'home-latest-news-div',
    newsReadMoreButton: 'news-read-more-button',
    storeExpandOffers: 'store-expand-offers',
    storeCollapseOffers: 'store-collapse-offers',
    inGameNews: 'in-game-news',
    loadMoreFeaturesOffers: 'load-more-features',
    xsollaIFrame: 'xsolla-lightbox-iframe',
    enabledDarkTheme: 'enable-dark-theme',
    enabledLightTheme: 'enable-light-theme',
    newsCard: 'news-card',
    newsBanner: 'news-banner',
    offerQuantity: 'offer-quantity',
    languageSelect: 'language-select',
    languageSelectOption: 'language-select-option',
    eventsBannerBadge: 'events-banner-badge',
    eventsBannerScorePointsNumber: 'events-banner-score-points-number',
    eventsBannerTimeLeftNumber: 'events-banner-time-left-number',
    eventsBannerStartInNumber: 'events-banner-start-in-number',
    eventsBannerProgressBarScoreNumber: 'events-banner-progress-bar-score-number',
    eventsRewardPointsTitle: 'events-reward-points-title',
    eventsRewardClaimButton: 'events-reward-claim-button',
    eventsRewardInfoButton: 'events-reward-info-button',
    eventsNavTimer: 'events-nav-timer',
    eventsRewardContainer: 'events-rewards-container',
    redeemCodeInput: 'redeem-code-input',
    redeemCodeButton: 'redeem-code-button',
    redeemCodeHelperText: 'redeem-code-helper-text',
    manageCookieSettings: 'manage-cookie-settings-button',
};
