import styled from 'styled-components';

export const LanguageDropdownStyled = styled.div`
    .WP-LanguageDropdown-arrow {
        width: 10px;
        height: 6px;
        justify-self: right;
        transition: transform 350ms ease-out;
    }

    .rotate {
        transform: rotate(180deg);
    }

    .lang-menu {
        position: relative;
        border: 1px solid;
        z-index: 100;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;

        ${({ theme: { helpers } }) => helpers.toggleScrollBar({ enable: false })}

        ${({
            theme: {
                palette: { mode },
            },
        }) => ({
            borderColor: mode === 'dark' ? 'transparent' : '#EBEDF0',
            backgroundColor: mode === 'dark' ? '#2d2f33' : '#fff',
        })}
    }

    .hidden {
        display: none;
    }

    .check-img {
        margin-left: auto;
        margin-right: 12px;
    }

    .mobile-upper-section {
        border-bottom: 1px solid #383b40;
    }

    .WP-LanguageDropDown-select-flag {
        width: auto;
    }
`;

export const LanguageItemDropdownStyled = styled.div`
    cursor: pointer;

    ${({ theme }) => theme.palette.mode === 'light'} {
        background-color: ${({ selected }) => (selected ? '#43464d' : 'transparent')};
    }

    ${({ theme }) => theme.palette.mode === 'dark'} {
        background-color: ${({ selected }) => (selected ? '#F0F2F5' : 'transparent')};
    }

    .WP-LanguageDrop-item-flag {
        object-fit: contain;
    }
`;
