import { hexToRgba, lightenRgbColor } from 'src/helpers/styleHelpers';
import { getiOSVersion, getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';
import styled from 'styled-components';

import { getSessionStorage, setSessionStorage } from '../../../../services';

interface IButtonStyledProps {
    mainColor?: string; // Assuming mainColor is a string, adjust as necessary
    secondaryColor?: string;
    degree?: number;
}

const supportedOldiOStyles = () => {
    const mobileOperatingSystem = getMobileOperatingSystem();

    if (mobileOperatingSystem !== 'iOS') {
        return false;
    }

    const key = 'oldIOSSUpport';
    const cachedValue = getSessionStorage(key);

    if (cachedValue) {
        return true;
    }

    const [oldiOSVersionStyles] = getiOSVersion();

    if (oldiOSVersionStyles && oldiOSVersionStyles <= 12) {
        setSessionStorage(key, true);
        return true;
    }

    return false;
};

export const ButtonStyled = styled.button<IButtonStyledProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme: { palette, typography, helpers } }) => {
        let styles: {
            fontWeight: number;
            fontFamily: string;
            transition: string;
            color: string;
            [index: string]:
                | string
                | number
                | {
                      width: string;
                      height: string;
                  };
        } = {
            fontWeight: typography.weight.bold,
            fontFamily: typography.font.univiaPro,
            transition: helpers.transitions('border-color', 'background-color'),
            color: palette.common.white,
        };

        // TODO think about better solution
        if (supportedOldiOStyles()) {
            styles = {
                ...styles,
                '&:before': {
                    width: 'calc(100% - 10px)',
                    height: 'calc(100% - 10px)',
                },
            };
        }

        return styles;
    }};

    &:before {
        content: '';
        position: absolute;
        transition: ${({ theme }) => theme.helpers.transitions('background-color')};
        inset: 4px;
    }

    &.large {
        padding: 12px 44px;
        font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p16.lineHeight};
    }

    &.medium {
        padding: 16px 28px;
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p14.lineHeight};
    }

    &.small {
        padding: 8px 16px;
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p12.lineHeight};
        font-weight: ${({ theme }) => theme.typography.weight.semiBold};

        .button-icon {
            font-size: 16px;
            margin-right: ${({ theme }) => theme.spacing(1)}px;
        }
    }

    &.primary {
        border: 1px solid ${({ theme }) => theme.palette.yellow[700]};
        background-color: transparent;

        &:before {
            border: 1px solid;
            background-image: ${({ theme }) =>
                `linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)`};
            border-image: ${({ theme }) =>
                `linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%) 1`};
        }

        &:hover {
            border-color: #fed65d;

            &:before {
                background-image: linear-gradient(180deg, #ffd760 0%, #b7900e 100%);
            }
        }

        &:focus {
            border-color: #ffe8a1;

            &:before {
                border-color: rgba(255, 255, 255, 0.5);
                background-image: ${({ theme }) =>
                    `linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)`};
            }
        }

        &:active {
            border-color: #fedd78;

            &:before {
                background-image: linear-gradient(180deg, #ffde7a 0%, #c99c07 100%);
            }
        }

        &:disabled {
            border-color: ${({ theme }) => theme.palette.grey.dark[300]};
            color: ${({ theme }) => theme.palette.grey.light[700]};

            &:before {
                background: ${({ theme }) => theme.palette.grey.dark[400]};
            }
        }
    }

    &.secondary {
        border: 1px solid ${({ theme }) => theme.palette.green[600]};
        background-color: transparent;

        &:before {
            background-image: ${({
                theme,
            }) => `linear-gradient(180deg, #5cc75c 0%, ${theme.palette.green[800]} 100%),
                linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)`};
        }

        &:hover {
            border-color: ${({ theme }) => theme.palette.green[600]};

            &:before {
                background-image: linear-gradient(180deg, #80e180 0%, #298129 100%);
            }
        }

        &:focus {
            border-color: #9ef19e;

            &:before {
                background-image: ${({
                    theme,
                }) => `linear-gradient(180deg, #5cc75c 0%, ${theme.palette.green[800]} 100%),
                    linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)`};
            }
        }

        &:active {
            border-color: ${({ theme }) => theme.palette.green[600]};

            &:before {
                background-image: linear-gradient(180deg, #8cf28c 0%, #329732 100%);
            }
        }

        &:disabled {
            border-color: ${({ theme }) => theme.palette.grey.dark[300]};
            color: ${({ theme }) => theme.palette.grey.light[700]};

            &:before {
                background: ${({ theme }) => theme.palette.grey.dark[400]};
            }
        }
    }

    &.teriatry {
        border: 1px solid ${hexToRgba('#fff', 0.3)};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.grey.dark[800]};

        &:before {
            background-image: linear-gradient(180deg, #f0f3f8 0%, #b1b9cc 100%);
        }

        &:hover {
            &:before {
                background-image: linear-gradient(180deg, #fbfcff 0%, #c5cad7 100%);
            }
        }

        &:focus {
            border-color: ${hexToRgba('#fff', 0.5)};

            &:before {
                background: linear-gradient(180deg, #f0f3f8 0%, #b1b9cc 100%);
            }
        }

        &:active {
            border-color: ${hexToRgba('#fff', 0.5)};

            &:before {
                background-image: linear-gradient(180deg, #fbfcff 0%, #d6dce9 100%);
            }
        }

        &:disabled {
            border-color: ${hexToRgba('#fff', 0.3)};
            opacity: 0.5;

            &:before {
                background-image: linear-gradient(180deg, #f0f3f8 0%, #b1b9cc 100%);
            }
        }
    }

    &.solid {
        border: 1px solid
            ${({ theme }) =>
                theme.palette.mode === 'dark' ? theme.palette.white.transparent30 : '#5D626D'};
        background-color: transparent;

        &:before {
            background-image: linear-gradient(180deg, #f0f3f8 0%, #b1b9cc 100%);
        }

        &:disabled {
            &:before {
                background-image: ${({ theme }) => `
                    linear-gradient(180deg, #8c919d 0%, #5d626d 100%),
                    linear-gradient(180deg, #5cc75c 0%, ${theme.palette.green[800]} 100%),
                    linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)
                `};
            }
        }
    }

    &.grey-1 {
        border: 1px solid;
        background-color: transparent;
        border-color: transparent;
        transition: ${({ theme }) => theme.helpers.transitions('border-color')};
        color: ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => (mode === 'dark' ? palette.grey.light[700] : palette.lightMode.grey[600])};

        &:before {
            transition: ${({ theme }) => theme.helpers.transitions('background-color')};
            border: 1px solid;

            ${({
                theme: {
                    palette,
                    palette: { mode },
                },
            }) => ({
                borderColor: mode === 'dark' ? palette.white.transparent10 : 'transparent',
                backgroundColor: mode === 'dark' ? palette.grey.dark[600] : palette.common.white,
            })};
        }

        &:hover {
            ${({
                theme,
                theme: {
                    palette: { mode },
                },
            }) => ({
                color:
                    mode === 'dark'
                        ? theme.palette.grey.light[400]
                        : theme.palette.lightMode.grey[600],

                boxShadow: mode === 'dark' ? 'none' : '0px 7px 12px rgba(19, 25, 39, 0.05)',

                '&:before': {
                    borderColor:
                        mode === 'dark' ? theme.palette.white.transparent20 : 'transparent',
                    background:
                        mode === 'dark' ? theme.palette.grey.dark[400] : theme.palette.common.white,
                },
            })};
        }

        &.active {
            ${({
                theme: {
                    palette,
                    palette: { mode },
                },
            }) => ({
                color: mode === 'dark' ? palette.grey.light[400] : palette.lightMode.grey[900],
                borderColor: mode === 'dark' ? palette.white.transparent30 : '#E4E6EB',

                '&:before': {
                    background: mode === 'dark' ? palette.grey.dark[400] : palette.common.white,
                    borderColor:
                        mode === 'dark' ? palette.white.transparent20 : palette.common.white,
                },
            })};

            &:before {
            }
        }

        &:disabled {
            border-color: transparent;
            color: ${({ theme }) => theme.palette.grey.dark[100]};

            &:before {
                background: ${({ theme }) => theme.palette.grey.dark[600]};
                border-color: ${({ theme }) => theme.palette.white.transparent10};
            }
        }
    }

    &.grey-2 {
        border: 1px solid;
        background-color: transparent;

        &:before {
            border: 1px solid transparent;
        }

        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            color: mode === 'dark' ? '#CDD1DA' : palette.lightMode.grey[700],
            borderColor: mode === 'dark' ? palette.white.transparent20 : '#DFE1E6',
            backgroundColor:
                mode === 'dark' ? hexToRgba(palette.white.transparent20) : 'transparent',

            '&:before': {
                borderImage:
                    mode === 'dark'
                        ? `linear-gradient(
                                180deg,
                                ${palette.white.transparent20} 0%,
                                transparent 100%
                            ) 1
                        `
                        : 'none',
                backgroundColor: mode === 'dark' ? '#45474d' : '#fff',
            },
        })};
    }

    &.grey-3 {
        border: 1px solid ${({ theme }) => theme.palette.white.transparent20};
        background-color: transparent;

        &:before {
            border: 1px solid transparent;
            border-image: ${({ theme }) => `linear-gradient(
                    180deg,
                    ${theme.palette.white.transparent20} 0%,
                    transparent 100%
                ) 1
            `};
            background-color: #45474d;
        }
    }

    /*
        Grey-4 for events button case
    */
    &.grey-4 {
        ${({ theme }) => {
            const isDark = theme.palette.mode === 'dark';

            return {
                border: '1px solid',
                borderImage: isDark
                    ? 'linear-gradient(180deg, #6A6E77 0%, #34383F 100%) 1'
                    : 'linear-gradient(180deg, #8c919d 0%, #5d626d 100%) 1',
                background: isDark
                    ? 'linear-gradient(180deg, #6A6E77 0%, #34383F 100%)'
                    : 'linear-gradient(180deg, #8c919d 0%, #5d626d 100%)',
                color: isDark
                    ? hexToRgba(theme.palette.common.white, 0.25)
                    : hexToRgba(theme.palette.common.white, 0.5),
            };
        }};
    }

    &.yellow-without-border {
        padding: 13px 34px;
        border: 0;
        background-image: linear-gradient(180deg, #ffc926 0%, #ba8f0a 100%);

        ${({ theme }) => theme.breakpoints.up!('sm')} {
            padding: 13px 39px;
        }

        &:before {
            background-image: ${({ theme }) =>
                `linear-gradient(180deg, ${theme.palette.yellow[700]} 0%, #ba8f0a 100%)`};
        }

        &:hover {
            &:before {
                background-image: linear-gradient(180deg, #ffd760 0%, #b7900e 100%);
            }
        }

        &:active {
            border-color: #fedd78;

            &:before {
                background-image: linear-gradient(180deg, #ffde7a 0%, #c99c07 100%);
            }
        }

        &:disabled {
            border-color: ${({ theme }) => theme.palette.grey.dark[300]};
            color: ${({ theme }) => theme.palette.grey.light[700]};

            &:before {
                background: ${({ theme }) => theme.palette.grey.dark[400]};
            }
        }
    }

    &.custom {
        border: 1px solid transparent;

        &:before {
            border: 1px solid transparent;
        }
    }

    &:disabled {
        cursor: default;
    }

    .button-text {
        position: relative;
        color: inherit;
        z-index: 1;
    }

    .button-icon {
        margin-right: ${({ theme }) => theme.spacing(2)};
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    &.loading {
        pointer-events: none;
        cursor: default;

        .button-icon {
            animation: spinner 1s infinite linear;
        }
    }

    @keyframes spinner {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &.special {
        border: 1px solid ${({ mainColor }) => mainColor};
        background-color: transparent;

        &:before {
            background-image: ${({ mainColor, secondaryColor, degree }) =>
                `linear-gradient(${degree}deg, ${mainColor} 0%, ${secondaryColor} 100%)`};
            border-image: ${({ mainColor, secondaryColor, degree }) =>
                `linear-gradient(${degree}deg, ${mainColor} 0%, ${secondaryColor} 100%)`};
        }

        &:hover {
            border-color: ${({ mainColor }) => mainColor};

            &:before {
                background-image: ${({ mainColor, secondaryColor, degree }) =>
                    `linear-gradient(${degree}deg, ${
                        mainColor ? lightenRgbColor(mainColor, 7.5) : mainColor
                    } 0%, ${
                        secondaryColor ? lightenRgbColor(secondaryColor, 7.5) : secondaryColor
                    } 100%)`};
            }
        }
        &:focus {
            border-color: ${({ mainColor }) => mainColor};

            &:before {
                border-color: rgba(255, 255, 255, 0.5);
                background-image: ${({ mainColor }) =>
                    `linear-gradient(180deg, ${mainColor} 0%, #ba8f0a 100%)`};
            }
        }

        &:active {
            border-color: ${({ mainColor }) => mainColor};

            &:before {
                background-image: ${({ mainColor, secondaryColor, degree }) =>
                    `linear-gradient(${degree}deg, ${mainColor} 0%, ${secondaryColor} 100%)`};
            }
            }
        }

        &:disabled {
            border-color: ${({ theme }) => theme.palette.grey.dark[300]};
            color: ${({ theme }) => theme.palette.grey.light[700]};

            &:before {
                background: ${({ theme }) => theme.palette.grey.dark[400]};
            }
        }
    }
`;
