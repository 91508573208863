import { hexToRgba, textCutY } from 'src/helpers/styleHelpers';
import styled from 'styled-components';

// eslint-disable-next-line no-undef
type TPositioningTooltip = (...args: string[]) => string;
// eslint-disable-next-line no-undef
type TMoveTooltip = (moveBy: string, axis: boolean) => string;
// eslint-disable-next-line no-undef
type TMakeCenter = (axis: boolean) => string;

interface SimpleVariantStyledProps {
    zIndex: number;
    maxRows: number;
    tooltipWidth: string;
    tooltipBGColor: string;
    tooltipTextColor: string;
    tooltipBorderColor: string;
    tooltipBorderRadius: string;
    tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
    arrowSize: string;
    centerX: boolean;
    centerY: boolean;
    moveX: string;
    moveY: string;
}

const positioningTooltip: TPositioningTooltip = (top, bottom, right, left, position) => {
    switch (position) {
        case 'top':
            return top;
        case 'left':
            return left;
        case 'right':
            return right;
        default:
            return bottom;
    }
};

const moveTooltip: TMoveTooltip = (moveBy, axis) => {
    return moveBy !== '0px' ? `transform: translate${axis ? 'Y' : 'X'}(${moveBy});` : '';
};

const makeCenter: TMakeCenter = (axis) => {
    return axis
        ? `top: 50%; transform: translateY(-50%);`
        : `left: 50%; transform: translateX(-50%);`;
};

export const SimpleVariantStyled = styled.div<SimpleVariantStyledProps>`
    position: relative;
    width: 100%;
    z-index: ${({ zIndex }) => zIndex};

    p {
        text-align: center;
    }

    .WP-TruncateText-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: default;

        &:hover {
            .WP-TruncateText-tooltip-text {
                visibility: visible;
            }
        }
    }

    .WP-TruncateText-title-long {
        ${({ maxRows }) => textCutY(maxRows)}
    }

    .break {
        word-break: break-word;
    }

    .hidden {
        z-index:;
    }

    .WP-TruncateText-tooltip-text {
        position: absolute;
        visibility: hidden;
        width: ${({ tooltipWidth }) => tooltipWidth};
        background-color: ${({ tooltipBGColor }) => tooltipBGColor};
        color: ${({ tooltipTextColor }) => tooltipTextColor};
        text-align: center;
        border: 1px solid ${({ tooltipBorderColor }) => hexToRgba(tooltipBorderColor)};
        border-radius: ${({ tooltipBorderRadius }) => tooltipBorderRadius};
        z-index: ${({ zIndex }) => (zIndex ? zIndex : 99)};
        padding: 8px 8px;
        ${({ tooltipWidth }) => (tooltipWidth === 'auto' ? '' : 'word-break: break-word')};

        ${({ centerX, centerY }) => {
            if (centerX) {
                return makeCenter(false);
            }
            if (centerY) {
                return makeCenter(true);
            }
        }}

        ${({ tooltipPosition, moveX, moveY }) =>
            positioningTooltip(
                `bottom: calc(110% + ${moveY}); ${moveTooltip(moveX, false)}`,
                `top: calc(110% + ${moveY}); ${moveTooltip(moveX, false)}`,
                `left: calc(110% + ${moveX}); ${moveTooltip(moveY, true)}`,
                `right: calc(110% + ${moveX}); ${moveTooltip(moveY, true)}`,
                tooltipPosition,
            )}


        &::before {
            content: ' ';
            position: absolute;
            ${({ tooltipPosition, tooltipBorderColor }) =>
                positioningTooltip(
                    `top: 100%; left: 50%; transform: translateX(-50%); border-color: ${hexToRgba(
                        tooltipBorderColor,
                    )} transparent transparent transparent;`,
                    `bottom: 100%; left: 50%; transform: translateX(-50%);  border-color: transparent transparent ${hexToRgba(
                        tooltipBorderColor,
                    )} transparent;`,
                    `right: 100%; transform: translateY(-50%); top: 50%; border-color: transparent ${hexToRgba(
                        tooltipBorderColor,
                    )} transparent transparent;`,
                    `left: 100%; transform: translateY(-50%); top: 50%; border-color: transparent transparent transparent ${hexToRgba(
                        tooltipBorderColor,
                    )};`,
                    tooltipPosition,
                )}
            border-width: ${({ arrowSize }) => `${parseInt(arrowSize) + 1}px`};
            border-style: solid;
        }
        &::after {
            content: ' ';
            position: absolute;

            ${({ tooltipPosition, tooltipBGColor }) =>
                positioningTooltip(
                    `top: 100%; left: 50%; transform: translateX(-50%); border-color: ${tooltipBGColor} transparent transparent transparent;`,
                    `bottom: 100%; left: 50%; transform: translateX(-50%);  border-color: transparent transparent ${tooltipBGColor} transparent;`,
                    `right: 100%; transform: translateY(-50%); top: 50%; border-color: transparent ${tooltipBGColor} transparent transparent;`,
                    `left: 100%; transform: translateY(-50%); top: 50%; border-color: transparent transparent transparent ${tooltipBGColor};`,
                    tooltipPosition,
                )}

            border-width: ${({ arrowSize }) => arrowSize};
            border-style: solid;
        }
    }
`;
