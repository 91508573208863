import { createSlice } from '@reduxjs/toolkit';

import { initialEventsState } from './initialState';

export const eventsSlice = createSlice({
    name: 'events',
    initialState: initialEventsState,
    reducers: {
        setEventsLoading(state, action) {
            state.eventsLoading = action.payload;
        },
        setEvents(state, action) {
            state.events = action.payload;
        },
        setEventsBundles(state, action) {
            state.bundles = action.payload;
        },
        setEventsTranslationLoading(state, action) {
            state.translationsLoading = action.payload;
        },
        setEventsTranslations(state, action) {
            state.eventsTranslations = action.payload;
        },
        setEventsContentTranslations(state, action) {
            state.rewardContent.itemData[action.payload.bundleId] = action.payload.itemData;
        },
        setEventsContentTranslationsLoading(state, action) {
            state.rewardContent.loading = action.payload;
        },
        setEventsSidebarTimeStamp(state, action) {
            state.eventsSidebarTimeStamp = action.payload;
        },
        setIsNewEventsShown(state, action) {
            state.isNewEventsShown = action.payload;
        },
        setRewardClaimModalData(state, action) {
            state.modals.rewardClaimed = {
                ...state.modals.rewardClaimed,
                isOpen: action.payload.open,
                items: action.payload.items,
                bundlesId: action.payload.bundlesId,
            };
        },
        toggleRewardClaimModal(state, action) {
            state.modals.rewardClaimed = {
                ...state.modals.rewardClaimed,
                isOpen: action.payload.open,
            };
        },
        toggleRewardClaimModalLoading(state, action) {
            state.modals.rewardClaimed.loading = {
                ...state.modals.rewardClaimed.loading,
                [action.payload.configId]: action.payload.loading,
            };
        },
        setRewardContentItemData(state, action) {
            state.rewardContentItemData[action.payload.rewardId] = action.payload.itemData;
        },
        setRewardContentItemLoading(state, action) {
            state.rewardContentItemsLoading[action.payload] =
                !state.rewardContentItemsLoading[action.payload];
        },
        clearRewardContentItemData(state) {
            state.rewardContentItemData = {};
        },
    },
});
