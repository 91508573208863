import moment from 'moment';
//todo not used
const pad = (n: number, z = 2) => ('00' + n).slice(-z);
export function msToTime(s: number) {
    return (
        pad((s / 3.6e6) | 0) +
        ' : ' +
        pad(((s % 3.6e6) / 6e4) | 0) +
        ' : ' +
        pad(((s % 6e4) / 1000) | 0)
    );
}

export const generateProgressDateInPercents = (
    startDate: moment.MomentInput,
    endDate: moment.MomentInput,
) => {
    const dateNow = moment().valueOf();
    const sDate = moment.parseZone(startDate).valueOf();
    const eDate = moment.parseZone(endDate).valueOf();
    const fullProgress = eDate - sDate;
    const currentProgress = dateNow - sDate;

    return (currentProgress * 100) / fullProgress;
};

// TODO
export const generateProgressDate = (endDate: moment.MomentInput, short?: boolean): string => {
    const duration = moment.duration(moment.parseZone(endDate).diff(moment()));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days < 0 || hours < 0 || minutes < 0) {
        return '';
    }

    if (days && hours) {
        return `${days}${short ? 'd' : ' Days'} ${hours}${short ? 'h' : ' Hours Left'}`;
    } else if (hours && !days) {
        return `${hours}${short ? 'h' : ' Hours'} ${minutes}${short ? 'm' : ' Minutes Left'}`;
    } else if (!hours && days) {
        return `${days} ${short ? 'd' : ' Days Left'}`;
    }

    return `${minutes}${short ? 'm' : ' Minutes Left'}`;
};

export const getDuration = (endDate: moment.MomentInput) => {
    return moment.duration(moment.parseZone(endDate).diff(moment()));
};

// TODO remove, not used, also remove date-fns
export const getRemainingOfferDate = (endDate: Date) => {
    const expireDate = new Date(endDate);
    const today = new Date();
    const differenceInMilliseconds = expireDate.getTime() - today.getTime();

    const remainingDays = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
    const remainingHours = Math.floor(differenceInMilliseconds / (1000 * 3600));
    const remainingMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const remainingSeconds = Math.floor(differenceInMilliseconds / 1000);

    const remainingHoursRounded = remainingHours - remainingDays * 24;
    const remainingMinutesRounded = remainingMinutes - remainingHours * 60;
    const remainingSecondsRounded = remainingSeconds - remainingMinutes * 60;

    if (remainingDays > 0) {
        return `${remainingDays}d ${remainingHoursRounded}h`;
    }

    if (remainingHours > 0) {
        return `${remainingHoursRounded}h ${remainingMinutesRounded}m`;
    }

    if (remainingMinutes > 0) {
        return `${remainingMinutesRounded}m ${remainingSecondsRounded}s`;
    }

    if (remainingSeconds > 0) {
        return `${remainingSecondsRounded}s`;
    }

    return 0;
};

export const formatTimeStamp = (timestamp) => {
    let date = new Date(timestamp * 1000);
    return date.toLocaleString();
};

export const isTimeStampPassed = (timestamp) => {
    let currentDateInSeconds = new Date().getTime() / 1000;
    return currentDateInSeconds > timestamp ? true : false;
};
