import styled from 'styled-components';

interface IStyledProps {
    startCloseAnimation: boolean;
}

export const GlobalLoaderStyled = styled.div<IStyledProps>(
    ({ theme: { helpers }, startCloseAnimation }) => {
        let animationStyles = {};

        if (startCloseAnimation) {
            animationStyles = {
                opacity: 0,
                visibility: 'hidden',
            };
        }

        return {
            transition: helpers.transitions('opacity', 'visibility'),

            ...animationStyles,

            '.WP-GlobalLoader-circle': {
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    background: `conic-gradient(
                        from 0deg at 50% 50%,
                        rgba(194, 154, 33, 0) 0deg,
                        #c29a21 360deg
                    )`,
                    animation: 'spin 4s linear infinite',
                    borderRadius: '100px',
                    zIndex: 1,
                },
            },

            '@keyframes spin': {
                '100%': {
                    transform: 'rotate(360deg)',
                },
            },
        };
    },
);
