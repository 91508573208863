import styled from 'styled-components';

export const OblivionStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    transition: background-color 200ms ease-out;
    color: #cdd1da;

    border: 1px solid;
    border-color: ${({ theme }) =>
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#DFE1E5'};

    background-color: transparent;
    padding: 10px 16px 10px 12px;
    font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
    line-height: ${({ theme }) => theme.typography.size.p14.lineHeight};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 12px 25px 10px 16px;
    }

    &:before {
        content: '';
        position: absolute;
        border: 1px solid transparent;
        border-image-slice: 1;
        inset: 4px;

        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            borderImageSource:
                mode === 'dark'
                    ? `linear-gradient(${palette.white.transparent20} 0%, #45474d 100%)`
                    : 'none',
            backgroundColor: mode === 'dark' ? '#2e3033' : palette.common.white,
        })}
    }

    &:disabled:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
    }

    .button-text {
        z-index: 1;
    }

    .MuiCircularProgress-svg {
        color: #cdd1da;
    }
`;
