import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import { hexToRgba } from 'src/helpers/styleHelpers';

import Logo from '../../../assets/images/avatar.png';
import { useTranslation } from '../../../hooks';
import { globalSel } from '../../../store/global';
import { Box } from '../ui-components/Box/Box';
import { Typography } from '../ui-components/Typography/Typography';
import { GlobalLoaderStyled } from './GlobalLoader.styled';

interface IProps {
    close: boolean;
    preview?: boolean;
}

type TText = {
    title: {
        key: string;
        fallback: string;
    };
    subTitle: {
        key: string;
        fallback: string;
    };
};

const LOADING_TEXT_TIMEOUT = 10000;

const texts: [TText, TText, TText, TText, TText] = [
    {
        title: {
            key: 'common_screen_loading_title_1',
            fallback: 'All power to the engines!',
        },
        subTitle: {
            key: 'common_screen_loading_subtitle_1',
            fallback: 'Set a course for the Final Frontier!',
        },
    },
    {
        title: {
            key: 'common_screen_loading_title_2',
            fallback: 'Initializing...',
        },
        subTitle: {
            key: 'common_screen_loading_subtitle_2',
            fallback: 'Diverting auxiliary power to engines, Commander.',
        },
    },
    {
        title: {
            key: 'common_screen_loading_title_3',
            fallback: 'Course laid in, Commander.',
        },
        subTitle: {
            key: 'common_screen_loading_subtitle_3',
            fallback: 'Time to boldly go where no one has gone before...',
        },
    },
    {
        title: {
            key: 'common_screen_loading_title_4',
            fallback: 'Engaging engines...',
        },
        subTitle: {
            key: 'common_screen_loading_subtitle_4',
            fallback: 'Time to explore strange new worlds.',
        },
    },
    {
        title: {
            key: 'common_screen_loading_title_5',
            fallback: 'Warp Core Initialized',
        },
        subTitle: {
            key: 'common_screen_loading_subtitle_5',
            fallback: 'Setting course to the Neutral Zone...',
        },
    },
];

const getLoadingText = (order = 0) => {
    if (order < 0 || order > texts.length) {
        return texts[0];
    }

    return texts.find((_, index) => order === index) || texts[0];
};

const GlobalLoader: React.FC<IProps> = ({ close, preview }) => {
    const {
        palette,
        palette: { mode },
    } = useSelector(globalSel.themeSelector);

    const t = useTranslation();

    const currentTextOrder = useRef(0);
    const [currentText, setCurrentText] = useState(() => getLoadingText(currentTextOrder.current));

    const colors = {
        title: mode === 'dark' ? '#DDE2ED' : palette.lightMode.grey[900],
        subTitle: mode === 'dark' ? palette.grey.light[600] : '#8A8E99',
        border: mode === 'dark' ? hexToRgba(palette.common.white, 0.12) : palette.common.white,
        bgOuterColor: mode === 'dark' ? '#17181a' : '#EDEFF2',
        bgInnerColor:
            mode === 'dark' ? hexToRgba(palette.common.white, 0.08) : palette.common.white,
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            currentTextOrder.current++;
            if (currentTextOrder.current >= texts.length) {
                currentTextOrder.current = 0;
            }
            setCurrentText(getLoadingText(currentTextOrder.current));
        }, LOADING_TEXT_TIMEOUT);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    /* TODO handle call Typography like this
     Issue is about default margin: 0 style and first Box call */

    return (
        <>
            {/* @ts-ignore:next-line */}
            <Typography />
            <Box
                component={GlobalLoaderStyled}
                startCloseAnimation={close}
                position={preview ? 'relative' : 'fixed'}
                top={0}
                right={0}
                left={0}
                bottom={0}
                zIndex={99999}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {!preview && <Backdrop open />}

                <Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={[0, 10]}>
                        <Box position="relative" className="WP-GlobalLoader-circle">
                            <Box p={1}>
                                <Box
                                    p={3}
                                    position="relative"
                                    zIndex={99}
                                    borderRadius="100px"
                                    bgcolor={colors.bgOuterColor}
                                    data-testid="WP-GlobalLoader-innerDiv"
                                >
                                    <Box position="relative" borderRadius="100px">
                                        <Box
                                            borderRadius="100px"
                                            border={1}
                                            borderColor={colors.border}
                                            bgcolor={colors.bgInnerColor}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                py={5}
                                                px={7}
                                            >
                                                <Box
                                                    component="img"
                                                    src={Logo}
                                                    alt="loading"
                                                    width={40}
                                                    height={56}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={11} textAlign="center" px={2} position="relative" zIndex={99}>
                        <Typography variant="h1" color={colors.title}>
                            {t(currentText.title.key, currentText.title.fallback)}
                        </Typography>

                        <Box
                            component={Typography}
                            variant="p24"
                            color={colors.subTitle}
                            maxWidth={352}
                            mt={4}
                            mx="auto"
                        >
                            {t(currentText.subTitle.key, currentText.subTitle.fallback)}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default GlobalLoader;
