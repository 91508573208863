export const newsCard = (palette) => {
    const isDarkMode = palette.mode === 'dark';

    return {
        mainBG: isDarkMode ? palette.grey.dark[700] : palette.common.white,
        dateC: isDarkMode ? palette.grey.light[700] : palette.lightMode.grey[700],
        titleC: isDarkMode ? palette.grey.light[300] : palette.lightMode.grey[900],
        descriptionC: palette.grey.light[700],
        linkC: isDarkMode ? palette.grey.light[400] : palette.grey.light[700],
        linkHoverC: isDarkMode ? palette.grey.light[100] : palette.lightMode.grey[500],

        labelC: isDarkMode ? palette.grey.light[600] : palette.lightMode.grey[700],
        labelBg: isDarkMode ? palette.grey.dark[500] : palette.lightMode.grey[300],
        labelBorderC: isDarkMode ? palette.grey.dark[400] : palette.lightMode.grey[300],

        labelHoverC: isDarkMode ? palette.grey.light[500] : palette.lightMode.grey[900],
        labelHoverBg: isDarkMode ? palette.grey.dark[400] : palette.lightMode.grey[400],
        labelHoverBorderC: isDarkMode ? palette.grey.dark[300] : palette.lightMode.grey[700],

        labelHr: isDarkMode ? palette.grey.dark[500] : '#E9EBF0',

        skeleton: {
            bgcolor: isDarkMode ? palette.grey.dark[700] : palette.white.transparent50,
        },
    };
};
