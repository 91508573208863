import { type IEventsState } from 'src/@types/store';

export const initialEventsState: IEventsState = {
    events: [],
    eventsLoading: true,
    translationsLoading: true,
    eventsTranslations: {},
    bundles: {},
    eventsSidebarTimeStamp: {},
    isNewEventsShown: [],
    milestoneRewards: [],
    rewardContent: {
        loading: true,
        itemData: {},
    },
    modals: {
        rewardClaimed: {
            isOpen: false,
            items: [],
            bundlesId: [],
            loading: {},
        },
    },
};
