import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSessionStorage } from '../../../services';
import { globalSel } from '../../../store/global';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import { isAuthorizationUri } from 'src/helpers/utils';

const ANIMATION_TIME = 350;

const GlobalLoaderWrapper = () => {
    const isStoreRedirect = getSessionStorage(process.env.REACT_APP_AUTH_SUCCESS_REDIRECT!);
    const errorModalData = useSelector(globalSel.errorModalSelector);

    const [loaded, setLoaded] = useState({
        domContentLoaded: false,
        authenticatedAfterRedirect: !isStoreRedirect,
    });
    const [removeElement, setRemoveElement] = useState(false);

    const isAuthenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    useEffect(() => {
        // This code runs after the component has mounted
        // and the first render is complete (equivalent to DOMContentLoaded).
        // window.addEventListener might conflict with React for several reasons (React's Virtual DOM,
        // React's Component Lifecycle like useState, Re-rendering Issues)
        setLoaded((prevState) => ({
            ...prevState,
            domContentLoaded: true,
        }));
    }, []);

    useEffect(() => {
        const currentLocation = window.location.href;
        const isScopelyAuth = isAuthorizationUri(currentLocation);
        const isStorePageRedirected = isAuthenticated || isStoreRedirect;

        if (isScopelyAuth && isAuthenticated) {
            return setLoaded((prevState) => ({
                ...prevState,
                authenticatedAfterRedirect: true,
            }));
        }

        if (errorModalData.type === 'game-in-maintenance') {
            return setLoaded((prevState) => ({
                ...prevState,
                authenticatedAfterRedirect: true,
            }));
        }

        if (isScopelyAuth) {
            return setLoaded((prevState) => ({
                ...prevState,
                authenticatedAfterRedirect: false,
            }));
        }

        if (isStorePageRedirected) {
            return setLoaded((prevState) => ({
                ...prevState,
                authenticatedAfterRedirect: true,
            }));
        }

        setLoaded((prevState) => ({
            ...prevState,
            authenticatedAfterRedirect: true,
        }));

    }, [isAuthenticated, currentUser, isStoreRedirect, errorModalData.type]);

    useEffect(() => {
        if (loaded.authenticatedAfterRedirect && loaded.domContentLoaded) {
            setTimeout(() => {
                setRemoveElement(true);
            }, ANIMATION_TIME);
        }
    }, [loaded]);

    if (removeElement) {
        return null;
    }

    return <GlobalLoader close={loaded.authenticatedAfterRedirect && loaded.domContentLoaded} />;
};

export default GlobalLoaderWrapper;
