import React, { type FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/@types/hooks';
import { globalOp, globalSel } from 'src/store/global';
import AuthorizeModal from 'src/views/components/Modal/AuthorizeModal/AuthorizeModal';
import ErrorModal from 'src/views/components/Modal/ErrorModal/ErrorModal';
import NotSupportedPlatformModal from 'src/views/components/Modal/NotSupportedPlatformModal/NotSupportedPlatformModal';

interface IProps {
    isLoggedIn: boolean;
}

const CommonModals: FC<IProps> = ({ isLoggedIn }) => {
    const dispatch = useAppDispatch();

    const isAuthenticatedModal = useSelector(globalSel.isAuthenticatedModalSelector);
    const dialogData = useSelector(globalSel.playOnPCDialogDataSelector);
    const errorModalData = useSelector(globalSel.errorModalSelector);
    // const isAuthenticatedModal = useSelector(globalSel.isAuthenticatedModalSelector);

    const handleCloseDialogModal = () => {
        dispatch(
            globalOp.handlePlayOnPCDialogData({
                ...dialogData,
                open: false,
            }),
        );
    };

    const handleCloseErrorModal = () => {
        dispatch(globalOp.setErrorModal({ open: false }));
    };

    const handleCloseAuthModal = () => {
        dispatch(globalOp.handleAuthenticatedModalToggle({ open: false, type: 'storeAuth' }));
    };

    useEffect(() => {
        if (isLoggedIn) {
            handleCloseAuthModal();
        }
    }, [isLoggedIn]);

    return (
        <>
            <NotSupportedPlatformModal
                open={dialogData.open}
                title={dialogData.titleText}
                description={dialogData.bodyText}
                confirmAction={dialogData.acceptAction}
                confirmText={dialogData.confirmText}
                declineAction={dialogData.declineAction}
                declineText={dialogData.declineText}
                onClose={handleCloseDialogModal}
            />

            <AuthorizeModal
                isOpen={isAuthenticatedModal.open}
                type={isAuthenticatedModal.type}
                onClose={handleCloseAuthModal}
            />

            <ErrorModal modalData={errorModalData} onCloseModal={handleCloseErrorModal} />
        </>
    );
};

export default CommonModals;
