import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface IProps {
    alt?: string;
    height: string;
    width: string;
    src: string;
    effect?: 'blur' | 'black-and-white' | 'opacity';
    placeholderSrc?: string;
    threshold: number;
}

const LazyImage: React.FC<IProps> = ({
    alt,
    height = '100%',
    width = '100%',
    src = '',
    effect,
    placeholderSrc,
    threshold = 100,
}) => (
    <LazyLoadImage
        alt={alt}
        height={height}
        src={src}
        width={width}
        effect={effect}
        placeholderSrc={placeholderSrc}
        threshold={threshold}
    />
);

export default LazyImage;
