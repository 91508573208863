import {
    type TGetNewsBySlugResponse,
    type TGetNewsItemsArguments,
    type TNewsCategories,
    type TOperation,
} from 'src/@types';
import { type IInGameNews, type INewsItems, type TNewsDetails } from 'src/@types/store';
import { sortInGameNewsByPriority } from 'src/helpers/dataManipulations';

import { fetch } from '../../services';
import { newsSlice } from './newsSlice';

// TODO handle all errors, in "catch" method
const getNewsItems: TOperation<TGetNewsItemsArguments, INewsItems> = ({
    category = 'All',
    page = 1,
}) => {
    const { setLoading, setNewsItems } = newsSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await fetch<INewsItems>({
                endPoint: `/news?category=${category}&page=${page}&limit=8`,
            });

            dispatch(setNewsItems(data));
            dispatch(setLoading(false));

            return (
                data || {
                    count: 0,
                    currentPage: 1,
                    data: [],
                    limit: 0,
                    pages: 0,
                }
            );
        } catch (err) {
            return {
                count: 0,
                currentPage: 1,
                data: [],
                limit: 0,
                pages: 0,
            };
        }
    };
};

const getNewsCategories: TOperation<void, TNewsCategories[]> = () => {
    const { setCategoryList } = newsSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<TNewsCategories[]>({
                endPoint: `/news/categories`,
            });

            dispatch(setCategoryList(data));

            return data;
        } catch (err) {
            return [];
        }
    };
};

const getNewsBySlug: TOperation<string, TGetNewsBySlugResponse> = (slug) => {
    const { setNewsDetails, setIsNewsDetailsLoading } = newsSlice.actions;

    return async (dispatch) => {
        dispatch(setIsNewsDetailsLoading(true));

        try {
            const { data } = await fetch<TNewsDetails>({
                endPoint: `/news/${slug}`,
            });

            dispatch(setNewsDetails(data));

            return data;
        } catch (err) {
            return null;
        } finally {
            dispatch(setIsNewsDetailsLoading(false));
        }
    };
};

const getInGameNews: TOperation = () => {
    const { setInGameNews } = newsSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<IInGameNews[]>({
                endPoint: `/in-game-news`,
                privateRequest: true,
            });

            if (data && data.length > 1) {
                sortInGameNewsByPriority(data);
                dispatch(setInGameNews(data));
            }
        } catch (err) {
            // return [];
        }
    };
};

const clearInGameNews: TOperation = () => {
    const { setInGameNews } = newsSlice.actions;
    return (dispatch) => {
        dispatch(setInGameNews([]));
    };
};

export const newsOp = {
    getNewsItems,
    getNewsCategories,
    getInGameNews,
    clearInGameNews,
    getNewsBySlug,
};
