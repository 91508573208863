import styled from 'styled-components';

export const InputWithButtonStyled = styled.div`
    .WP-InputWithButton-container {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid;
        backdrop-filter: blur(10px);

        .WP-InputWithButton {
            height: 20px;
            input {
                font-size: 14px;
                ${({ theme }) => theme.breakpoints.up('sm')} {
                    font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
                    line-height: ${({ theme }) => theme.typography.size.p16.lineHeight};
                }

                padding: 0;
                ${({ theme: { palette }, hasError }) => ({
                    color: hasError
                        ? palette.red[600]
                        : palette.mode === 'dark'
                          ? palette.lightMode.grey[400]
                          : palette.lightMode.grey[600],
                })};

                ::placeholder,
                ::-webkit-input-placeholder {
                    color: ${({ theme: { palette } }) =>
                        palette.mode === 'dark'
                            ? palette.lightMode.grey[400]
                            : palette.lightMode.grey[600]};
                    opacity: 1;
                }

                :-ms-input-placeholder {
                    color: ${({ theme: { palette } }) =>
                        palette.mode === 'dark'
                            ? palette.lightMode.grey[400]
                            : palette.lightMode.grey[600]};
                    opacity: 1;
                }
            }
        }
        ${({ theme: { palette }, hasError }) => ({
            borderColor: hasError
                ? palette.red[300]
                : palette.mode === 'dark'
                  ? `rgba(255, 255, 255, 0.4)`
                  : '#fff',
        })};
    }
`;
