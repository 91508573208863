import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { BUGSNAG_API_KEY } from './constants';

Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
