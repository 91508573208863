import { groupEventsTranslationsById } from 'src/helpers/dataManipulations';
import { fetch } from 'src/services';

import { eventsSlice } from './eventsSlice';

const getEvents = () => {
    const { setEventsLoading, setEvents, setEventsBundles } = eventsSlice.actions;

    return async (dispatch) => {
        dispatch(setEventsLoading(true));

        try {
            const { data } = await fetch({
                endPoint: '/events',
                privateRequest: true,
            });

            const { events, bundles } = data;

            const parsedBundlesData = {};

            bundles.forEach((bundle) => {
                parsedBundlesData[bundle.bundle_id] = bundle;
            });

            dispatch(setEvents(events));
            dispatch(setEventsBundles(parsedBundlesData));
        } catch (e) {
            // dispatch(setProcessingReward(null));
            return e;
        } finally {
            dispatch(setEventsLoading(false));
        }
    };
};

const getEventsTranslations = (configIds) => {
    const { setEventsTranslationLoading, setEventsTranslations } = eventsSlice.actions;

    return async (dispatch) => {
        dispatch(setEventsTranslationLoading(true));

        try {
            const { data } = await fetch({
                endPoint: `/events/translations/?configId=${configIds.join()}`,
                privateRequest: true,
            });

            const groupedData = groupEventsTranslationsById(data.event || []);
            dispatch(setEventsTranslations(groupedData));
        } catch (e) {
            // dispatch(setProcessingReward(null));
            return e;
        } finally {
            dispatch(setEventsTranslationLoading(false));
        }
    };
};

const getEventsSidebarTimeStamp = () => {
    const { setEventsSidebarTimeStamp } = eventsSlice.actions;
    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `/events/time`,
                privateRequest: true,
            });

            dispatch(setEventsSidebarTimeStamp(data));
        } catch (e) {
            return e;
        }
    };
};

const setIsNewEventsShown = (data) => {
    const { setIsNewEventsShown } = eventsSlice.actions;

    return (dispatch) => dispatch(setIsNewEventsShown(data));
};

const toggleRewardModalLoading = (configId, loading = true) => {
    const { toggleRewardClaimModalLoading } = eventsSlice.actions;
    return (dispatch) => dispatch(toggleRewardClaimModalLoading({ configId, loading }));
};

const claimReward = (configId) => {
    const { toggleRewardClaimModal, toggleRewardClaimModalLoading } = eventsSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: '/events/claim-rewards',
                privateRequest: true,
                method: 'POST',
                body: {
                    configId,
                },
            });

            if (data.code === 200) {
                dispatch(
                    toggleRewardClaimModal({
                        open: true,
                    }),
                );
            }
        } catch (e) {
            // dispatch(setProcessingReward(null));
            return e;
        } finally {
            dispatch(toggleRewardClaimModalLoading({ configId, loading: false }));
        }
    };
};

const dropRewardClaimModal = () => {
    const { setRewardClaimModalData } = eventsSlice.actions;

    return (dispatch) => {
        dispatch(
            setRewardClaimModalData({
                open: false,
                items: [],
                bundlesId: [],
            }),
        );
    };
};

const getRewardContentTranslations = (ids, types, bundleId) => {
    const { setEventsContentTranslations, setEventsContentTranslationsLoading } =
        eventsSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `/translations/content?ids=${ids.join()}&types=${types.join()}`,
                privateRequest: true,
            });

            const itemData = {};
            data.forEach((item) => (itemData[item.id] = item));

            dispatch(setEventsContentTranslations({ bundleId, itemData }));
        } catch (err) {
            // todo Add error handler
        } finally {
            dispatch(setEventsContentTranslationsLoading(bundleId));
        }
    };
};

const clearRewardContentTranslations = () => (dispatch) => {
    const { clearRewardContentItemData } = eventsSlice.actions;
    dispatch(clearRewardContentItemData());
};

const setPossibleItemsClaimData = ({ rewards, ranking, lastClaimedIndex, rewardsIds = [] }) => {
    const { setRewardClaimModalData } = eventsSlice.actions;

    /**
     * Filter the available to claim bundles,
     * and multiply the the items count related to the amount number
     */

    const filteredBundles = rewards
        .filter(
            (bundle, index) =>
                ranking.score >= bundle.rewardData.position[0] && index > lastClaimedIndex,
        )
        .map((bundle) => {
            const bundleAmount = bundle.rewardData.amount;

            if (bundleAmount > 1) {
                return {
                    ...bundle,
                    content: {
                        ...bundle.content,
                        static: bundle.content.static.map((c) => ({
                            ...c,
                            quantity: c.quantity * bundleAmount,
                        })),
                    },
                };
            }

            return bundle;
        });

    return (dispatch) => {
        dispatch(
            setRewardClaimModalData({
                open: false,
                items: filteredBundles,
                bundlesId: rewardsIds,
            }),
        );
    };
};

export const eventsOp = {
    getEvents,
    claimReward,
    dropRewardClaimModal,
    getRewardContentTranslations,
    clearRewardContentTranslations,
    getEventsTranslations,
    setPossibleItemsClaimData,
    getEventsSidebarTimeStamp,
    setIsNewEventsShown,
    toggleRewardModalLoading,
};
