import { useEffect, useState } from 'react';

import { type TOfferItem, type TUseOfferItemsSortByMultiPhasic } from '../@types/hooks/hooks';

// TODO remove all this file after backend implementation

const ids = {
    1546490552: 1546490552,
};

export const useOfferItemsSortByMultiPhasic: TUseOfferItemsSortByMultiPhasic = ({
    itemData,
    content,
}) => {
    const [sortedItems, setSortedItems] = useState<TOfferItem[]>([]);

    useEffect(() => {
        if (itemData && content.length > 0) {
            const sortedItems: TOfferItem[] = [];

            content.forEach((item) => {
                const parsedId = +item.id;

                if (parsedId === ids[parsedId]) {
                    sortedItems.unshift(item);
                } else {
                    sortedItems.push(item);
                }
            });

            setSortedItems(sortedItems);
        }
    }, [itemData, content]);

    return sortedItems;
};
