import styled from 'styled-components';

export const NavStyled = styled.nav`
    .nav-link {
        ${({
            theme: {
                palette,
                spacing,
                helpers,
                palette: { mode },
            },
        }) => ({
            color: mode === 'dark' ? '#8a8f99' : palette.lightMode.grey[900],
            marginBottom: `${spacing}px`,
            transition: helpers.transitions('color'),

            '&:hover': {
                color: mode === 'dark' ? '#ffc926' : palette.blue[400],
            },
        })};

        display: block;
        white-space: nowrap;
        text-decoration: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .active-link {
        position: relative;
        border: 1px solid;
        border-image-slice: 1;
        border-left: 0;
        border-right: 0;

        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            background:
                mode === 'dark'
                    ? `
                        linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.15) 0%,
                            rgba(255, 255, 255, 0) 100%
                        )
                    `
                    : `
                        linear-gradient(
                            90.88deg,
                            rgba(91, 170, 213, 0.1) 0.58%,
                            rgba(91, 170, 213, 0) 99.34%
                        )
                    `,
            borderImageSource:
                mode === 'dark'
                    ? `
                        linear-gradient(
                            90deg,
                            rgba(255, 201, 38, 0.8) 0%,
                            rgba(255, 201, 38, 0) 100%
                        )
                    `
                    : `
                        linear-gradient(
                            90deg,
                            rgba(191, 216, 229, 0.8) 0%,
                            rgba(204, 229, 242, 0) 100%
                        )
                    `,
            color: mode === 'dark' ? '#ffc926' : palette.blue[400],
        })};

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;

            ${({ theme }) => theme.palette.mode === 'light'} {
                width: 4px;
                background: linear-gradient(
                    180deg,
                    rgba(255, 201, 38, 0) 0%,
                    #ffc926 49.77%,
                    rgba(255, 201, 38, 0) 100%
                );
                filter: blur(2px);
            }

            ${({ theme }) => theme.palette.mode === 'dark'} {
                top: 50%;
                transform: translateY(-50%);
                width: 4px;
                height: 72%;
                left: -2px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                background: #5baad5;
            }
        }
    }

    .nav-label {
        text-transform: uppercase;
    }

    .WP-Nav-notificationIndicator {
        border-radius: 50%;
    }

    .WP-Nav-ClaimableEventsCount {
        border-radius: 50%;
        background: linear-gradient(180deg, #5cc75c 0%, #1c6c1c 100%);
        color: ${({ theme }) => theme.palette.common.white};
    }
`;
