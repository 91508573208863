import { type Breakpoint, type PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { textCutX, textCutY, textGradient } from 'src/helpers/newStyleHelpers';
import { hexToRgba, transitions } from 'src/helpers/styleHelpers';

import { inGamesNewsCard, newsCard, offerCard, specialOffers } from './components';
import { palette } from './palette';
import newTypography from './typography/newTypography';

const x = {
    xs: 'sm',
    sm: 'md',
    md: 'lg',
    lg: 'xl',
};

export const theme = (mode: PaletteMode = 'dark') => {
    const muiDialogProps =
        mode === 'light'
            ? {
                  boxShadow: 'none',
              }
            : {};

    const mergedPalette = { ...palette, mode };

    return createTheme({
        palette: mergedPalette,
        spacing: 4,
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1280,
                lg: 1440,
                xl: 1920,
            },
            get down() {
                return (key: Breakpoint | number): string => {
                    const supportedDeprecatedStyle = x[key];

                    if (supportedDeprecatedStyle) {
                        return `@media (max-width:${this.values[x[key]] - 0.5}px)`;
                    }

                    return `@media (min-width: 0px)`;
                };
            },
            get up() {
                return (key: Breakpoint | number): string => {
                    const existingMedia = this.values[key];

                    if (existingMedia) {
                        return `@media (min-width:${existingMedia}px)`;
                    }

                    return `@media (min-width: 0px)`;
                };
            },
        },
        customComponents: {
            newsCard: newsCard(mergedPalette),
            inGamesNewsCard: inGamesNewsCard(mergedPalette),
            progress: {
                mainBG: palette.grey.light[400],
                fillerBG: palette.green[400],
                fillerCPrimary: `linear-gradient(0deg, #896B0D 0%, #FEC826 100%)`,
                fillerCSecondary: palette.green[600],
            },
            offerCard: offerCard(mergedPalette),
            specialOffers: specialOffers(mergedPalette),
        },
        helpers: {
            hexToRgba,
            textCutY,
            textCutX,
            textGradient,
            transitions,
        },
        shape: {
            borderRadius: 0,
        },
        typography: {
            fontFamily: "'Univia Pro', 'Helvetica', 'Arial', sans-serif",
            fontSize: 14,
            htmlFontSize: 16,
            ...newTypography,
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        backgroundImage: 'none',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paperWidthSm: {
                        maxWidth: 670,
                    },
                    paperWidthMd: {
                        maxWidth: 980,
                    },
                    paper: {
                        width: '100%',
                        margin: '0 20px',
                        overflow: 'visible',
                        overflowY: 'visible',
                        ...muiDialogProps,
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        border: '1px solid',
                        backgroundColor: mode === 'dark' ? '#242629' : '#EBEDF2',
                        borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#EBEDF2',
                    },
                    switchBase: {
                        color: '#737780',

                        '&.Mui-checked': {
                            color: '#48B048',
                        },

                        '&.Mui-disabled': {
                            color: '#4F5259',
                        },
                    },
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark' ? '#2A2A2D' : '#E7E9ED',
                    },
                    text: {
                        backgroundColor: mode === 'dark' ? '#353538' : '#EBEDF0',
                        backdropFilter: 'blur(8px)',
                    },
                    rectangular: {
                        backgroundColor: mode === 'dark' ? '#353538' : 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(8px)',
                    },
                    circular: {
                        backgroundColor: mode === 'dark' ? '#353538' : 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(8px)',
                    },
                    wave: {
                        '&::after': {
                            background: `linear-gradient(90deg, transparent, ${
                                mode === 'dark' ? '#4A4A4F' : '#EBEDF0'
                            }, transparent)`,
                        },
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor:
                            mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(237, 239, 242, 0.9)',
                        backdropFilter: 'blur(20px)',
                    },
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        color: palette.common.white,
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        h1: 'h1',
                        h2: 'h2',
                        h3: 'h3',
                        h4: 'h4',
                        h5: 'h5',
                        h6: 'h6',
                        p54: 'p54',
                        p40: 'p40',
                        p32: 'p32',
                        p28: 'p28',
                        p26: 'p26',
                        p24: 'p24',
                        p20: 'p20',
                        p18: 'p18',
                        p16: 'p16',
                        p14: 'p14',
                        p12: 'p12',
                        p10: 'p10',
                    },
                },
            },
        },
    });
};
