import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';

import { Box } from '../ui-components/Box/Box';
import { Typography } from '../ui-components/Typography/Typography';
import { SwitchContainerStyled } from './SwitchContainer.styled';

const SwitchContainer = ({ title, typographyAttributes, onChange, disabled, checked }) => {
    const handleChange = (event) => {
        onChange(event.target.checked);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={4}
            component={SwitchContainerStyled}
            disabled={disabled}
        >
            <Typography {...typographyAttributes}>{title}</Typography>

            <Box className="WP-SwitchContainer-formControlLabel-wrapper">
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={checked}
                            onChange={handleChange}
                            value={checked}
                            color="default"
                            className="WP-switch-styles"
                        />
                    }
                    labelPlacement="start"
                />
            </Box>
        </Box>
    );
};

SwitchContainer.defaultProps = {
    checked: false,
    onChange: () => {},
    typographyAttributes: {},
};

SwitchContainer.propTypes = {
    title: PropTypes.string,
    typographyAttributes: PropTypes.object,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default SwitchContainer;
