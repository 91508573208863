import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { pageMaintenanceModeRenderer } from 'src/helpers/utils';

import { REACT_APP_S3 } from '../../../constants';
import { globalSel } from '../../../store/global';
import { Box } from '../../components/ui-components/Box/Box';
import LazyImage from '../../components/ui-components/LazyImage/LazyImage';
import { MainStyled } from './Main.styled';

export default function Main({ children }) {
    const bannerConfig = useSelector(globalSel.bannerConfig);
    const killSwitchConfig = useSelector(globalSel.killSwitchConfig)?.value;

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const { pathname } = useLocation();

    const isPageInMaintenance = pageMaintenanceModeRenderer(pathname, killSwitchConfig);

    const isBgImageNeedToBeShown =
        bannerConfig?.value?.bannerBackgroundType === 'video' ? !mdUp || pathname !== '/' : true;

    return (
        <Box
            component={MainStyled}
            display="flex"
            alignItems="stretch"
            position="relative"
            flexGrow={1}
            pt={15}
            pl={[0, 0, 61]}
            width={1}
            height={1}
            hideOverflow={pathname === '/'}
            minHeight="100vh"
        >
            <Box px={[5, 8]} width={1}>
                {children}
            </Box>

            {isBgImageNeedToBeShown && bannerConfig && pathname !== '/redeem-code' && (
                <Box
                    position={
                        pathname === '/maintenance' || isPageInMaintenance ? 'fixed' : 'absolute'
                    }
                    top={0}
                    left={0}
                    right={0}
                    className="WP-Main-background"
                    width={1}
                    height={['70vh', 'auto']}
                    zIndex="-10"
                >
                    <LazyImage
                        src={`${REACT_APP_S3}${bannerConfig.value.background}`}
                        alt="background"
                        width="100%"
                        height="100%"
                    />
                </Box>
            )}
        </Box>
    );
}

Main.propTypes = {
    children: PropTypes.node,
};
