import { getColorStyle } from 'src/helpers/styleHelpers';
import styled from 'styled-components';

interface IStyledProps {
    outerBorder: string;
    innerBorder: string;
    outerBgColor: string;
    innerBgColor: string;
    hasBlur: boolean;
}

export const BorderedContentStyled = styled.div<IStyledProps>`
    ${({ outerBorder }) => `${getColorStyle(outerBorder)}!important`};
    ${({ hasBlur }) => `${hasBlur ? `background: rgba(255, 255, 255, 0.03);` : ''}`};
    ${({ hasBlur }) => `${hasBlur ? `backdrop-filter: blur(10px);` : ''}`};

    background: ${({ outerBgColor }) => (outerBgColor ? outerBgColor : 'transparent')};

    .WP-BorderedContent-body {
        ${({ innerBorder }) => `${getColorStyle(innerBorder)}!important`};
        background: ${({ innerBgColor }) => (innerBgColor ? innerBgColor : 'transparent')};
    }
`;
