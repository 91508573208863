import { FEATURED_GROUP_ID, WEB_GIFTS_GROUP_ID } from '../../constants/offer.constants';
import { type IInitialOfferState } from './../../@types/store/offer';

export const initialOfferState: IInitialOfferState = {
    isOfferLoading: true,
    isWebGiftsLoading: true,
    isOfferPurchasing: false,
    offerItems: [],
    secondaryOfferItems: {},
    webGifts: [],
    offerBanner: null,
    paymentStatus: null,
    contentItemData: {},
    contentItemLoading: {},
    localizedItems: {},
    processingBundle: null,
    offerBannerQuantity: {},
    boughtBundleId: null,
    claimableWebGiftsCount: 0,
    webGiftsSection: {
        claimedItems: {
            isModalOpen: false,
            items: [],
            bundleId: 0,
            quantity: 1,
        },
    },
    offerSection: {
        modals: {
            paymentStatus: {
                type: 'success',
                isModalOpen: false,
            },
            offerPreview: {
                isModalOpen: false,
                bundle: null,
            },
        },
    },
    storeGroups: [],
    isStoreGroupsLoading: true,
    selectedStoreGroup: null,
    webGiftGroup: {
        store_group_id: WEB_GIFTS_GROUP_ID,
        name: 'Web Gifts',
        trans_id: 'store_gifts_button_text',
        sort_order: 1,
        translated_title: null,
    },
    featuredGroup: {
        store_group_id: FEATURED_GROUP_ID,
        name: 'Featured',
        trans_id: 'store_featured_offers_button_text',
        sort_order: 0,
        translated_title: null,
    },
    selectedBucket: null,
    isStoreGroupsTransitioning: false,
    lastDataFetchTimestamp: null,
};

// TODO change state to more complex structure
