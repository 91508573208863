import { type IGlobalPalette } from 'src/@types';
import { hexToRgba } from 'src/helpers/styleHelpers';

/**
 *  HUD Grey Colors
 */

const darkGrey800 = '#17181A';
const darkGrey700 = '#1F2023';
const darkGrey600 = '#242629';
const darkGrey500 = '#2D2F33';
const darkGrey400 = '#383B40';
const darkGrey300 = '#43464D';
const darkGrey200 = '#4F5259';
const darkGrey100 = '#676B73';

const lightGrey800 = '#737780';
const lightGrey700 = '#8A8F99';
const lightGrey600 = '#A1A7B3';
const lightGrey500 = '#B0B5BF';
const lightGrey400 = '#BCC1CC';
const lightGrey300 = '#CED2D9';
const lightGrey200 = '#DCE0E6';
const lightGrey100 = '#EBEDF2';

/**
 *  Main CoreUI Colors
 */

const green800 = '#1C6C1C';
const green700 = '#2C8C2C';
const green600 = '#48B048';
const green500 = '#5FC95F';
const green400 = '#7BDB7B';
const green300 = '#A3EBA3';
const green200 = '#C5F2C5';
const green100 = '#DBFBDB';

const blue800 = '#0E3D56';
const blue700 = '#1D5C7E';
const blue600 = '#2A7399';
const blue500 = '#418EB7';
const blue400 = '#5BAAD5';
const blue300 = '#7DC3E9';
const blue200 = '#96D2F2';
const blue100 = '#BAE2F8';

const red800 = '#6E1212';
const red700 = '#882020';
const red600 = '#9C2D2D';
const red500 = '#B23F3F';
const red400 = '#C65454';
const red300 = '#DF6767';
const red200 = '#EF7D7D';
const red100 = '#F5A9A9';

const yellow800 = '#FFC30F';
const yellow700 = '#FFC926';
const yellow600 = '#FFD040';
const yellow500 = '#FFD968';
const yellow400 = '#FFE188';
const yellow300 = '#FFEAAA';
const yellow200 = '#FFF2CA';
const yellow100 = '#FFF9E6';

const whiteTransparent70 = hexToRgba('#fff', 0.7);
const whiteTransparent50 = hexToRgba('#fff', 0.5);
const whiteTransparent40 = hexToRgba('#fff', 0.4);
const whiteTransparent30 = hexToRgba('#fff', 0.3);
const whiteTransparent20 = hexToRgba('#fff', 0.2);
const whiteTransparent10 = hexToRgba('#fff', 0.1);

export const globalPalette: IGlobalPalette = {
    common: {
        white: '#fff',
        black: '#000',
    },
    black: {
        dark: {
            800: darkGrey800,
            700: darkGrey700,
            600: darkGrey600,
            500: darkGrey500,
            400: darkGrey400,
            300: darkGrey300,
            200: darkGrey200,
            100: darkGrey100,
        },
    },
    grey: {
        dark: {
            800: darkGrey800,
            700: darkGrey700,
            600: darkGrey600,
            500: darkGrey500,
            400: darkGrey400,
            300: darkGrey300,
            200: darkGrey200,
            100: darkGrey100,
        },
        light: {
            800: lightGrey800,
            700: lightGrey700,
            600: lightGrey600,
            500: lightGrey500,
            400: lightGrey400,
            300: lightGrey300,
            200: lightGrey200,
            100: lightGrey100,
        },
    },
    green: {
        800: green800,
        700: green700,
        600: green600,
        500: green500,
        400: green400,
        300: green300,
        200: green200,
        100: green100,
    },
    blue: {
        800: blue800,
        700: blue700,
        600: blue600,
        500: blue500,
        400: blue400,
        300: blue300,
        200: blue200,
        100: blue100,
    },
    red: {
        800: red800,
        700: red700,
        600: red600,
        500: red500,
        400: red400,
        300: red300,
        200: red200,
        100: red100,
    },
    yellow: {
        800: yellow800,
        700: yellow700,
        600: yellow600,
        500: yellow500,
        400: yellow400,
        300: yellow300,
        200: yellow200,
        100: yellow100,
    },
    white: {
        transparent70: whiteTransparent70,
        transparent50: whiteTransparent50,
        transparent40: whiteTransparent40,
        transparent30: whiteTransparent30,
        transparent20: whiteTransparent20,
        transparent10: whiteTransparent10,
    },
};
