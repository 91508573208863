import { type PaletteMode } from '@mui/material';
import {
    hexToRgba,
    textCutX,
    textCutY,
    textGradient,
    toggleScrollBar,
    transitions,
} from 'src/helpers/styleHelpers';
import { type DefaultTheme } from 'styled-components';

import { inGamesNewsCard, newsCard, offerCard, specialOffers } from './components';
import { theme as muiTheme } from './themeMUI';
import { typography } from './typography/typography';

export const theme = (mode: PaletteMode): DefaultTheme => {
    const materialTheme = muiTheme(mode);
    return {
        palette: materialTheme.palette,
        breakpoints: materialTheme.breakpoints,
        spacing: materialTheme.spacing,
        helpers: {
            hexToRgba,
            textCutY,
            textCutX,
            textGradient,
            transitions,
            toggleScrollBar,
        },
        typography,
        zIndex: {
            appBar: 1100,
            drawer: 1200,
            modal: 1300,
            snackbar: 1400,
            tooltip: 1500,
        },
        components: {
            newsCard: newsCard(materialTheme.palette),
            inGamesNewsCard: inGamesNewsCard(materialTheme.palette),
            progress: {
                mainBG: materialTheme.palette.grey.light[400],
                fillerBG: materialTheme.palette.green[400],
                fillerCPrimary: `linear-gradient(0deg, #896B0D 0%, #FEC826 100%)`,
                fillerCSecondary: materialTheme.palette.green[600],
            },
            offerCard: offerCard(materialTheme.palette),
            specialOffers: specialOffers(materialTheme.palette),
        },
        customComponents: {
            newsCard: newsCard(materialTheme.palette),
            inGamesNewsCard: inGamesNewsCard(materialTheme.palette),
            progress: {
                mainBG: materialTheme.palette.grey.light[400],
                fillerBG: materialTheme.palette.green[400],
                fillerCPrimary: `linear-gradient(0deg, #896B0D 0%, #FEC826 100%)`,
                fillerCSecondary: materialTheme.palette.green[600],
            },
            offerCard: offerCard(materialTheme.palette),
            specialOffers: specialOffers(materialTheme.palette),
        },
    };
};
