import React from 'react';

import { Box } from '../ui-components/Box/Box';
import { BorderedContentStyled } from './BorderedContent.styled';

interface IProps {
    innerBorder?: string;
    outerBorder?: string;
    innerBgColor?: string;
    outerBgColor?: string;
    border?: number;
    borderRadius?: [number | string, number | string];
    innerBorderWidth?: number;
    p?: number | string | number[] | string[];
    width?: number | string | number[] | string[];
    className?: string;
    hasBlur?: boolean;
    onClick?: () => void;
    [otherProps: string]: any;
}

const BorderedContent: React.FC<IProps> = ({
    innerBorder = 'transparent',
    innerBorderWidth = 1,
    outerBorder,
    innerBgColor,
    outerBgColor,
    border = 1,
    p = 2,
    borderRadius = [0, 0],
    width = 1,
    children,
    className,
    hasBlur = false,
    onClick = () => ({}),
    ...otherProps
}) => {
    return (
        <Box
            component={BorderedContentStyled}
            innerBorder={innerBorder}
            outerBorder={outerBorder}
            border={border}
            width={width}
            p={p}
            innerBgColor={innerBgColor}
            outerBgColor={outerBgColor}
            borderRadius={borderRadius[0]}
            className={className}
            hasBlur={hasBlur}
            onClick={onClick}
            {...otherProps}
        >
            <Box
                border={innerBorderWidth}
                flex={1}
                borderRadius={borderRadius[1]}
                className="WP-BorderedContent-body"
            >
                {children}
            </Box>
        </Box>
    );
};

export default BorderedContent;
