import { styled } from '@mui/material/styles';

export const HeaderStyled = styled('header')(({ theme: { palette, breakpoints, zIndex } }) => {
    const isDark = palette.mode === 'dark';

    return {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: zIndex.appBar,
        height: 60,
        padding: '0 16px',

        backdropFilter: isDark ? 'none' : 'blur(20px)',
        borderBottom: `1px solid ${
            isDark ? palette.white.transparent20 : palette.white.transparent50
        }`,

        [breakpoints.up('sm')]: {
            padding: '0 32px',
            height: 61,
        },

        [breakpoints.up('md')]: {
            left: 244,
        },

        '.avatar': {
            objectFit: 'contain',
        },

        '.button-text': {
            zIndex: 1,
        },

        '.WP-Header-log-out-btn': {
            flex: 1,
        },
    };
});
