export const inGamesNewsCard = (palette) => {
    const isDarkMode = palette.mode === 'dark';

    return {
        mainBG: isDarkMode ? palette.grey.dark[700] : palette.lightMode.grey[100],
        mainBGSelected: isDarkMode ? '#393C40' : palette.common.white,

        outerBorder: isDarkMode ? palette.white.transparent20 : palette.white.transparent70,
        labelHr: isDarkMode ? palette.grey.dark[500] : '#E9EBF0',

        dateC: isDarkMode ? palette.grey.light[700] : '#8A8F99',
        titleC: isDarkMode ? palette.grey.light[700] : palette.lightMode.grey[900],
        titleBlueC: palette.blue[400],
        titleYellowC: palette.yellow[700],

        linkC: isDarkMode ? palette.grey.light[400] : palette.grey.light[700],
        linkHoverC: isDarkMode ? palette.grey.light[100] : palette.lightMode.grey[500],
    };
};
