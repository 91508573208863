import styled from 'styled-components';

export const RightSidebarStyled = styled.aside`
    transition: transform 200ms ease-out;
    border: 0;
    border-left: 1px solid;
    border-color: ${({
        theme: {
            palette,
            palette: { mode },
        },
    }) => (mode === 'dark' ? ` ${palette.white.transparent20}` : palette.grey.light[100])};

    ${({ theme }) => theme.breakpoints.down('md')} {
        transform: translateX(280px);
        &.show {
            transform: translateX(0);
            overflow: hidden;
            touch-action: none;
        }
    }
`;
