import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SNACKBAR_DURATION } from '../../../constants';
import { globalOp, globalSel } from '../../../store/global';
import { Box } from '../ui-components/Box/Box';
import SnackbarComponent from './components/SnackbarComponent';
import { useAppDispatch } from 'src/store/configureStore';

interface IProps {}

const SnackBar: React.FC<IProps> = () => {
    const dispatch = useAppDispatch();

    const { open, message, messageTranslationKey, variant, isTimeOutDisabled } = useSelector(
        globalSel.snackbarSelector,
    );

    const theme = useSelector(globalSel.themeSelector);

    const handleCloseSnackbar = () => {
        dispatch(globalOp.handleCloseSnackbar());
    };

    useEffect(() => {
        if (!isTimeOutDisabled && open) {
            setTimeout(() => {
                dispatch(globalOp.handleCloseSnackbar());
            }, SNACKBAR_DURATION);
        }
        // eslint-disable-next-line
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <Box
            position="fixed"
            top={0}
            right={0}
            height={1}
            zIndex={1600}
            width={[310, 350]}
            data-testid="WP-SnackBar"
        >
            <SnackbarComponent
                open={open}
                message={message || ''}
                messageTranslationKey={messageTranslationKey || ''}
                variant={variant || 'info'}
                onClose={handleCloseSnackbar}
                theme={theme}
                isTimeOutDisabled={isTimeOutDisabled}
            />
        </Box>
    );
};

export default SnackBar;
