import { getCookieStatus } from './cookie.service';
import { getCookieLess, removeCookieLess, setCookieLess } from './cookieLess.service';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './localStorage.service';
import {
    getSessionStorage,
    removeSessionStorage,
    setSessionStorage,
} from './sessionStorage.service';

export { fetch, abortPendingRequest } from './fetch.service';

export {
    setCookie,
    getCookie,
    getAllCookies,
    getCookieStatus,
    removeCookie,
} from './cookie.service';

const isCookieDisabled = !getCookieStatus();

let handleSetSessionStorage = setSessionStorage;
let handleGetSessionStorage = getSessionStorage;
let handleRemoveSessionStorage = removeSessionStorage;

let handleSetLocalStorage = setLocalStorage;
let handleGetLocalStorage = getLocalStorage;
let handleRemoveLocalStorage = removeLocalStorage;

if (isCookieDisabled) {
    handleSetSessionStorage = setCookieLess;
    handleGetSessionStorage = getCookieLess;
    handleRemoveSessionStorage = removeCookieLess;

    handleSetLocalStorage = setCookieLess;
    handleGetLocalStorage = getCookieLess;
    handleRemoveLocalStorage = removeCookieLess;
}

export {
    handleGetSessionStorage as getSessionStorage,
    handleSetSessionStorage as setSessionStorage,
    handleRemoveSessionStorage as removeSessionStorage,
    handleGetLocalStorage as getLocalStorage,
    handleSetLocalStorage as setLocalStorage,
    handleRemoveLocalStorage as removeLocalStorage,
};
