import {
    getColorStyle,
    hexToRgba,
    toggleElement,
    tooltipArrowConfiger,
} from 'src/helpers/styleHelpers';
import styled from 'styled-components';

const aligns = (align, wrapperRight) => {
    switch (align) {
        case 'x-left':
            return `
                left: 0;
            `;
        case 'x-center':
            return `
                transform: translateX(-50%);
                left: 50%;
            `;
        case 'x-right':
            return `
                right: ${wrapperRight ? wrapperRight : '0'};
            `;
        case 'y-left':
            return `
                left: 0;
            `;
        case 'y-center':
            return `
                transform: translateY(-50%);
                top: 50%;
            `;
        case 'y-right':
            return `
                right: 0;
            `;
        default:
            break;
    }
};

export const ToolTipBoxStyled = styled.div`
    ${({ showOnHover }) => !showOnHover} {
        .WP-ToolTipBox-Body {
            ${toggleElement(false)};
        }

        &:hover,
        .WP-ToolTipBox-Body:hover {
            .WP-ToolTipBox-Body {
                ${toggleElement(true)};
            }
        }
    }

    .WP-ToolTipBox-Body {
        border: 1px solid;
        transition: opacity 350ms ease-out;

        ${({ align, wrapperRight }) => aligns(align, wrapperRight)};
        ${({ show }) => toggleElement(show)};
        ${({ bordercolor }) => getColorStyle(bordercolor)};
    }

    .WP-ToolTipBox-Content {
        position: relative;
        z-index: 1;
        background-color: ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => (mode === 'dark' ? '#2e3033' : palette.lightMode.grey[100])};
    }

    .WP-ToolTipBox-arrow {
        &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid transparent;
            ${({ arrowDirection, arrowOffset, arrowPosition }) => {
                const { transform, offset, position } = tooltipArrowConfiger(
                    arrowDirection,
                    arrowOffset,
                    arrowPosition,
                );

                return `
                    ${transform};
                    ${offset};
                    ${position};
                `;
            }};

            ${({
                theme: {
                    palette,
                    palette: { mode },
                },
            }) => ({
                backgroundColor: mode === 'dark' ? '#2e3033' : palette.lightMode.grey[100],
                borderTopColor: hexToRgba(palette.common.white, 0.2),
                borderLeftColor: hexToRgba(palette.common.white, 0.2),
            })};
        }
    }
`;
