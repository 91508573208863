type fontProperties = {
    fontSize: string;
    lineHeight: string;
};

export interface StyledTypography {
    size: {
        h1: fontProperties;
        h2: fontProperties;
        h3: fontProperties;
        h4: fontProperties;
        p54: fontProperties;
        p40: fontProperties;
        p32: fontProperties;
        p28: fontProperties;
        p26: fontProperties;
        p24: fontProperties;
        p20: fontProperties;
        p18: fontProperties;
        p16: fontProperties;
        p14: fontProperties;
        p12: fontProperties;
        p10: fontProperties;
        pre14: fontProperties;
    };
    weight: {
        bold: number;
        semiBold: number;
        medium: number;
        regular: number;
        book: number;
    };
    font: {
        architectsDaughter: string;
        fingerPaint: string;
        inter: string;
        univiaPro: string;
    };
}

export const typography: StyledTypography = {
    size: {
        h1: { fontSize: '32px', lineHeight: '44px' },
        h2: { fontSize: '28px', lineHeight: '38px' },
        h3: { fontSize: '24px', lineHeight: '32px' },
        h4: { fontSize: '20px', lineHeight: '28px' },
        p54: { fontSize: '54px', lineHeight: '72px' },
        p40: { fontSize: '40px', lineHeight: '54px' },
        p32: { fontSize: '32px', lineHeight: '38px' },
        p28: { fontSize: '28px', lineHeight: '36px' },
        p26: { fontSize: '26px', lineHeight: '34px' },
        p24: { fontSize: '24px', lineHeight: '32px' },
        p20: { fontSize: '20px', lineHeight: '28px' },
        p18: { fontSize: '18px', lineHeight: '26px' },
        p16: { fontSize: '16px', lineHeight: '24px' },
        p14: { fontSize: '14px', lineHeight: '22px' },
        p12: { fontSize: '12px', lineHeight: '18px' },
        p10: { fontSize: '10px', lineHeight: '12px' },
        pre14: { fontSize: '14px', lineHeight: '22px' },
    },
    weight: {
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400,
        book: 350,
    },
    font: {
        architectsDaughter: 'Architects Daughter',
        fingerPaint: 'Finger Paint',
        inter: 'Inter',
        univiaPro: 'Univia Pro',
    },
};
