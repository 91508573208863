import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { arrayElementsNotInTargetArray } from 'src/helpers/dataManipulations';
import { getLocalStorage } from 'src/services/localStorage.service';
import { eventsOp, eventsSel } from 'src/store/events';

import { AUTOMATION_TESTING_IDS } from '../../../../constants';
import { googleAnalyticsEvent } from '../../../../helpers/GA4Helper';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { globalSel } from '../../../../store/global';
import { offerOp } from '../../../../store/offer';
import { translationsSel } from '../../../../store/translation/selectors';
import TextSuspend from '../../../components/TextSuspend/TextSuspend';
import { Box } from '../../../components/ui-components/Box/Box';
import { Icon } from '../../../components/ui-components/Icon/Icon';
import { Typography } from '../../../components/ui-components/Typography/Typography';
import NavItemEventsLabel from './components/NavItemEventsLabel/NavItemEventsLabel';
import { NavStyled } from './Nav.styled';

const NavItem = ({ navItem, theme, extraInfoDisabledForEvents }) => {
    const t = useTranslation();

    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);

    return (
        <Box display="flex" alignItems="center" justifyContent="flex-start" height={50} px={4}>
            <Box display="flex" alignItems="center">
                <Box display="flex" justifyContent="center" width={20} mr={3}>
                    {navItem.iconComponent ? (
                        <Box width={1} height={20} component={navItem.iconComponent} />
                    ) : (
                        <Icon size={20} className={navItem.iconClassName} />
                    )}
                </Box>

                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <TextSuspend loading={translationsLoading}>
                        <Typography variant="p14" color="inherit">
                            {t(`${navItem.translationKey}`, navItem.fallBackName)}
                        </Typography>
                    </TextSuspend>

                    {navItem.key === 'events' &&
                        !translationsLoading &&
                        !extraInfoDisabledForEvents && (
                            <NavItemEventsLabel palette={theme.palette} />
                        )}
                </Box>
            </Box>
        </Box>
    );
};

const Nav = ({ navigationList, setOpenLeftSidebar, onModalOpen, dropScrollPosition }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const theme = useSelector(globalSel.themeSelector);
    const userLatestEvents = useSelector(eventsSel.eventsSidebarTimeStampSelector);
    const isAuthenticated = useSelector(globalSel.isAuthSelector);
    const isEventPageLive = useSelector(globalSel.killSwitchConfig)?.value.events;

    const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const handleNavItemClick = (needAuthorization, href) => {
        if (lgDown && href) {
            setOpenLeftSidebar(false);
        }
        if (needAuthorization) {
            onModalOpen(href);
        }
        if (pathname !== '/store') {
            dispatch(offerOp.setSelectedStoreGroup(null));
            dispatch(offerOp.setSelectedBucket(null));
        }
        dropScrollPosition();

        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'navigation',
            screen_category: 'navigation',
            interaction_object: 'navigation_button',
        });
        // eslint-disable-next-line default-case
        switch (href) {
            case '/store':
                // eslint-disable-next-line default-case
                switch (href) {
                    case '/store': {
                        googleAnalyticsEvent.event({
                            action: 'web_iap_store_viewed',
                            event_category: 'web_iap_store',
                            event_label: 'web_iap_store_navigation_button',
                        });
                        break;
                    }
                    case '/news': {
                        googleAnalyticsEvent.event({
                            action: 'blog_news_viewed',
                            event_category: 'blog_news',
                            event_label: 'blog_news_navigation_button',
                        });
                        break;
                    }
                    case '/redeem-code': {
                        googleAnalyticsEvent.event({
                            action: 'redeem_code_viewed',
                            event_category: 'redeem_code',
                            event_label: 'redeem_code_navigation_button',
                        });
                        break;
                    }
                }
        }
    };

    const createIdAttribute = (href) => {
        const isHomePage = !href.split('/')[1];

        return isHomePage
            ? `${AUTOMATION_TESTING_IDS.navigationItem}home`
            : AUTOMATION_TESTING_IDS.navigationItem + href.split('/')[1];
    };

    useEffect(() => {
        isAuthenticated && isEventPageLive && dispatch(eventsOp.getEventsSidebarTimeStamp());
    }, [pathname, isAuthenticated]);

    useEffect(() => {
        if (userLatestEvents.eventsData) {
            const userLatestEventsIds = userLatestEvents.eventsData?.map((item) => item.id);
            const userSeenEventIds = getLocalStorage('userSeenEventIds');
            if (Array.isArray(userSeenEventIds)) {
                let notSeenEventIds = arrayElementsNotInTargetArray(
                    userLatestEventsIds,
                    userSeenEventIds,
                );

                dispatch(eventsOp.setIsNewEventsShown(notSeenEventIds));
            } else {
                dispatch(eventsOp.setIsNewEventsShown(userLatestEventsIds));
            }
        }
    }, [userLatestEvents]);

    return (
        <NavStyled>
            <Box p={0} m={0} pb={16}>
                {navigationList.map((navItem) => {
                    return navItem.checkForAuth && !isAuthenticated ? (
                        <Box
                            key={navItem.id}
                            className="nav-link cursor-pointer"
                            onClick={(e) => handleNavItemClick(true, navItem.href, e)}
                            id={createIdAttribute(navItem.href)}
                            data-testid={createIdAttribute(navItem.href)}
                        >
                            <NavItem
                                navItem={navItem}
                                theme={theme}
                                extraInfoDisabledForEvents={!isEventPageLive}
                            />
                        </Box>
                    ) : (
                        <NavLink
                            exact
                            // isActive={(match, location) => {
                            //     const pathStrings = location.pathname.split('/');

                            //     console.log(match, location);

                            //     if (match) {
                            //         return true;
                            //     } else if (`/${pathStrings[1]}` === navItem.href) {
                            //         return true;
                            //     } else {
                            //         return false;
                            //     }
                            // }}
                            key={navItem.id}
                            to={navItem.href}
                            onClick={(e) => handleNavItemClick(false, navItem.href, e)}
                            className={() => {
                                const x = 'nav-link cursor-pointer';
                                const pathStrings = pathname.split('/');

                                if (`/${pathStrings[1]}` === navItem.href) {
                                    return x + ' active-link';
                                }

                                return x;
                            }}
                            id={createIdAttribute(navItem.href)}
                            data-testid={createIdAttribute(navItem.href)}
                        >
                            <NavItem
                                navItem={navItem}
                                theme={theme}
                                extraInfoDisabledForEvents={!isEventPageLive}
                            />
                        </NavLink>
                    );
                })}
            </Box>
        </NavStyled>
    );
};

NavItem.propTypes = {
    navItem: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    extraInfoDisabledForEvents: PropTypes.bool,
};

Nav.propTypes = {
    navigationList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            fallBackName: PropTypes.string,
            href: PropTypes.string,
            iconClassName: PropTypes.string,
            checkForAuth: PropTypes.bool,
        }),
    ).isRequired,
    setOpenLeftSidebar: PropTypes.func,
    onModalOpen: PropTypes.func,
    dropScrollPosition: PropTypes.func,
};

export default Nav;
