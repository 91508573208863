import CookieStore from 'store/storages/cookieStorage';

import * as Constants from '../constants';

export const saveSessionToken = (token: string) => {
    CookieStore.write(Constants.SESSION_ID, token);
};

export const getSessionToken = (): string => {
    return CookieStore.read(Constants.SESSION_ID) || '';
};

export const removeSessionToken = () => {
    CookieStore.remove(Constants.SESSION_ID);
};
