import styled from 'styled-components';

export const LeftSidebarFooterStyled = styled.div(
    ({
        theme: {
            palette,
            typography,
            palette: { mode },
            helpers: { transitions },
        },
    }) => {
        const isDark = mode === 'dark';

        return {
            p: {
                transition: transitions('color'),
            },

            img: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },

            'a:active > p, a:hover > p': {
                color: isDark ? palette.common.white : palette.lightMode.grey[500],
            },

            '.WP-LeftSidebar-link': {
                color: isDark ? '#8A8F99' : palette.lightMode.grey[900],
                transition: transitions('color'),
                fontWeight: typography.weight.regular,
                fontSize: typography.size.p14.fontSize,
                lineHeight: typography.size.p14.lineHeight,

                '&:active, &:hover': {
                    color: isDark ? palette.common.white : palette.lightMode.grey[500],
                },
            },

            '.social-links': {
                marginBottom: 32,

                a: {
                    marginRight: 20,
                    color: '#8a8f99',
                    transition: transitions('color'),

                    '&:active, &:hover': {
                        color: isDark ? palette.common.white : '#45474D',
                    },

                    '&:last-child': {
                        marginRight: 0,
                    },
                },
            },

            '.discord-button': {
                padding: '8px 16px',
                textAlign: 'left',
                textTransform: 'none',

                '& i': {
                    marginRight: '16px',
                },
            },

            '.WP-LeftSideBarFooter-download-link': {
                img: {
                    objectFit: 'cover',
                },

                '&.ios-market-link': {
                    img: {
                        marginLeft: -6,
                        width: '75%',
                    },
                },

                '&.android-market-link': {
                    img: {
                        width: '90%',
                    },
                },
            },
        };
    },
);
