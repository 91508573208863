import React from 'react';
import { useSelector } from 'react-redux';
import { type IPalette } from 'src/@types';
import { AUTOMATION_TESTING_IDS } from 'src/constants';
import { isTimeStampPassed } from 'src/helpers/dateTimeHelpers';
import { eventsSel } from 'src/store/events';
import RemainingTimeLive from 'src/views/components/OfferBanner/components/RemainingTime/RemainingTimeLive';

import { Box } from '../../../../../components/ui-components/Box/Box';
import { Typography } from '../../../../../components/ui-components/Typography/Typography';

interface IProps {
    palette: IPalette;
    extraInfoDisabled: boolean;
}

const NavItemEventsLabel: React.FC<IProps> = ({ palette, extraInfoDisabled = false }) => {
    const remainingTime = useSelector(eventsSel.eventsSidebarTimeStampSelector)?.time;
    const newEventsIds = useSelector(eventsSel.isNewEventsShownSelector);
    const claimableEventsCount = useSelector(
        eventsSel.eventsSidebarTimeStampSelector,
    ).countOfClaimAvailableEvents;

    const isIndicatorEnabled = !!newEventsIds.length;

    const claimableEventsCountGenerator = (claimableEventsCount: number) => {
        return (
            <>
                {claimableEventsCount > 0 ? (
                    <Box display="flex">
                        <Box
                            className="WP-Nav-ClaimableEventsCount"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width={18}
                            height={18}
                            ml={1.5}
                            mt={0}
                        >
                            <Box component={Typography} variant="p10" fWeight="bold">
                                {claimableEventsCount}
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    ''
                )}
            </>
        );
    };

    if (extraInfoDisabled) {
        return null;
    }

    if (!remainingTime || isTimeStampPassed(remainingTime)) {
        return claimableEventsCountGenerator(claimableEventsCount);
    }

    return (
        <>
            {isIndicatorEnabled && (
                <Box display="flex">
                    <Box
                        className="WP-Nav-notificationIndicator"
                        width={6}
                        height={6}
                        bgcolor={palette.red[400]}
                        ml={0.5}
                        mt={-1.5}
                    />
                </Box>
            )}

            {claimableEventsCountGenerator(claimableEventsCount)}

            <Box display="flex" alignItems="center" width={1} mt={0.5}>
                <Box bgcolor="#CA4949" px={2} borderRadius="20px">
                    <Box
                        component={Typography}
                        variant="p12"
                        fWeight="medium"
                        color={palette.common.white}
                        textTransform="uppercase"
                        id={AUTOMATION_TESTING_IDS.eventsNavTimer}
                    >
                        <RemainingTimeLive date={remainingTime} short dateType="secondsTimeStamp" />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default NavItemEventsLabel;
