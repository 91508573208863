import React from 'react';
import clsx from 'clsx';

import { BackdropStyled } from './Backdrop.styled';

type BackdropProps = {
    open: boolean;
    color?: string;
    zIndex: number;
    className?: string;
    onClick: () => void;
    children?: React.ReactNode;
};

export const Backdrop: React.FC<BackdropProps> = ({
    open = false,
    color = '',
    zIndex,
    className = '',
    children,
    onClick,
}) => {
    return open ? (
        <BackdropStyled color={color} zIndex={zIndex}>
            <div className="overlay" onClick={onClick} />
            {children && <div className={clsx('backdrop-content', className)}>{children}</div>}
        </BackdropStyled>
    ) : null;
};
