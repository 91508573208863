/* eslint-disable no-undef */
import axios from 'axios';
import { type TAuthErrorSlugs, type THandleAuthError, type THttpError } from 'src/@types';
import { getSessionToken } from 'src/bridge';
import { scopelyId } from 'src/configs/web-sdk.config';
import { API_URL, ERROR_HANDLER_MODAL } from 'src/constants';
import { setSessionStorage } from 'src/services';
import { store } from 'src/store/configureStore';
import { globalOp, isAuthenticated } from 'src/store/global/operations';

class ErrorResponse {
    response = {
        config: {},
        data: {
            message: '',
            slug: 'unauthorized-user-not-found',
            statusCode: 401,
            timestamp: '',
        },
        headers: {},
        request: {},
        status: 401,
        statusText: 'Unauthorized',
        actualError: null,
    };

    constructor(err: any) {
        this.response.actualError = err;
    }
}

const handleAuthError: THandleAuthError = (errorMessage) => {
    switch (errorMessage) {
        case 'unauthorized-user-banned':
            setSessionStorage(ERROR_HANDLER_MODAL, {
                open: true,
                className: 'WP-Modal-Account-Suspended',
                type: 'unauthorized-user-banned',
                title: 'Account Suspended',
                description:
                    'Commander, your account has been suspended. Please contact customer support for more details.',
                titleTranslationKey: 'common_error_modal_unauthorized_user_banned_title_text',
                descriptionTranslationKey: 'common_error_modal_unauthorized_user_banned_text',
            });
            return true;
        case 'unauthorized-user-not-found':
            setSessionStorage(ERROR_HANDLER_MODAL, {
                open: true,
                type: 'unauthorized-user-not-found',
                className: 'WP-Modal-Not-Connected-Account',
                title: 'No Connected Account',
                description:
                    'common_Play Star Trek: Fleet Command on mobile or PC and link your account to a ScopelyID to gain access to the web store and its exclusive rewards!',
                titleTranslationKey: 'common_error_modal_unauthorized_user_not_found_title_text',
                descriptionTranslationKey: 'common_error_modal_unauthorized_user_not_found_text',
            });
            return true;
        case 'unauthorized-user-is-archived':
            setSessionStorage(ERROR_HANDLER_MODAL, {
                open: true,
                type: 'unauthorized-user-is-archived',
                className: 'WP-Modal-Account-Archived',
                title: 'Please Log In',
                description:
                    'Commander, we need you to log into the game before you can access the web version of the store.',
                titleTranslationKey: 'common_error_modal_unauthorized_user_is_archived_title_text',
                descriptionTranslationKey: 'common_error_modal_unauthorized_user_is_archived_text',
            });
            return true;
        case 'unauthorized-user-is-deactivated':
            setSessionStorage(ERROR_HANDLER_MODAL, {
                open: true,
                type: 'unauthorized-user-banned',
                className: 'WP-Modal-Account-Suspended',
                title: 'Account Not Found',
                description: 'Please contact customer support for more details.',
                titleTranslationKey:
                    'common_error_modal_unauthorized_user_is-deactivated_title_text',
                descriptionTranslationKey:
                    'common_error_modal_unauthorized_user_is-deactivated_text',
            });
            return true;
        default:
            return false;
    }
};

export const responseInterceptorOnError = (error: THttpError | undefined) => {
    if (!error) {
        return Promise.reject(new ErrorResponse(error));
    }

    if (axios.isCancel(error)) {
        return Promise.reject(new ErrorResponse(error));
    }

    if (!error.response) {
        return Promise.reject(new ErrorResponse(error));
    }

    const { slug, statusCode } = error.response.data;

    if (statusCode === 503) {
        if (store) {
            if (slug === 'game-in-maintenance') {
                store.dispatch(
                    globalOp.setErrorModal({
                        open: true,
                        type: 'game-in-maintenance',
                        className: 'WP-Modal-Server-in-Maintenance',
                        title: 'Under Maintenance',
                        description:
                            'The game is currently undergoing maintenance. Please check back soon.',
                        titleTranslationKey: 'common_error_modal_maintenance_title_text',
                        descriptionTranslationKey:
                            'common_error_modal_maintenance_description_text',
                    }),
                );
            } else {
                store.dispatch(globalOp.getConfigs());
            }
        }
    }

    if (statusCode === 401) {
        let isUnhandled = false;
        const authenticated = isAuthenticated();

        const isHandled = handleAuthError(slug as TAuthErrorSlugs);

        if (isHandled) {
            scopelyId.logout();
        } else {
            isUnhandled = true;
        }

        if (authenticated && store) {
            store.dispatch(globalOp.logout());

            if (isUnhandled) {
                store.dispatch(
                    globalOp.handleAuthenticatedModalToggle({
                        open: true,
                        type: 'storeAuth',
                    }),
                );
            }
        }
    }

    return Promise.reject(error);
};

export const checkForMaintenance = () => {
    window
        .fetch(`${API_URL}/maintenance`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${getSessionToken() || ''}`,
            },
        })
        .then((response) => {
            if (!response.ok) {
                return;
            }
            return response.json();
        })
        .then((data: { isMaintenance: boolean }) => {
            if (data.isMaintenance) {
                setSessionStorage(ERROR_HANDLER_MODAL, {
                    open: true,
                    type: 'game-in-maintenance',
                    className: 'WP-Modal-Server-in-Maintenance',
                    title: 'Under Maintenance',
                    description:
                        'The game is currently undergoing maintenance. Please check back soon.',
                    titleTranslationKey: 'common_error_modal_maintenance_title_text',
                    descriptionTranslationKey: 'common_error_modal_maintenance_description_text',
                });
                window.location.href = '/';
                store.dispatch(globalOp.logout());
            }
        })
        .catch(() => {
            // todo handle error
        });
};
