import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Hidden } from '@mui/material';
import { type IPalette } from 'src/@types';

import avatar from '../../../assets/images/avatar.png';
import mobileLogo from '../../../assets/images/mobile-logo.png';
import { AUTOMATION_TESTING_IDS } from '../../../constants';
import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';
import { useIsMounted, useIsomorphicLayoutEffect } from '../../../hooks';
import { globalSel } from '../../../store/global';
import { offerSel } from '../../../store/offer';
import AppLogo from '../../components/AppLogo/AppLogo';
import LoginWithScopely from '../../components/LoginWithScopely/LoginWithScopely';
import { Box } from '../../components/ui-components/Box/Box';
import { Icon } from '../../components/ui-components/Icon/Icon';
import { HeaderStyled } from './Header.styled';
import PlayerProfileInfo from './PlayerProfileInfo/PlayerProfileInfo';

interface IBoxWrapperProps {
    palette: IPalette;
    isDark: boolean;
    onClick: () => void;
    id: string;
    children: React.ReactNode;
    [key: string]: any;
}

interface IProps {
    setOpenLeftSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenRightSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoxWrapper: React.FC<IBoxWrapperProps> = ({
    palette,
    isDark,
    onClick,
    id,
    children,
    ...otherProps
}) => {
    return (
        <Box
            display="flex"
            border={1}
            borderColor={isDark ? palette.white.transparent20 : palette.common.white}
            bgcolor={isDark ? 'transparent' : palette.common.white}
            color={isDark ? '#C7CCD4' : palette.grey.light[700]}
            onClick={onClick}
            id={id}
            className="cursor-pointer"
            {...otherProps}
        >
            {children}
        </Box>
    );
};

const Header: React.FC<IProps> = ({ setOpenLeftSidebar, setOpenRightSidebar }) => {
    const theme = useSelector(globalSel.themeSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const offerPurchasingSelector = useSelector(offerSel.offerPurchasingSelector);
    const headerRef = useRef<HTMLDivElement>(null);
    const isMounted = useIsMounted();
    const isDark = theme.palette.mode === 'dark';

    const headerRightIconStyles = useMemo(() => {
        if (authenticated) {
            return {
                src: avatar,
                alt: 'avatar',
            };
        }

        return {
            src: mobileLogo,
            alt: 'mobile-logo',
        };
    }, [authenticated]);

    const handleAppLogoClick = () => {
        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'navigation',
            screen_category: 'navigation',
            interaction_object: 'navigation_button',
        });
    };

    const handleRightSideBarClick = () => {
        if (offerPurchasingSelector) {
            return false;
        }

        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'right_side_bar',
            screen_category: 'sidebar',
            interaction_object: 'right_side_bar_button',
        });

        setOpenRightSidebar(true);
    };

    const handleLeftSideBarClick = () => {
        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'left_side_bar',
            screen_category: 'sidebar',
            interaction_object: 'left_side_bar_button',
        });

        setOpenLeftSidebar(true);
    };

    const changeBackground = () => {
        const rgb = isDark ? '23,24,26' : '250, 252, 255';
        const multiplier = (1 / 120) * window.scrollY;

        if (!headerRef.current) return;

        if (!isDark && multiplier > 0.8) {
            return (headerRef.current.style.backgroundColor = `rgba(${rgb},0.8)`);
        }

        headerRef.current.style.backgroundColor = `rgba(${rgb},${(1 / 120) * window.scrollY})`;
    };

    useEffect(() => {
        if (isMounted) {
            changeBackground();
        }
    }, [theme.palette.mode]);

    useIsomorphicLayoutEffect(() => {
        window.addEventListener('scroll', changeBackground);

        return () => window.removeEventListener('scroll', changeBackground);
    }, [isDark]);

    return (
        <HeaderStyled ref={headerRef}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent={['space-between', 'space-between', 'flex-end']}
                height={1}
            >
                <Hidden mdUp>
                    <BoxWrapper
                        palette={theme.palette}
                        isDark={isDark}
                        onClick={handleLeftSideBarClick}
                        id={AUTOMATION_TESTING_IDS.leftSideBarButton}
                        px={2}
                        py={2.5}
                    >
                        <Box
                            component={Icon}
                            className="icon-burger"
                            color={isDark ? '#C7CCD4' : theme.palette.lightMode.grey[700]}
                            size={14}
                        />
                    </BoxWrapper>
                </Hidden>

                <Hidden mdUp>
                    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                        <AppLogo isLeftSideBar={false} onClick={handleAppLogoClick} />
                    </Box>
                </Hidden>

                <Hidden mdUp>
                    <BoxWrapper
                        palette={theme.palette}
                        isDark={false}
                        onClick={handleRightSideBarClick}
                        id={AUTOMATION_TESTING_IDS.rightSideBarButton}
                        p={2}
                    >
                        <Box
                            component="img"
                            width={18}
                            height={18}
                            src={headerRightIconStyles.src}
                            alt={headerRightIconStyles.alt}
                            className="avatar"
                        />
                    </BoxWrapper>
                </Hidden>

                <Hidden mdDown>
                    <Box display="flex" alignItems="center" pl={3}>
                        {authenticated && currentUser ? (
                            <PlayerProfileInfo />
                        ) : (
                            <LoginWithScopely />
                        )}
                    </Box>
                </Hidden>
            </Box>
        </HeaderStyled>
    );
};

export default Header;
