import { type Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type TJsonKey } from 'src/@types';
import { hexToRgba } from 'src/helpers/styleHelpers';

import Galaxy from '../../../assets/images/Galaxy.png';
import LeftImage from '../../../assets/images/Officers_1.png';
import RightImage from '../../../assets/images/Officers_2.png';
import RedBg from '../../../assets/images/RedBackground.png';

// TODO check possibility to create a common interface for all styled components
interface IStyledProps {
    theme: Theme;
    fullPage?: boolean;
}

export const MaintenanceStyled = styled('div')<IStyledProps>(({
    theme: {
        breakpoints,
        palette,
        palette: { mode },
    },
    fullPage,
}: IStyledProps) => {
    const obj: TJsonKey<string> = {
        background:
            mode === 'dark'
                ? 'linear-gradient(180deg, rgba(23, 27, 38, 0) 50%, #17181a 100%)'
                : `linear-gradient(180deg, rgba(235, 239, 247, 0) 50%, ${palette.lightMode.grey[200]} 100%)`,
    };

    if (fullPage) {
        obj.maxWidth = '100vw';
        obj.minHeight = '100vh';
    }

    return {
        ...obj,

        '.WP-BorderedContent-box': {
            background: mode === 'dark' ? hexToRgba('#1F2122', 0.2) : palette.white.transparent20,
        },

        '.WP-Maintenance': {
            '&-title': {
                ...(mode === 'light' && {
                    textShadow: `
                            1px 1px 0 ${palette.white.transparent20},
                            1px -1px 0 ${palette.white.transparent20},
                            -1px 1px 0 ${palette.white.transparent20},
                            -1px -1px 0 ${palette.white.transparent20}
                            `,
                }),

                [breakpoints.up('md')]: {
                    fontSize: '36px',
                    lineHeight: '42px',
                },

                [breakpoints.up('xl')]: {
                    fontSize: '40px',
                    lineHeight: '46px',
                },
            },

            '&-officers_1': {
                backgroundImage: `url(${LeftImage})`,
                backgroundPosition: 'center left',
            },

            '&-officers_2': {
                backgroundImage: `url(${RightImage})`,
                backgroundPosition: 'center right',
            },

            '&-officers_1, &-officers_2': {
                display: 'none',
                backgroundSize: 'auto 50%',
                backgroundRepeat: 'no-repeat',
                zIndex: 2,

                [breakpoints.up('md')]: {
                    backgroundSize: 'auto 60%',
                    display: 'block',
                },

                [breakpoints.up('xl')]: {
                    backgroundSize: 'auto 70%',
                },
            },

            '&-red-bg, &-galaxy': {
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            },

            '&-red-bg': {
                backgroundImage: `url(${RedBg})`,
                backgroundSize: '870px 500px',
                mixBlendMode: 'lighten',
                zIndex: 1,
            },

            '&-galaxy': {
                backgroundImage: `url(${Galaxy})`,
                backgroundSize: 'cover',
                mixBlendMode: 'screen',
            },
        },
    };
});
