export const offerCard = (palette) => {
    const isDarkMode = palette.mode === 'dark';

    return {
        skeleton: {
            bgcolor: isDarkMode ? palette.grey.dark[700] : palette.white.transparent50,
            contentBg: isDarkMode ? palette.grey.dark[700] : palette.white.transparent50,
        },
    };
};
