/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react';

export const interval =
    (delay = 0) =>
    (callback: () => void) =>
        useEffect(() => {
            const id = setInterval(callback, delay);

            return () => clearInterval(id);
        }, [callback]);

export const use1Second = interval(1e3);

export const useAccurateTimer = ({
    seconds: initialSeconds = 10,
    running: initiallyRunning = false,
} = {}) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [running, setRunning] = useState(initiallyRunning);
    const tick = useCallback(
        () => (running ? setSeconds((seconds) => seconds - 1) : undefined),
        [running],
    );
    const startOrContinue = () => !running && setRunning(true);
    const pause = () => setRunning(false);
    const reset = (second: number) => setSeconds(second);
    const stop = () => {
        pause();
        reset(initialSeconds);
    };

    use1Second(tick);

    return { pause, reset, running, seconds, startOrContinue, stop };
};
