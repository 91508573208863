/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';

const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler =
            typeof value !== 'undefined'
                ? setTimeout(() => {
                      setDebouncedValue(value);
                  }, delay)
                : setTimeout(() => {
                      setDebouncedValue(value);
                  });

        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line
    }, [value]);

    return debouncedValue;
};

/**
 * Returns a debounced function that delays calling the original function until a certain amount of time has passed
 * without further calls.
 * This function is useful for optimizing performance when working with events that are triggered frequently,
 * such as input or scroll events.
 * It takes a callback function and a delay time as arguments and returns a new debounced function that can be called
 * with arbitrary arguments.
 * The debounced function can be called multiple times, but the original function will only be executed once per
 * delay period, with the last set of arguments provided.
 */
const useDebounceFunction = (callback: Function, delay: number) => {
    const timeRef = useRef(null);
    let time: ReturnType<typeof setTimeout> | null = timeRef?.current;

    useEffect(() => {
        return () => {
            if (time) clearTimeout(time);
        };
    }, [time]);

    return (...args: any[]) => {
        if (time) clearTimeout(time);

        time = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

export { useDebounce, useDebounceFunction };
