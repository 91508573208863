import styled from 'styled-components';

export const LeftSidebarStyled = styled.aside`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 244px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: ${({ isMobileDropdown }) => (isMobileDropdown ? '' : 'auto')};
    z-index: ${({ theme }) => theme.zIndex.drawer};
    border: 0;
    border-right: 1px solid;
    border-image-slice: 1;

    ${({
        theme: {
            palette,
            helpers,
            palette: { mode },
        },
    }) => ({
        borderImageSource:
            mode === 'dark'
                ? `linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0.2) 0%,
                    rgba(255, 255, 255, 0) 100%
                )`
                : `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)`,
        backgroundColor: mode === 'dark' ? 'rgba(34, 36, 38, 0.6)' : palette.lightMode.grey[100],
        transition: helpers.transitions('transform'),

        '& hr': {
            width: 'calc(100% - 40px)',
            border: '1px solid',
            borderColor: mode === 'dark' ? palette.grey.dark[200] : '#E9EBF0',
            display: 'block',
            margin: '0 auto',
        },
    })};

    scrollbar-width: none;
    overflow-x: ${({ isMobileDropdown }) => (isMobileDropdown ? '' : 'hidden')};

    ::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        ${({
            theme: {
                palette,
                palette: { mode },
            },
        }) => ({
            backgroundColor: mode === 'dark' ? palette.grey.dark[600] : palette.lightMode.grey[100],
        })};

        transform: translateX(-244px);

        &.show {
            transform: translateX(0);
        }
    }
`;
