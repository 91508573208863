import { type TNotificationStatus } from '../@types/store/notifications';
import { service } from '../configs/web-sdk.config';
import { NOTIFICATIONS_STATES } from '../constants/notifications.constants';

export const isNotificationEnabledFromBrowser = () => {
    return service?.getNotificationEnabled();
};

export const isSystemHasPermission = () => {
    // web-sdk package returns 'unavailable' value when notification service fails
    return service?.getSystemPermission() !== 'unavailable';
};

export const isNotificationsBlockedByBrowser = (notificationsState: TNotificationStatus) => {
    return (
        notificationsState === NOTIFICATIONS_STATES.DEFAULT ||
        notificationsState === NOTIFICATIONS_STATES.BLOCKED_FROM_BROWSER
    );
};

export const isNotificationEnabled = (state: TNotificationStatus) => {
    return state === NOTIFICATIONS_STATES.ENABLED;
};
