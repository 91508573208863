/* eslint-disable no-undef */
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store/configureStore';

import { googleAnalyticsEvent } from '../helpers/GA4Helper';
import { offerOp, offerSel } from '../store/offer';

export function useXsollaLoadHook() {
    const processingOffer = useSelector(offerSel.processingBundleSelector);
    const processingBundleBeforeReset = useRef<any>(null); // TODO

    const { setOfferPurchasing, setProcessingBundle } = offerOp;
    const dispatch = useAppDispatch();

    const handleXsollaLoaded = () => {
        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_DONE,
            (event, res: { [x: string]: any }) => {
                if (res?.paymentInfo?.status === 'done') {
                    if (processingBundleBeforeReset.current?.metadata?.secondary_bundle_id) {
                        googleAnalyticsEvent.event({
                            action: `web_iap_store_${processingBundleBeforeReset.current.bundle_id}_monthly_pack_purchased`,
                            event_category: 'web_iap_store',
                            event_label: 'web_iap_store_monthly_pack_offer_purchased',
                        });
                    } else {
                        googleAnalyticsEvent.event({
                            action: `web_iap_store_${processingBundleBeforeReset.current.bundle_id}_purchased`,
                            event_category: 'web_iap_store',
                            event_label: 'web_iap_store_offer_purchased',
                        });
                    }

                    dispatch(offerOp.setPaymentStatus('done'));
                }
            },
        );

        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_TROUBLED,
            function () {
                //TODO add error case handler
                // titanAnalyticsEvents.xsolla_payment({
                //     error: 'status troubled',
                //     amount_us: offer.price,
                //     local_currency_type: offer.currency,
                //     store_sku: settings.data?.xsolla_store_id,
                // });
            },
        );

        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, () => {
            requestAnimationFrame(() => {
                dispatch(setProcessingBundle(null));
                dispatch(setOfferPurchasing(false));
            });
        });
    };

    useEffect(() => {
        processingBundleBeforeReset.current = processingOffer;
    }, [processingOffer]);

    useEffect(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//cdn.xsolla.net/embed/paystation/1.0.7/widget.min.js';
        s.onload = handleXsollaLoaded;
        const head = document.getElementsByTagName('head')[0];
        head?.appendChild(s);
        // eslint-disable-next-line
    }, []);
}
