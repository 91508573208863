import { styled } from '@mui/material';

interface IStyledProps {
    disabled?: boolean;
}

export const SwitchContainerStyled = styled('div')(({ disabled }: IStyledProps) => ({
    opacity: disabled ? 0.4 : 1,

    '.WP-SwitchContainer-formControlLabel-wrapper': {
        '.MuiSwitch-root': {
            width: 44,
            height: 26,
            borderRadius: '30px',
        },

        '.MuiButtonBase-root.MuiSwitch-switchBase': {
            left: -8,
            bottom: 0,
        },

        '.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart': {
            marginRight: 0,
        },
    },
}));
