import { getUserSelectedLanguage } from 'src/helpers/webEnvHelpers';

import { projectPages } from '../../constants/project.constants';
import { type ITranslationsState } from './../../@types/store/translation';

const handleGetUserSelectedLanguage = () => {
    if (!document.cookie) {
        return 'en';
    }

    return getUserSelectedLanguage();
};

export const initialTranslationsState: ITranslationsState = {
    selectedLanguage: handleGetUserSelectedLanguage(),
    translations: {},
    loading: Object.values(projectPages).reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: true,
        }),
        {
            common: true,
        },
    ),
};
