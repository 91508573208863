import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActions } from '@mui/material';

import { INTEGRATION_TESTING_IDS } from '../../../../constants';
import { globalSel } from '../../../../store/global';
import { Box } from '../../ui-components/Box/Box';
import { Button } from '../../ui-components/Button/Button';
import { Typography } from '../../ui-components/Typography/Typography';

interface IProps {
    open: boolean;
    title: string;
    description: string;
    declineText: string;
    declineAction: () => void;
    confirmText: string;
    confirmAction: () => void;
    onClose: () => void;
}

const NotSupportedPlatformModal: React.FC<IProps> = ({
    open = false,
    title,
    description,
    declineText,
    declineAction = () => {},
    confirmText,
    confirmAction = () => {},
    onClose = () => {},
}) => {
    const {
        palette,
        palette: { mode },
    } = useSelector(globalSel.themeSelector);

    const colors = {
        titleColor: mode === 'dark' ? '#C3C6CC' : palette.lightMode.grey[900],
        descriptionColor: mode === 'dark' ? palette.grey.light[700] : palette.lightMode.grey[700],
        bgColor: mode === 'dark' ? '#1f2123' : '#F8F9FC',
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="not-supported-platform"
            aria-describedby="platform-doesn't-support-the-game"
            PaperProps={{
                style: {
                    backgroundColor: colors.bgColor,
                    padding: '20px',
                },
            }}
        >
            <Typography variant="p16" color={colors.titleColor}>
                <span data-testid={INTEGRATION_TESTING_IDS.notSupportedModalTitle}>{title}</span>
            </Typography>

            <Box component={Typography} variant="p12" color={colors.descriptionColor} mt={3} mb={5}>
                <span data-testid={INTEGRATION_TESTING_IDS.notSupportedModalDescription}>
                    {description}
                </span>
            </Box>

            <DialogActions>
                <Button
                    onClick={declineAction}
                    color="grey-1"
                    size="medium"
                    testId={INTEGRATION_TESTING_IDS.notSupportedModalDeclineButton}
                >
                    {declineText}
                </Button>

                <Button
                    onClick={confirmAction}
                    color="primary"
                    size="medium"
                    testId={INTEGRATION_TESTING_IDS.notSupportedModalConfirmButton}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotSupportedPlatformModal;
