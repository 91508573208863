import { service } from '../../configs/web-sdk.config';
import { NOTIFICATIONS_STATES } from '../../constants/notifications.constants';
import { fetch } from '../../services';
import globalSlice from '../global';
import { notificationsSlice } from './notificationsSlice';

const setNotificationsState = (state, accountId) => {
    const { setNotificationsState } = notificationsSlice.actions;
    const { setSnackbar } = globalSlice.actions;

    return async (dispatch) => {
        try {
            await fetch({
                endPoint: '/user/notification-mode',
                method: 'PUT',
                privateRequest: true,
                body: { isNotificationAllowed: state === NOTIFICATIONS_STATES.ENABLED },
            });

            if (state === NOTIFICATIONS_STATES.ENABLED) {
                service?.subscribeToPushNotifications(accountId).then((event) => {
                    if (event) {
                        dispatch(setNotificationsState(NOTIFICATIONS_STATES.ENABLED));
                    } else {
                        dispatch(setNotificationsState(NOTIFICATIONS_STATES.BLOCKED_FROM_BROWSER));
                    }
                });
            } else {
                await service?.unsubscribeToPushNotifications();
                dispatch(setNotificationsState(NOTIFICATIONS_STATES.BLOCKED_FROM_USER));
            }
        } catch (e) {
            dispatch(
                setSnackbar({
                    open: true,
                    message: 'Oops! There was an error',
                    messageTranslationKey: 'common_snackbar_oops_error_text',
                    variant: 'error',
                }),
            );
        }
    };
};

export const notificationsOp = {
    setNotificationsState,
};
