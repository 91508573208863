import styled from 'styled-components';

export const MainStyled = styled.main`
    overflow-x: ${({ hideOverflow }) => (hideOverflow ? 'hidden' : 'inherit')};

    .WP-Main-background {
        img {
            position: relative;
            object-fit: cover;
        }

        ${({
            theme: {
                palette: { mode },
            },
        }) =>
            mode === 'light' && {
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `linear-gradient(
                    90deg,
                    rgba(235, 239, 247, 0.5) 18.81%,
                    rgba(255, 255, 255, 0) 65.32%
                ),
                linear-gradient(180deg, rgba(235, 239, 247, 0.5) 25%, rgba(235, 239, 247, 1) 95%)`,
                },
            }}
    }
`;
