import { type INotificationsStatusObject } from '../@types/store/notifications';

export const NOTIFICATIONS_STATES: INotificationsStatusObject = {
    DEFAULT: 'default',
    ENABLED: 'enabled',
    BLOCKED_FROM_CONTROLLER: 'blocked-from-controller',
    BLOCKED_FROM_BROWSER: 'blocked-from-browser',
    BLOCKED_FROM_USER: 'blocked-from-user',
    UNAVAILABLE: 'unavailable',
};
