/* eslint-disable no-undef */
export const getBrowserType = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    const browsers = {
        chromeiOS: /criOS/i.test(userAgent) && /iphone|ipod|ipad/i.test(userAgent),
        chrome: /chrome/i.test(userAgent),
        safari: /safari/.test(userAgent) && /apple/.test(userAgent),
        firefox: /firefox/.test(userAgent),
        opera: /opera/.test(userAgent),
        msie: /msie/.test(userAgent) && !/opera/.test(userAgent),
        mozilla: /mozilla/.test(userAgent) && !/compatible/.test(userAgent),
    };

    for (const key in browsers) {
        if (browsers[key]) {
            return key;
        }
    }

    return null;
};

export const getiOSVersion = () => {
    const n = navigator?.userAgent || navigator?.platform || 'unknown';

    if (/iP(hone|od|ad)/.test(n)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [
            parseInt(v?.[1] || '0', 10),
            parseInt(v?.[2] || '0', 10),
            parseInt(v?.[3] || '0', 10),
        ];
    }

    return [0];
};

const isIOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const touchScreen = 'ontouchstart' in document.documentElement;

    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent) || /SamsungBrowser/i.test(userAgent)) {
        return 'Android';
    }

    if (isIOS()) {
        return 'iOS';
    }

    if (touchScreen) {
        return 'touchScreen';
    }

    return 'unknown';
};

export const getDesktopOperatingSystem = () => {
    // eslint-disable-next-line no-undef
    const platform = window.navigator.platform;

    const isMac = platform.toUpperCase().indexOf('MAC') >= 0;
    const isWindows = platform.toUpperCase().indexOf('WIN') >= 0;
    const isLinux = !isMac && !isWindows ? /Linux/.test(platform) : false;

    if (isMac) {
        return 'MacOS';
    }

    if (isWindows) {
        return 'Windows';
    }

    if (isLinux) {
        return 'Linux';
    }

    return 'unknown';
};

export const checkBrowser = {
    _userAgent: navigator.userAgent.toLowerCase(),
    _vendor: navigator.vendor.toLowerCase(),

    isSamsungInternet: function () {
        return this._userAgent.match(
            /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
        );
    },
    isSafari: function () {
        // Return true for iOS all browsers
        // see https://allthingsd.com/20120628/googles-chrome-for-ios-is-more-like-a-chrome-plated-apple/
        return /^((?!chrome|android).)*safari/i.test(this._userAgent);
    },
    isNotiOSSafariBrowser: function () {
        return (
            this._vendor.indexOf('apple') > -1 &&
            this._userAgent.indexOf('crios') === -1 &&
            this._userAgent.indexOf('fxios') === -1
        );
    },
};
