import { type ILightPalette } from 'src/@types';
import { hexToRgba } from 'src/helpers/styleHelpers';

import { globalPalette } from './globalPalette';

const grey900 = '#5C5F66';
const grey800 = '#6F727B';
const grey700 = '#8A8E99';
const grey600 = '#ADB2BF';
const grey500 = '#C2C5CC';
const grey400 = '#E7E9ED';
const grey300 = '#E9ECF2';
const grey200 = '#EBEFF7';
const grey100 = '#FAFCFF';

const purple800 = '#3F3E53';
const purple600 = '#525167';
const purple400 = '#6D6B85';

const green800 = '#3A5459';
const green600 = '#537177';
const green400 = '#698990';

export const lightPalette: ILightPalette = {
    primary: {
        main: globalPalette.yellow[600],
    },
    secondary: {
        main: globalPalette.green[600],
    },
    solid: {
        main: globalPalette.grey.dark[400],
        hover: globalPalette.grey.dark[200],
    },
    background: {
        main: globalPalette.grey.dark[700],
        50: globalPalette.grey.light[100],
        100: globalPalette.grey.light[400],
        200: globalPalette.grey.light[800],
        300: globalPalette.grey.dark[300],
        400: globalPalette.grey.dark[400],
        500: globalPalette.grey.dark[600],
        600: globalPalette.grey.dark[800],
        700: globalPalette.grey.dark[700],
        800: globalPalette.grey.dark[800],
    },
    border: {
        50: globalPalette.grey.light[300],
        100: globalPalette.grey.light[800],
        T10FFF: hexToRgba('#fff', 0.1),
    },
    text: {
        main: globalPalette.grey.light[100],
        50: globalPalette.grey.light[200],
        100: globalPalette.grey.light[300],
        200: globalPalette.grey.light[400],
        300: globalPalette.grey.light[500],
        400: globalPalette.grey.light[600],
        500: globalPalette.grey.light[700],
        600: globalPalette.grey.light[800],
        700: globalPalette.grey.dark[200],
        800: globalPalette.grey.dark[300],
        900: globalPalette.grey.dark[400],
    },
    lightMode: {
        grey: {
            900: grey900,
            800: grey800,
            700: grey700,
            600: grey600,
            500: grey500,
            400: grey400,
            300: grey300,
            200: grey200,
            100: grey100,
        },
        purple: {
            800: purple800,
            600: purple600,
            400: purple400,
        },
        green: {
            800: green800,
            600: green600,
            400: green400,
        },
    },
};
