import styled from 'styled-components';

interface ProgressBarStyledProps {
    progress: number;
    height: number;
    color: string;
}

export const ProgressBarStyled = styled.div<ProgressBarStyledProps>`
  .WP-ProgressBar-filler {
    width: ${({ progress }) => progress}%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
    position: relative;
    height: ${({ height }) => height + 'px' || '8px'};
    background: ${({ color }) => color || 'red'};

    &.dashed {
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        animation: stripes-move 1.4s linear infinite;
        border: 2px solid hsla(0, 0%, 100%, 0.5);
        border-radius: 10px;
        background-size: 22px 22px;
        background-image: linear-gradient(135deg,
        hsla(0, 0%, 100%, 0.15) 25%,
        transparent 0,
        transparent 50%,
        hsla(0, 0%, 100%, 0.15) 0,
        hsla(0, 0%, 100%, 0.15) 75%,
        transparent 0,
        transparent);
      }
    }

    @-webkit-keyframes stripes-move {
      0% {
        background-position: 22px 22px;
      }
      100% {
        background-position: 0 0;
      }
    }

    @keyframes stripes-move {
      0% {
        background-position: 22px 22px;
      }
      100% {
        background-position: 0 0;
      }
    }
`;
