import React from 'react';
import clsx from 'clsx';

import { type IBundleStyleConfig } from '../../../../helpers/bundleStyleHelper';
import { Icon } from '../Icon/Icon';
import { ButtonStyled } from './Button.styled';

interface ButtonProps {
    children: React.ReactNode;
    className?: string;
    color?:
        | 'special'
        | 'primary'
        | 'secondary'
        | 'teriatry'
        | 'solid'
        | 'grey-1'
        | 'grey-2'
        | 'grey-3'
        | 'grey-4'
        | 'custom'
        | 'yellow-without-border';
    disabled?: boolean;
    icon?: string | false;
    iconSize?: number;
    onClick?: () => void;
    isLoading?: boolean;
    size?: 'large' | 'medium' | 'small';
    id?: string;
    name?: string;
    testId?: string;
    styleConfig?: IBundleStyleConfig;
}

export const Button: React.FC<ButtonProps> = ({
    size = 'medium',
    color = 'primary',
    className = '',
    icon = false,
    iconSize = 14,
    children,
    disabled = false,
    onClick = () => {},
    isLoading = false,
    id,
    name,
    testId,
    styleConfig,
}) => {
    const attributes: { id?: string; name?: string } = {};

    if (name) {
        attributes.name = name;
    }

    if (id) {
        attributes.id = id;
    }

    if (testId) {
        attributes['data-testid'] = testId;
    }

    return (
        <ButtonStyled
            className={clsx(color, size, className, 'cursor-pointer')}
            disabled={disabled}
            onClick={!isLoading ? onClick : undefined}
            mainColor={styleConfig?.mainColor}
            secondaryColor={styleConfig?.secondaryColor}
            degree={styleConfig?.degree}
            {...attributes}
        >
            {icon && <Icon size={iconSize} className={clsx(icon, 'button-icon')} />}
            <span className="button-text">{children}</span>
        </ButtonStyled>
    );
};
