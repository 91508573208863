import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { globalSel } from '../../../store/global';
import { Box } from '../../components/ui-components/Box/Box';
import { RightSidebarStyled } from './RightSidebar.styled';
import UserWrapper from './UserWrapper/UserWrapper';

export default function RightSidebar({ openRightSidebar, setOpenRightSidebar, isLoggedIn }) {
    const theme = useSelector(globalSel.themeSelector);
    const isDark = theme.palette.mode === 'dark';

    return (
        <Box
            component={RightSidebarStyled}
            position="fixed"
            className={openRightSidebar && 'show'}
            top={0}
            right={0}
            bottom={0}
            width={244}
            overflow="auto"
            zIndex={theme.zIndex.drawer}
            bgcolor={isDark ? theme.palette.grey.dark[600] : theme.palette.lightMode.grey[100]}
            isDark={isDark}
        >
            <UserWrapper
                openRightSidebar={openRightSidebar}
                setOpenRightSidebar={setOpenRightSidebar}
                isLoggedIn={isLoggedIn}
            />
        </Box>
    );
}

RightSidebar.propTypes = {
    openRightSidebar: PropTypes.bool,
    setOpenRightSidebar: PropTypes.func,
    isLoggedIn: PropTypes.bool,
};
