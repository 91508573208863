import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { globalSel } from '../../../store/global';

const TextSuspend = ({
    loading,
    children,
    whiteVariant = false,
    customVariant = undefined,
    center = false,
    ...skeletonProps
}) => {
    const theme = useSelector(globalSel.themeSelector);
    const styles = {
        center: css`
            margin: 'auto';
        `,
        whiteVarian: css`
            background-color: ${theme.palette.common.white};
        `,
        customVariant: css`
            background-color: ${customVariant};
        `,
    };

    if (loading) {
        return (
            <Skeleton
                className={clsx(skeletonProps.className, {
                    [styles.center]: center,
                    [styles.whiteVarian]: whiteVariant,
                    [styles.customVariant]: customVariant,
                })}
                variant="text"
                width={120}
                height="100%"
                data-testid="WP-TextSuspend-skeleton"
                {...skeletonProps}
            />
        );
    }

    return children || null;
};

TextSuspend.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    whiteVariant: PropTypes.bool,
    customVariant: PropTypes.string,
    center: PropTypes.bool,
    argv2: PropTypes.object,
};

export default TextSuspend;
