import React from 'react';
import { useSelector } from 'react-redux';

import { type TNotificationStatus } from '../../../@types/store/notifications';
import { NOTIFICATIONS_STATES } from '../../../constants/notifications.constants';
import { useTranslation } from '../../../hooks';
import { globalSel } from '../../../store/global';
import { Typography } from '../ui-components/Typography/Typography';

interface IProps {
    notificationsState: TNotificationStatus;
}

const NotificationsWarningMessage: React.FC<IProps> = ({ notificationsState }) => {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();

    return (
        <Typography color={theme.palette.grey.light[700]} variant="p12">
            {notificationsState === NOTIFICATIONS_STATES.DEFAULT
                ? t('common_notifications_not_allowed', 'Enable notifications on your browser.')
                : t(
                      'common_notifications_blocked_by_browser',
                      'Enable notifications on your browser settings and refresh the page.',
                  )}
        </Typography>
    );
};

export default NotificationsWarningMessage;
