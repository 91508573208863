import './theme/styles.scss';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';

import { attributionHook } from './configs/web-sdk.config';
import * as Constants from './constants';
import { getUserPreferredLanguage } from './helpers/webEnvHelpers';
import { useCssViewPoint, useHelpShift, useXsollaLoadHook } from './hooks';
import { useAttributionHook } from './hooks/useAttribution.hook';
import AppRouter from './routes/AppRouter';
import {
    getLocalStorage,
    getSessionStorage,
    removeSessionStorage,
    setLocalStorage,
} from './services';
import { handleError } from './services/handleError.service';
import { globalOp, globalSel } from './store/global';
import { translationsOp } from './store/translation/operations';
import { GlobalStyles } from './theme/global.styled';
import { theme as muiTheme } from './theme/themeMUI';
import GlobalLoaderWrapper from './views/components/GlobalLoaderWrapper/GlobalLoaderWrapper';
import SnackBar from './views/components/SnackBar/SnackBar';
import { useAppDispatch } from './store/configureStore';

attributionHook.userLanding();

const App = () => {
    const dispatch = useAppDispatch();

    const providerTheme = useSelector(globalSel.themeSelector);

    const { getTranslations, setTranslations, setTranslationLoading } = translationsOp;

    useXsollaLoadHook();

    useAttributionHook();

    useHelpShift();

    useCssViewPoint();

    useEffect(() => {
        const errorModalHandlerData = getSessionStorage(Constants.ERROR_HANDLER_MODAL);

        const selectedLanguage = getLocalStorage(Constants.LANGUAGE_STORAGE_KEY);

        if (!selectedLanguage) {
            const userPreferredLanguage = getUserPreferredLanguage();

            setLocalStorage(Constants.LANGUAGE_STORAGE_KEY, userPreferredLanguage || 'en');
        } else {
            document.documentElement.setAttribute('lang', selectedLanguage);
        }

        // titanAnalyticsEvents.appOpen();
        if (errorModalHandlerData) {
            dispatch(globalOp.setErrorModal(errorModalHandlerData));
            removeSessionStorage(Constants.ERROR_HANDLER_MODAL);
        }

        smoothscroll.polyfill();

        handleError(
            getTranslations(['common'])
                .then((res) => {
                    if (Array.isArray(res)) {
                        dispatch(setTranslations(res[0]?.data));
                    }
                })
                .finally(() => {
                    dispatch(setTranslationLoading('common', false));
                }),
        );
        // eslint-disable-next-line
    }, []);

    return (
        <MuiThemeProvider theme={muiTheme(providerTheme.palette.mode)}>
            <ThemeProvider theme={providerTheme}>
                <GlobalLoaderWrapper />
                <GlobalStyles />

                <AppRouter />
                <SnackBar />
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default App;
