import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type TRedeemModal, type TRedeemState } from './../../@types/store/redeem';
import { initialRedeemState } from './initialState';

export const redeemSlice = createSlice({
    name: 'redeem',
    initialState: initialRedeemState,
    reducers: {
        setRedeemState(state, action: PayloadAction<object>) {
            state.redeemState = action.payload;
        },
        setRedeemModal(state, action: PayloadAction<Omit<TRedeemModal, 'open'>>) {
            state.redeemModal.open = true;
            state.redeemModal.type = action.payload.type;
            state.redeemModal.data = action.payload.data;
        },
        dropRedeemModal(state) {
            state.redeemModal = { open: false, type: '', data: {} };
        },
        setValidCodeData(
            state,
            action: PayloadAction<Omit<TRedeemState, 'redeemState' | 'redeemModal'>>,
        ) {
            state.redeemCode = action.payload.redeemCode;
            state.redeemBundleId = action.payload.redeemBundleId;
            state.redeemMsg = action.payload.redeemMsg;
        },
    },
});
