import React from 'react';

import { IconStyled } from './Icon.styled';

interface IconProps {
    children?: React.ReactNode;
    size?: number;
    color?: string;
    className?: string;
    onClick?: () => void;
    id?: string;
}

export const Icon: React.FC<IconProps> = ({
    size = 24,
    color = 'inherit',
    className,
    children,
    onClick = () => {},
    id,
}) => {
    const attributedProps = {};

    if (id) {
        attributedProps['id'] = id;
    }

    return (
        <IconStyled
            {...attributedProps}
            size={size}
            color={color}
            className={className}
            onClick={onClick}
        >
            {children}
        </IconStyled>
    );
};
