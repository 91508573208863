import { type IStoreState } from 'src/@types/store/global';

const isLoadingSelector = (state: IStoreState) => state.news.isNewsLoading;
const newsItemsSelector = (state: IStoreState) => state.news.newsItems;
const newsBannerSelector = (state: IStoreState) => state.news.banner;
const newsCategoryListSelector = (state: IStoreState) => state.news.categoryList;
const newsDetailsSelector = (state: IStoreState) => state.news.newsDetails;
const isNewsDetailsLoadingSelector = (state: IStoreState) => state.news.isNewsDetailsLoading;
const inGameNewsSelector = (state: IStoreState) => state.news.inGameNews;

export const newsSel = {
    isLoadingSelector,
    newsItemsSelector,
    newsBannerSelector,
    newsCategoryListSelector,
    newsDetailsSelector,
    isNewsDetailsLoadingSelector,
    inGameNewsSelector,
};
