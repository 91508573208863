import { type IPagesPathEntries } from 'src/@types';

export const LANGUAGE_STORAGE_KEY = 'preferred_language'; // Also user in index.html fro help shift language key
export const LANGUAGES_KEYS = ['en', 'fr', 'it', 'de', 'es', 'ru', 'pt', 'js', 'ko'];
export const ACCOUNT_DATA_ID = 'account_id';
// eslint-disable-next-line no-undef
export const USER_DATA_SESSION_STORAGE_KEY = process.env.REACT_APP_USER_DATA_SESSION_KEY || '';
export const IOS_GAME_DOWNLOAD_LINK =
    'https://apps.apple.com/us/app/star-trek-fleet-command/id1427744264';
export const ANDROID_GAME_DOWNLOAD_LINK =
    'https://play.google.com/store/apps/details?id=com.scopely.startrek&gl=ES';

export const projectPages: IPagesPathEntries = {
    '/': 'dashboard',
    '/store': 'store',
    '/news': 'news',
    '/events': 'events',
    '/redeem-code': 'redeem-code',
    '/maintenance': 'maintenance',
    '/not-found': 'not-found',
};
