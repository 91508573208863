import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type TNotificationStatus } from '../../@types/store/notifications';
import { initialNotificationsState } from './initialState';

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState,
    reducers: {
        setNotificationsState(state, action: PayloadAction<TNotificationStatus>) {
            state.notificationsState = action.payload;
        },
    },
});
