import React, { type MouseEvent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useHandleNavigationPrevent } from 'src/hooks/useHandleNavigationPrevent.hook';
import { translationsSel } from 'src/store/translation';

import logo_en from '../../../assets/images/st-logo.webp';
import logo_ja from '../../../assets/images/st-logo-JP.webp';
import logo_ko from '../../../assets/images/st-logo-KR.webp';
import { AUTOMATION_TESTING_IDS } from '../../../constants';
import { Box } from '../ui-components/Box/Box';

interface IProps {
    isLeftSideBar: boolean;
    /* eslint-disable no-unused-vars */
    onClick: (arg0: MouseEvent<HTMLDivElement>) => void;
}

const logos = {
    en: logo_en,
    ja: logo_ja,
    ko: logo_ko,
};

const AppLogo: React.FC<IProps> = ({ isLeftSideBar, onClick = () => {} }) => {
    const selectedLang = useSelector(translationsSel.selectedLanguageSelector);

    const generateLogoImage = () => {
        if (selectedLang === 'ko' || selectedLang === 'ja') {
            return logos[selectedLang];
        }
        return logos.en;
    };

    const { handleNavigationClick } = useHandleNavigationPrevent();

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        if (handleNavigationClick(e)) {
            onClick(e);
        }
    };

    return (
        <Box my={7} ml={7} mr={10} onClick={handleClick} data-testid="WP-AppLogo">
            <NavLink onClick={handleNavigationClick} to="/" id={AUTOMATION_TESTING_IDS.logoButton}>
                <Box
                    component={LazyLoadImage}
                    src={generateLogoImage()}
                    width={isLeftSideBar ? [150, 150, 174] : [100, 100, 174]}
                    height={isLeftSideBar ? [60, 60, 70] : [40, 40, 70]}
                    className="cursor-pointer"
                    alt="Star Trek Main"
                />
            </NavLink>
        </Box>
    );
};

export default AppLogo;
