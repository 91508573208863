/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import Input from '@mui/material/Input';
import { useTranslation } from 'src/hooks';
import { globalSel } from 'src/store/global';

import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';
import { InputWithButtonStyled } from './InputWithButton.styled';

export const InputWithButton = ({
    input_id,
    button_id,
    helper_id,
    onChange,
    value,
    inputRef,
    placeholder = '',
    maxWidth = 600,
    width = 1,
    height = 60,
    buttonProps = { color: 'yellow-without-border' },
    buttonText = 'Button',
    onButtonClick,
    errorMessageKey = '',
    errorMessageFallback = '',
    buttonFontSize = ['14px', '16px'],
    buttonFontWeight = '500',
}) => {
    const t = useTranslation();

    const { palette } = useSelector(globalSel.themeSelector);

    const colors = {
        errorMessage: palette.red[800],
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            onButtonClick();
        }
    };

    return (
        <Box
            component={InputWithButtonStyled}
            hasError={errorMessageKey}
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={width}
        >
            <Box
                className="WP-InputWithButton-container"
                width={width}
                py={5}
                pl={5}
                pr={2}
                maxWidth={maxWidth}
                height={height}
            >
                <Input
                    inputRef={inputRef}
                    value={value}
                    fullWidth
                    placeholder={placeholder}
                    className="WP-InputWithButton"
                    disableUnderline
                    onChange={onChange}
                    id={input_id}
                    onKeyDown={handleKeyPress}
                    endAdornment={
                        <Button {...buttonProps} onClick={onButtonClick} id={button_id}>
                            <Box
                                component="span"
                                fontSize={buttonFontSize}
                                fontWeight={buttonFontWeight}
                            >
                                {buttonText}
                            </Box>
                        </Button>
                    }
                />
            </Box>

            {errorMessageKey && (
                <Box
                    component={Typography}
                    mt={1}
                    variant="p16"
                    color={colors.errorMessage}
                    className="WP-InputWithButton-errorText"
                    id={helper_id}
                >
                    {t(errorMessageKey, errorMessageFallback)}
                </Box>
            )}
        </Box>
    );
};
