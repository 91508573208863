import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/@types/hooks';
import { getDesktopOperatingSystem, getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';

import { attributionHook } from '../../../configs/web-sdk.config';
import { AUTOMATION_TESTING_IDS } from '../../../constants';
import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';
import { useTranslation } from '../../../hooks';
import { globalOp, globalSel } from '../../../store/global';
import { Box } from '../ui-components/Box/Box';
import { Typography } from '../ui-components/Typography/Typography';

type TDialogDataContent = {
    titleText: string;
    bodyText: string;
    confirmText: string;
    declineText: string;
    acceptAction: () => void;
    declineAction: () => void;
};

const getMobileDownloadLink = () => {
    const mobileOperatingSystem = getMobileOperatingSystem();

    if (mobileOperatingSystem === 'iOS') {
        return 'https://apps.apple.com/us/app/star-trek-fleet-command/id1427744264';
    } else if (mobileOperatingSystem === 'Android') {
        return 'https://play.google.com/store/apps/details?id=com.scopely.startrek&gl=ES';
    }

    return '';
};

const DownloadGameButton = () => {
    const t = useTranslation();
    const dispatch = useAppDispatch();

    const dialogData = useSelector(globalSel.playOnPCDialogDataSelector);
    const theme = useSelector(globalSel.themeSelector);

    const mobileDeviceDownloadLink = getMobileDownloadLink();
    const operatingSystem = getDesktopOperatingSystem();

    const isDark = theme.palette.mode === 'dark';

    const colors = {
        bgColor: isDark ? '#49494d' : theme.palette.lightMode.grey[500],
        downloadGamePlayOnText: isDark ? theme.palette.yellow[800] : theme.palette.blue[400],
        downloadGameText: isDark ? theme.palette.common.white : '#2E3033',
        downloadGameLine: '#49494D',
    };

    const handleNotSupportedPlatformModalOpen = (dialogDataContent: TDialogDataContent) => {
        titanAnalyticsEvents.generateModalEvent({
            screen_id: 'not_supported_platform',
            screen_category: 'game',
            interaction_object: 'not_supported_platform_modal',
        });

        dispatch(
            globalOp.handlePlayOnPCDialogData({
                ...dialogData,
                ...dialogDataContent,
                open: true,
            }),
        );
    };

    const handleClose = () => {
        dispatch(
            globalOp.handlePlayOnPCDialogData({
                ...dialogData,
                open: false,
            }),
        );
    };

    const handleDownloadClick = () => {
        const linkType = operatingSystem === 'MacOS' ? 'mac' : 'windows';

        // TODO remove after OTP for Mac Client will be implemented
        if (linkType === 'mac') {
            getDownloadLink('https://bit.ly/3HZ1Wnh');
            return;
        }

        dispatch(globalOp.getDesktopGameDownloadLink(linkType)).then((res: string) =>
            getDownloadLink(res),
        );
    };

    const getDownloadLink = (desktopDownloadLink: string) => {
        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'download_the_pc_game',
            screen_category: 'game',
            interaction_object: 'download_the_pc_game_button',
        });

        attributionHook.download();

        const cases = {
            isDeviceiOSOrAndroid: mobileDeviceDownloadLink,
            isDeviceDifferentThaniOSOrAndroid:
                operatingSystem === 'unknown' && !mobileDeviceDownloadLink,
            isDeviceWindowsOrMac: operatingSystem === 'Windows' || operatingSystem === 'MacOS',
        };

        if (cases.isDeviceiOSOrAndroid) {
            window.location.assign(mobileDeviceDownloadLink);
        } else if (cases.isDeviceDifferentThaniOSOrAndroid) {
            handleNotSupportedPlatformModalOpen({
                titleText: 'Alert',
                bodyText: "Sorry, we don't support market on you\r device",
                confirmText: 'Ok',
                declineText: 'Cancel',
                acceptAction: handleClose,
                declineAction: handleClose,
            });
        } else if (cases.isDeviceWindowsOrMac) {
            desktopDownloadLink && window.location.assign(desktopDownloadLink);
        } else {
            handleNotSupportedPlatformModalOpen({
                titleText: 'PC Launcher Download',
                bodyText: `
                    Star Trek Fleet Command is not compatible with ${operatingSystem}. Do you wish to continue?
                    `,
                confirmText: 'Yes, Download Now',
                declineText: 'No, Return to Site',
                acceptAction: () => {
                    desktopDownloadLink && window.location.assign(desktopDownloadLink);
                    handleClose();
                },
                declineAction: handleClose,
            });
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={5}
            py={2}
            border={1}
            borderColor={colors.bgColor}
            bgcolor={isDark ? theme.palette.common.black : theme.palette.common.white}
            borderRadius="4px"
            onClick={handleDownloadClick}
            id={AUTOMATION_TESTING_IDS.playOnPcButton}
            className="cursor-pointer"
            data-testid="WP-DownloadGameButton"
        >
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box display="flex" alignItems="center">
                    <Typography variant="p10" color={colors.downloadGamePlayOnText}>
                        {t('common_left_sidebar_play_on', 'PLAY ON')}
                    </Typography>
                    <Box
                        component="span"
                        mx={1.5}
                        width="1px"
                        height={22}
                        bgcolor={colors.downloadGameLine}
                    />

                    <Box
                        component={Typography}
                        variant="p12"
                        fWeight="bold"
                        color={colors.downloadGameText}
                        mt={-0.5}
                    >
                        {t('common_left_sidebar_desktop', 'YOUR COMPUTER')}
                    </Box>
                </Box>

                <Box mt={0.5}>
                    <Typography variant="p10" color={colors.downloadGameText}>
                        {t(
                            'common_left_sidebar_platform_support_text',
                            'Windows and Mac Supported',
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default DownloadGameButton;
