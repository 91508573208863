import React, { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { type Theme } from '@mui/material';

import SimpleVariant from './SimpleVariant';

interface IProps {
    variant: string | [];
    text?: string | React.ReactNode;
    maxRows?: number;
    color?: string;
    textShadow?: string;
    fWeight?: 'bold' | 'semiBold' | 'medium' | 'regular' | 'book';
    tooltipWidth?: string;
    tooltipTextColor?: string;
    tooltipBGColor?: string;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    tooltipBorderRadius?: string;
    tooltipBorderColor?: string;
    arrowSize?: string;
    moveX?: string;
    moveY?: string;
    zIndex?: number;
    [otherProps: string]: any;
}

const getRightIndex = (...args: boolean[]) => {
    return args.findIndex((item) => item);
};

const TruncateText: React.FC<IProps> = ({ variant, ...rest }) => {
    const [rightIndex, setRightIndex] = useState(-1);

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    const index = rightIndex > variant.length - 1 ? variant.length - 1 : rightIndex;

    useLayoutEffect(() => {
        const right = getRightIndex(smDown, mdDown, lgDown, xlDown, xlUp);
        setRightIndex(right);
    }, [xlUp, smDown, mdDown, lgDown, xlDown]);

    if (typeof variant === 'string') {
        return (
            <>
                <SimpleVariant key={rightIndex} variant={variant} {...rest} />
            </>
        );
    }

    return rightIndex >= 0 ? (
        <SimpleVariant key={rightIndex} variant={variant[index]} {...rest} />
    ) : null;
};

export default TruncateText;
