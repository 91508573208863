/* eslint-disable no-undef */
const setLocalStorage = (key: string, val: any) => {
    localStorage.setItem(key, JSON.stringify(val));
};

const getLocalStorage = (key: string) => {
    return JSON.parse(localStorage.getItem(key) || JSON.stringify(''));
};

const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export { setLocalStorage, getLocalStorage, removeLocalStorage };
