import { type IDarkPalette } from 'src/@types';
import { hexToRgba } from 'src/helpers/styleHelpers';

import { globalPalette } from './globalPalette';

export const darkPalette: IDarkPalette = {
    primary: {
        main: globalPalette.yellow[600],
    },
    secondary: {
        main: globalPalette.green[600],
    },
    solid: {
        main: globalPalette.grey.dark[400],
        hover: globalPalette.grey.dark[200],
    },
    background: {
        main: globalPalette.grey.dark[700],
        50: globalPalette.grey.light[100],
        100: globalPalette.grey.light[400],
        200: globalPalette.grey.light[800],
        300: globalPalette.grey.dark[300],
        400: globalPalette.grey.dark[400],
        500: globalPalette.grey.dark[600],
        600: globalPalette.grey.dark[800],
        700: globalPalette.grey.dark[700],
        800: globalPalette.grey.dark[800],
    },
    border: {
        50: globalPalette.grey.light[300],
        100: globalPalette.grey.light[800],
        T10FFF: hexToRgba('#fff', 0.1),
    },
    text: {
        main: globalPalette.grey.light[100],
        50: globalPalette.grey.light[200],
        100: globalPalette.grey.light[300],
        200: globalPalette.grey.light[400],
        300: globalPalette.grey.light[500],
        400: globalPalette.grey.light[600],
        500: globalPalette.grey.light[700],
        600: globalPalette.grey.light[800],
        700: globalPalette.grey.dark[200],
        800: globalPalette.grey.dark[300],
        900: globalPalette.grey.dark[400],
    },
};
