import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClickAwayListener, Divider, Hidden, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// import webCoin from '../../../../assets/images/web-coin.png';
import arrowDown from '../../../../assets/images/arrow-down.png';
import avatar from '../../../../assets/images/avatar.png';
import { AUTOMATION_TESTING_IDS } from '../../../../constants';
import { NOTIFICATIONS_STATES } from '../../../../constants/notifications.constants';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { globalOp, globalSel } from '../../../../store/global';
import { notificationsSel } from '../../../../store/notifications';
import { offerSel } from '../../../../store/offer';
import { translationsSel } from '../../../../store/translation/selectors';
import ToggleNotificationsSwitcher from '../../../components/MISwitchContainer/components/ToggleNotificationsSwitcher/ToggleNotificationsSwitcher';
import { Oblivion } from '../../../components/Oblivion/Oblivion';
import TextSuspend from '../../../components/TextSuspend/TextSuspend';
import ToolTipBox from '../../../components/ToolTipBox/ToolTipBox';
import { Backdrop } from '../../../components/ui-components/Backdrop/Backdrop';
import { Box } from '../../../components/ui-components/Box/Box';
import { Button } from '../../../components/ui-components/Button/Button';
import { Typography } from '../../../components/ui-components/Typography/Typography';
import { PlayerProfileInfoStyled } from './PlayerProfileInfo.styled';

const SignOutButton = ({ onClick }) => {
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);

    const t = useTranslation();

    return (
        <Box display="flex" width={200}>
            <Button
                onClick={onClick}
                className="WP-Header-log-out-btn"
                id={AUTOMATION_TESTING_IDS.logOutButton}
                testId={AUTOMATION_TESTING_IDS.logOutButton}
            >
                <TextSuspend loading={translationsLoading} width="100%">
                    {t('common_header_log_out_button_text', 'Log out')}
                </TextSuspend>
            </Button>
        </Box>
    );
};

export default function PlayerProfileInfo() {
    const dispatch = useDispatch();

    const currentUser = useSelector(globalSel.currentUserSelector);
    const theme = useSelector(globalSel.themeSelector);
    const offerPurchasingSelector = useSelector(offerSel.offerPurchasingSelector);
    const notificationsState = useSelector(notificationsSel.notificationsStateSelector);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const mobileQuery = useMediaQuery(theme.breakpoints.down('xs'));

    const handleLogout = () => {
        handleCloseDropDown();

        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'sign_out',
            screen_category: 'authorization',
            interaction_object: 'sign_out_button',
        });

        dispatch(globalOp.logout());

        if (pathname === '/store') {
            navigate('/');
        }
    };

    const handleToggleMenu = () => {
        setDropDownOpen((prevState) => !prevState);
    };

    const handleCloseDropDown = () => {
        setDropDownOpen(false);
    };

    return (
        <Box
            component={PlayerProfileInfoStyled}
            open={dropDownOpen}
            display="flex"
            alignItems="center"
        >
            <Hidden smDown>
                {/* <Box mr={5}>
                    <Oblivion imgUrl={webCoin} className="WP-PlayerProfileInfo-web-coin-box">
                        <Box zIndex={1}>
                            <Typography className="info-text" variant="p10" color={theme.palette.grey.light[300]}>
                                {currentUser.coinTitle}
                            </Typography>
                            <Typography className="info-text" variant="p10" color="#E2E6EF">
                                {currentUser.coinCount}
                            </Typography>
                        </Box>
                    </Oblivion>
                </Box> */}
                <ClickAwayListener onClickAway={handleCloseDropDown}>
                    <div
                        className={clsx({
                            'offer-purchasing': offerPurchasingSelector,
                        })}
                    >
                        <ToolTipBox
                            show={dropDownOpen}
                            borderColor={
                                theme.palette.mode === 'dark'
                                    ? `linear-gradient(180deg, ${theme.palette.white.transparent20} 0%, transparent 100%)`
                                    : `linear-gradient(180deg, ${theme.palette.lightMode.grey[500]} 0%, transparent 100%)`
                            }
                            content={
                                <Box py={6} px={5}>
                                    <SignOutButton onClick={handleLogout} />

                                    <Box mt={4}>
                                        <Divider className="WP-PlayerProfileInfo-tooltip-divider" />
                                    </Box>

                                    {notificationsState !== NOTIFICATIONS_STATES.UNAVAILABLE && (
                                        <ToggleNotificationsSwitcher />
                                    )}
                                </Box>
                            }
                            arrowPosition="88%"
                            offset={8}
                            align="right"
                        >
                            <Oblivion
                                imgUrl={avatar}
                                onClick={handleToggleMenu}
                                className={clsx(
                                    'WP-PlayerProfileInfo-web-profile-info cursor-pointer',
                                    {
                                        loading: offerPurchasingSelector,
                                    },
                                )}
                                testId="user-data-dropdown"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flex={1}
                                >
                                    <Box zIndex={1}>
                                        <Typography
                                            className="info-text"
                                            variant="p10"
                                            color={
                                                theme.palette.mode === 'dark'
                                                    ? theme.palette.grey.light[300]
                                                    : theme.palette.lightMode.grey[700]
                                            }
                                        >
                                            <span id={AUTOMATION_TESTING_IDS.userName}>
                                                {currentUser?.name}
                                            </span>
                                        </Typography>
                                        {/* <Typography className="info-text" variant="p10" color="#E2E6EF">
                                        {currentUser.level}
                                    </Typography> */}
                                    </Box>

                                    <Box
                                        component="img"
                                        width={14}
                                        height={10}
                                        src={arrowDown}
                                        alt="arrow-down"
                                        zIndex={1}
                                        className="WP-PlayerProfileInfo-dropDown-arrow"
                                    />
                                </Box>
                            </Oblivion>
                        </ToolTipBox>
                    </div>
                </ClickAwayListener>
            </Hidden>

            {dropDownOpen && mobileQuery && (
                <Backdrop open={true} zIndex={1200} onClick={handleCloseDropDown} top={60} />
            )}
        </Box>
    );
}

SignOutButton.propTypes = {
    onClick: PropTypes.func,
};

PlayerProfileInfo.propTypes = {};
