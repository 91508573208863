import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { hexToRgba } from 'src/helpers/styleHelpers';

import { AUTOMATION_TESTING_IDS, INTEGRATION_TESTING_IDS } from '../../../../constants';
import { globalSel } from '../../../../store/global';
import { translationsSel } from '../../../../store/translation';
import BorderedContent from '../../BorderedContent/BorderedContent';
import TextSuspend from '../../TextSuspend/TextSuspend';
import { Box } from '../../ui-components/Box/Box';
import { Icon } from '../../ui-components/Icon/Icon';
import { Typography } from '../../ui-components/Typography/Typography';
import { ModalBodyStyled } from './ModalBody.styled';

const ModalBody = ({
    image,
    title,
    text,
    button,
    onClose,
    hasCloseIcon,
    width,
    isPayment,
    isAuthError,
    isClaimedDetails,
    isOfferDetails,
    isEvents,
    children,
    contentMaxWidth,
}) => {
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);
    const {
        palette,
        palette: { mode },
    } = useSelector(globalSel.themeSelector);

    const colors = {
        title: mode === 'dark' ? '#DDE2ED' : palette.lightMode.grey[900],
        subTitle: mode === 'dark' ? '#A1A7B2' : '#8A8E99',
        backgroundColor: mode === 'dark' ? '#292B2E' : '#F8F9FC',
        outerBorder: mode === 'dark' ? hexToRgba(palette.common.white, 0.2) : '#CBD0D6',
        closeIcon: palette.grey.light[700],
    };

    return (
        <Box component={ModalBodyStyled} data-testid={INTEGRATION_TESTING_IDS.dialogModalBody}>
            <BorderedContent
                innerBorderWidth={0}
                innerBgColor={colors.backgroundColor}
                outerBorder={colors.outerBorder}
                width={width}
            >
                <Box
                    position="relative"
                    px={[isPayment ? 5 : 3, isPayment ? 5 : 8]}
                    pt={5}
                    pb={isPayment ? 0 : isAuthError ? 7 : isOfferDetails ? 13 : isEvents ? 8 : 20}
                >
                    <Box className="WP-Modal-background" />

                    {hasCloseIcon && (
                        <Box display="flex" justifyContent="flex-end">
                            <Box
                                display="flex"
                                color={colors.closeIcon}
                                onClick={onClose}
                                id={AUTOMATION_TESTING_IDS.modalCloseButton}
                                data-testid={INTEGRATION_TESTING_IDS.dialogModalBodyCloseIcon}
                            >
                                <Icon size={18} className="icon-close cursor-pointer" />
                            </Box>
                        </Box>
                    )}

                    <Box position="relative" maxWidth={contentMaxWidth} mx="auto" zIndex={9}>
                        {image && (
                            <Box
                                mt={[6, isClaimedDetails ? 29 : 9]}
                                maxWidth={[120, 150]}
                                mx="auto"
                            >
                                {image}
                            </Box>
                        )}

                        {children ? (
                            children
                        ) : (
                            <>
                                <Box
                                    component={Typography}
                                    textAlign="center"
                                    variant={['p20', 'h2']}
                                    color={colors.title}
                                    mt={[8, isPayment ? 12 : 15]}
                                    className="WP-ModalBody-title"
                                >
                                    <TextSuspend loading={translationsLoading} center>
                                        {title}
                                    </TextSuspend>
                                </Box>

                                {text && (
                                    <Box
                                        component={Typography}
                                        textAlign="center"
                                        variant={['p14', 'p20']}
                                        color={colors.subTitle}
                                        mt={isPayment ? [5, 7.5] : 5}
                                        className="WP-ModalBody-description"
                                    >
                                        <TextSuspend
                                            loading={translationsLoading}
                                            center
                                            width={170}
                                        >
                                            {text}
                                        </TextSuspend>
                                    </Box>
                                )}

                                <Box
                                    pt={
                                        isClaimedDetails
                                            ? [12, 39]
                                            : isPayment
                                              ? [12, 29]
                                              : [isAuthError ? 8 : 12, 15]
                                    }
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        mx="auto"
                                        minWidth={220}
                                    >
                                        {button}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </BorderedContent>
        </Box>
    );
};

ModalBody.defaultProps = {
    hasCloseIcon: true,
    width: 1,
    contentMaxWidth: 470,
};

ModalBody.propTypes = {
    image: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    button: PropTypes.node,
    onClose: PropTypes.func,
    hasCloseIcon: PropTypes.bool,
    width: PropTypes.number,
    isPayment: PropTypes.bool,
    isAuthError: PropTypes.bool,
    isClaimedDetails: PropTypes.bool,
    isOfferDetails: PropTypes.bool,
    isEvents: PropTypes.bool,
    children: PropTypes.node,
    contentMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

export default ModalBody;
