import { type LoginRequest } from '@scopely/web-sdk-scopelyid';
import { AuthorizationUri } from '@scopely/web-sdk-scopelyid/dist/src/uri';
import { type TDebounceCallback } from 'src/@types';
import { type TKillSwitchConfigPages } from 'src/@types/store';
import { scopelyId } from 'src/configs/web-sdk.config';
import { ANDROID_GAME_DOWNLOAD_LINK, IOS_GAME_DOWNLOAD_LINK } from 'src/constants';
import { v4 as uuidv4 } from 'uuid';

import { titanAnalyticsEvents } from './titanAnalytics';
import { getBrowserType } from './userDeviceHelpers';

export const isAuthorizationUri = (currentLocation) => {
    const authorizationUri = AuthorizationUri.parse(currentLocation);

    return authorizationUri?.isAuthorizationUri(scopelyId.settings.provider.redirectUri)
        ? true
        : false;
};

export const createId = (testKey: string, category: string): string => {
    return `${testKey}-${category.replace(' ', '-').toLowerCase()}`;
};

export const pageMaintenanceModeRenderer = (
    page: string,
    killSwitchConfig: TKillSwitchConfigPages | undefined,
): boolean => {
    if (!killSwitchConfig) return false;

    switch (page) {
        case '/':
            return !killSwitchConfig.dashboard;
        case '/store':
            return !killSwitchConfig.offers;
        case '/news':
            return !killSwitchConfig.news;
        case '/events':
            return !killSwitchConfig.events;
        case '/redeem-code':
            return !killSwitchConfig.redeem;
        default:
            return false;
    }
};

export const generateDesktopDownloadLink = (
    otp: string,
    // linkType: 'mac' | 'windows' = 'windows',
): string => {
    // TODO uncomment after OTP for Mac Client will be implemented
    // if (linkType === 'mac') {
    //     return `https://downloads.aprod.scopely.io/startrekfleetcommand/latest/Star Trek Fleet Command for Mac_${otp}.dmg`;
    // }

    return `https://downloads.aprod.scopely.io/startrekfleetcommand/latest/Star Trek Fleet Command for Windows_${otp}.exe`;
};

export const createGameDownloadLinks = () => {
    const isChrome = getBrowserType() === 'chromeiOS';

    if (isChrome) {
        return {
            iOS: IOS_GAME_DOWNLOAD_LINK.replace('https', 'itmss'),
            android: ANDROID_GAME_DOWNLOAD_LINK,
        };
    }

    return {
        iOS: IOS_GAME_DOWNLOAD_LINK,
        android: ANDROID_GAME_DOWNLOAD_LINK,
    };
};

export const redirectToScopelyAuth = () => {
    const state = uuidv4() as unknown as LoginRequest;
    scopelyId.login(state);

    titanAnalyticsEvents.generateButtonEvent({
        screen_id: 'sign_in',
        screen_category: 'authorization',
        interaction_object: 'sign_in_button',
    });
};

export const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithDots = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const DebounceCallback: TDebounceCallback = function () {
    let lastExecution = 0;

    return (callback, delay) => {
        if (lastExecution + delay < Date.now()) {
            callback();
            lastExecution = Date.now();
        }
    };
};
