import { type PaletteOptions } from '@mui/material';
import { type IPalette } from 'src/@types/theming/ThemeHelpers';

import { darkPalette } from './darkPalette';
import { globalPalette } from './globalPalette';
import { lightPalette } from './lightPalette';

export type Palette = PaletteOptions & IPalette;
// TODO Create a function that returns theme with related mode
export const palette: Palette = {
    ...globalPalette,
    ...darkPalette,
    ...lightPalette,
};
