export const INTEGRATION_TESTING_IDS = {
    dialogModal: 'WP-Modal',
    dialogModalBody: 'WP-ModalBody',
    dialogModalBodyCloseIcon: 'modal-close-button',
    errorModalAccountGuideButton: 'WP-ErrorModal-AccountGuide-button',
    errorModalOKButton: 'WP-ErrorModal-OK-button',
    errorModalRetryButton: 'WP-ErrorModal-Retry-button',
    notSupportedModalTitle: 'WP-NotSupportedPlatformModal-title',
    notSupportedModalDescription: 'WP-NotSupportedPlatformModal-description',
    notSupportedModalDeclineButton: 'WP-NotSupportedPlatformModal-decline-button',
    notSupportedModalConfirmButton: 'WP-NotSupportedPlatformModal-confirm-button',
    notEnoughModalStoreNavigationButton: 'WP-NotEnoughModal-store-navigation-button',
    inGameNewsModalAsSeenOnText: 'WP-InGameNewsDetailsModal-as-seen-on',
    inGameNewsModalArticleContent: 'WP-InGameNewsDetailsModal-article-content',
    collapseSwitcherCollapseButton: 'WP-CollapseSwitcher-collapse',
    collapseSwitcherExpandButton: 'WP-CollapseSwitcher-expand',
    offerPreviewModal: 'WP-OfferPreviewModal',
    offerPreviewModalCloseIcon: 'WP-OfferPreviewModal-close-icon',
    offerItemRarityEffectWrapper: 'WP-OfferItemRarityEffect-wrapper-element',
    offerItemRarityEffectFrame: 'WP-OfferItemRarityEffect-frame-element',
    offerPurchaseButtonPriceText: 'WP-OfferPurchaseButton-price-text',
    navigationPanel: 'WP-NavigationPanel',
    navigationPanelChild: 'WP-NavigationPanel-child',
    storeNavigationPanelWebGiftsCount: 'WP-StoreNavigationPanel-webGifts-count',
};
