import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type TNewsCategories } from 'src/@types';

import {
    type IInGameNews,
    type INewsItems,
    type TNewsBanner,
    type TNewsDetails,
} from './../../@types/store/news';
import { initialNewsState } from './initialState';

export const newsSlice = createSlice({
    name: 'news',
    initialState: initialNewsState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isNewsLoading = action.payload;
        },
        setNewsItems(state, action: PayloadAction<INewsItems>) {
            state.newsItems = action.payload;
        },
        setCategoryList(state, action: PayloadAction<TNewsCategories[]>) {
            state.categoryList = action.payload;
        },
        setBannerItem(state, action: PayloadAction<TNewsBanner | null>) {
            state.banner = action.payload;
        },
        setNewsDetails(state, action: PayloadAction<TNewsDetails | null>) {
            state.newsDetails = action.payload;
        },
        setIsNewsDetailsLoading(state, action: PayloadAction<boolean>) {
            state.isNewsDetailsLoading = action.payload;
        },
        setInGameNews(state, action: PayloadAction<IInGameNews[]>) {
            state.inGameNews = action.payload;
        },
    },
});
